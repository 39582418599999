import React from 'react';
import Icons from 'assets/styles/icons/icons';

import { FormFieldProps } from './FormField.types';
import Styled from './FormField.styled';

import ErrorMessage from './ErrorMessage/ErrorMessage.layout';
import AnimatedLabel from './AnimatedLabel/AnimatedLabel.layout';
import { TextInput } from './TextInput/TextInput.layout';

export const FormField = (
    {
        label,
        placeholder,
        input,
        meta,
        t,
        id,
        animatedLabelProps,
        iconName,
        iconTooltip,
        iconOnClick,
        shouldShowError = true,
        name,
        renderCustomInput,
        ...rest
    }: FormFieldProps
) => {

    const { onBlur, onChange, value, onFocus } = input;
    const showError = shouldShowError && meta.touched && meta.error;
    const isValid = meta.valid && !meta.active && meta.touched;
    const isLabelRaised = (value !== undefined && value !== null && !!`${value}`.length) || meta.active || !!placeholder;

    return (
        <Styled.FormFieldWithError p={rest?.p}>
            <Styled.AnimatedLabelWrap
                hasError={showError}
                hasIcon={!!iconName}
            >

                {iconName && (
                    <Styled.FieldIcon
                        icon={Icons[iconName]}
                        data-tip={iconTooltip}
                        onClick={iconOnClick}
                        hasError={showError}
                        isValid={isValid}
                    />
                )}

                {
                    renderCustomInput
                        ? (renderCustomInput({ input, meta }, showError))
                        : (
                            <TextInput
                                dataTestId={`${id}-input`}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                onFocus={onFocus}
                                showError={showError}
                                placeholder={placeholder}
                                {...rest}
                            />
                        )
                }

                {label && (
                    <AnimatedLabel
                        hasError={showError}
                        hasValue={isLabelRaised}
                        text={label}
                        {...animatedLabelProps}
                    />
                )}

            </Styled.AnimatedLabelWrap>

            {showError && (
                <ErrorMessage
                    translationKey={meta.error.key || meta.error}
                    translationValues={meta.error.values}
                    dataTestId={id}
                />
            )}

        </Styled.FormFieldWithError>
    );
};
