import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppLogoProps } from './AppLogo.types';

const AppLogo = styled.img<AppLogoProps>`
  width: 153.13px;
  height: 46.23px;
  ${props => (props.size === 'larger' ? 'width: 200px; height: 60px;' : '')};
`;

const AppLogoLinkWrap = styled(Link)`
  display: block;
  text-decoration: none;
`;

export default { AppLogo, AppLogoLinkWrap };
