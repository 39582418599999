import React from "react";
import { DropDepth } from "store/reducers/fileUploaderReducer.types";
import { noop } from "utils/function-tools";
import { Dispatch } from "redux";
import { change } from "redux-form";
import { Action } from "models/meta/action";
import { MimeType } from "models/app/common";
import Icons from "assets/styles/icons/icons";
import Styled from "./FileUploader.styled";
import {
    Event,
    FileUploaderProps,
    OnSingleFileLimitReached,
    SingleFileValidationErrorsHandler
} from "./FileUploader.types";
import BiActionModalLayout from "../Modals/BiActionModal/BiActionModal.layout";

export function createDispatchChangeForm<T>(dispatch: Dispatch<Action>) {
    return (form: string, fieldName: string, value: T) => {
        dispatch(change(form, fieldName, value));
    };
}

const checkForSingleFileValidationErrors = (props: FileUploaderProps) => (uploadedFile: File, name: string = ''): string[] => {

    const { files, maxFiles, acceptedFileTypes, t } = props;
    const fileSizeMB = uploadedFile.size / 1024 / 1024;

    const handlers: SingleFileValidationErrorsHandler[] = [
        {
            predicate: () => files.length === maxFiles && maxFiles > 1,
            handler: (fileErrors) => {
                return fileErrors.concat(t('validationMsg.tooManyFiles', { fileAmount: maxFiles }));
            },
        },
        {
            predicate: () => {
                return !!files.find((file: File) => file.name === uploadedFile.name);
            },
            handler: (fileErrors) => {
                return fileErrors.concat(`${name && name} ${t('validationMsg.fileAlreadyAttached')}`);
            },
        },
        {
            predicate: () => {

                return !!(
                    acceptedFileTypes
                    && acceptedFileTypes?.findIndex((file: MimeType) => {
                        const [, extension] = uploadedFile.name.split('.');

                        return extension === file.extension;
                    }) === -1
                );
            },
            handler: (fileErrors) => {
                const acceptedFilesForErrorMsg = Array.from(
                    new Set(acceptedFileTypes?.map((type: MimeType) => type.extension))
                );

                return fileErrors.concat(
                    `${name && name} ${t('validationMsg.invalidFileFormat')}
                    "${acceptedFilesForErrorMsg.join('", "')}".`
                );
            },
        },
        {
            predicate: () => {
                return fileSizeMB > 5;
            },
            handler: (fileErrors) => fileErrors.concat(t('validationMsg.tooBigFile'))
        }
    ];

    return handlers.reduce((validationErrors, validationErrorHandler) => {
        return validationErrorHandler.predicate() ? validationErrorHandler.handler(validationErrors) : validationErrors;
    }, []);
};

const handleUploadedFiles = (props: FileUploaderProps) => (uploadedFile: FileList | null): void => {

    const {
        dispatchAsynchronousValidation,
        dispatchSetFileUploaderFiles,
        onFileUploaderUnmounted,
        dispatchStartFileUploaderAsyncValidation,
        maxFiles,
        files,
        dispatchErrors,
        t
    } = props;

    const uploaded = uploadedFile ? Array.from(uploadedFile) : [];

    const handlers = [
        {
            predicate: () => uploaded.length === 1,
            handler: () => {
                const singleFileValidationErrors = checkForSingleFileValidationErrors(props)(uploaded[0]);

                if (singleFileValidationErrors.length) {
                    dispatchErrors(singleFileValidationErrors, true);
                } else if (dispatchAsynchronousValidation) {

                    dispatchStartFileUploaderAsyncValidation();

                    dispatchAsynchronousValidation([...files, ...uploaded]);

                } else {

                    onFileUploaderUnmounted();
                    dispatchSetFileUploaderFiles([...files, ...uploaded]);
                }
            }
        },
        {
            predicate: () => uploaded.length > 1 && (uploaded.length + files.length > maxFiles),
            handler: () => {
                dispatchErrors(
                    [t('validationMsg.tooManyFiles', { fileAmount: maxFiles })]
                );
            }
        },
        {
            predicate: () => uploaded.length > 1,
            handler: () => {

                const errors = uploaded.map((el: File) => {
                    return checkForSingleFileValidationErrors(props)(el, el.name);
                });

                if (!errors.length) {
                    onFileUploaderUnmounted();
                }

                dispatchErrors(errors.flat());

                dispatchSetFileUploaderFiles([...files, ...uploaded.filter((el: File) => {
                    const fileErrors = checkForSingleFileValidationErrors(props)(el, el.name);

                    return !fileErrors.length;
                })]);
            }
        }
    ];

    const matchedHandlers = handlers.filter(({ predicate }) => predicate());

    matchedHandlers[0]?.handler?.();
};

export const handleUpload = (props: FileUploaderProps) => (uploadedFiles: FileList | null): void => {

    const { maxFiles, files } = props;

    if (maxFiles === 1 && !!files.length) {
        props.onSingleFileLimitReached(props)(uploadedFiles);
    } else {
        handleUploadedFiles(props)(uploadedFiles);
    }
};

export const handleFileDropUpload = (props: FileUploaderProps) => (event: Event) => {

    const { dispatchSetFileUploaderBeingDraggedOverState, dispatchSetFileUploaderDropDept } = props;

    event.preventDefault();
    event.stopPropagation();

    dispatchSetFileUploaderBeingDraggedOverState(false);

    if (event.dataTransfer !== null && event.dataTransfer.files && event.dataTransfer.files.length > 0) {

        handleUpload(props)(event.dataTransfer.files);
        event.dataTransfer.clearData();
        dispatchSetFileUploaderDropDept(DropDepth.OUTSIDE_DROP_ZONE);
    }
};
export const handleDragIn = (props: FileUploaderProps) => (event: Event) => {

    const { dropDepth, dispatchSetFileUploaderDropDept, dispatchSetFileUploaderBeingDraggedOverState } = props;

    event.preventDefault();
    event.stopPropagation();

    const greaterDropDepth: DropDepth = dropDepth + DropDepth.ON_THE_EDGE_OF_DROP_ZONE;

    dispatchSetFileUploaderDropDept(greaterDropDepth);

    if (event.dataTransfer !== null && event.dataTransfer.items && event.dataTransfer.items.length > 0) {
        dispatchSetFileUploaderBeingDraggedOverState(true);
    }
};
export const handleDragOut = (props: FileUploaderProps) => (event: Event) => {

    const { dropDepth, dispatchSetFileUploaderDropDept, dispatchSetFileUploaderBeingDraggedOverState } = props;

    event.preventDefault();
    event.stopPropagation();

    const smallerDropDepth: DropDepth = dropDepth - DropDepth.ON_THE_EDGE_OF_DROP_ZONE;

    dispatchSetFileUploaderDropDept(smallerDropDepth);

    if (smallerDropDepth === DropDepth.OUTSIDE_DROP_ZONE) {
        dispatchSetFileUploaderBeingDraggedOverState(false);
    }
};

export function handleDrag(event: Event) {
    event.preventDefault();
    event.stopPropagation();
}

export const showSwapModal: OnSingleFileLimitReached = (props) => (uploaded) => {

    const { t, openModal } = props;

    const payload = {
        type: BiActionModalLayout.type,
        data: {
            title: t('common:actionPopUps.bulkFileOverride.title'),
            desc: t('common:actionPopUps.bulkFileOverride.text'),
            positiveActionName: t('common:buttons.yes.text'),
            negativeActionName: t('common:buttons.no.text'),
            positiveAction: () => {
                handleUploadedFiles({ ...props, files: [] })(uploaded);
            },
            negativeAction: noop
        }
    };

    openModal(payload);
};

export function uploadedFilesMapper(props: FileUploaderProps) {

    const { files, t, dataTestId, dispatchSetFileUploaderFiles } = props;

    return files?.map((file: File, index: number) => {
        return (
            <Styled.FilesListElement
                key={`${file.name}${index}`}
                data-test-id={`${dataTestId}-item-${index}`}
            >

                <Styled.FileListIcon
                    icon={Icons.fileText}
                    fontSize={20}
                    color="accent.base"
                />

                <Styled.FileListElementFilename
                    width={files.reduce((previousFile, nextFile) => {
                        return (
                            previousFile.name.length > nextFile.name.length
                                ? previousFile
                                : nextFile
                        );
                    }).name.length}
                >
                    {file.name}
                </Styled.FileListElementFilename>

                <Styled.FileListButton
                    disabled={props.isBeingAsyncValidated}
                    type={'button'}
                    title={t('buttons.removeFile.title')}
                    onClick={() => {
                        dispatchSetFileUploaderFiles(files.filter((el: File) => el.name !== file.name));
                    }}
                    data-test-id={`${dataTestId}-remove-file-btn-${index}`}
                >
                    <Styled.FileListIcon
                        disabled={props.isBeingAsyncValidated}
                        icon={Icons.trash}
                        fontSize={20}
                        color="accent.base"
                    />
                </Styled.FileListButton>

            </Styled.FilesListElement>
        );
    });

}

export function allowForInstantReselectionOfTheSameFile(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    (event.target as { value?: string }).value = '';
}
