import React from 'react';

import { defaultRoutePath } from 'config/config';
import AppLogo from 'components/reusable/AppLogo/AppLogo.layout';

import { OkeoButton } from 'components/atoms/OkeoButton/OkeoButton.styled';
import { ActionVariant } from 'components/atoms/OkeoButton/OkeoButton.types';
import { NavigationBarProps } from './NavigationBar.types';
import Styled from './NavigationBar.styled';

const NavigationBar: React.FC<NavigationBarProps> = ({ t, dispatchNavigate }) => {

    return (
        <Styled.NavigationBar>
            <Styled.NavigationBarDecorationWrapper />

            <Styled.NavigationBarLogoAndButtonWrap>
                <Styled.LogoWrap>
                    <AppLogo />
                </Styled.LogoWrap>

                <Styled.ButtonWrap>
                    <OkeoButton onClick={() => dispatchNavigate(defaultRoutePath)} variant="secondary" actionVariant={ActionVariant.DANGER}>
                        {t('buttons.goBack.text')}
                    </OkeoButton>
                </Styled.ButtonWrap>

            </Styled.NavigationBarLogoAndButtonWrap>

        </Styled.NavigationBar>
    );
};

export default NavigationBar;
