import React from 'react';
import Icons from 'assets/styles/icons/icons';
import Styled from './ReportTile.styled';
import { ReportTileProps } from "./ReportTile.types";

const ReportTile: React.FC<ReportTileProps> = ({
    text,
    dispatchDownloadURI,
    reportFileName
}) => {

    return (
        <Styled.ReportTile
            onClick={() => {
                dispatchDownloadURI(
                    `/reports/${reportFileName}`,
                    reportFileName
                );
            }}
        >
            <Styled.ReportTileLabel>
                {text}
            </Styled.ReportTileLabel>

            <Styled.ReportTileIcon icon={Icons.dropFile} />

        </Styled.ReportTile>
    );
};

export default ReportTile;
