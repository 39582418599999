import styled from 'styled-components';
import theme from 'config/theme';
import { IconFont } from 'assets/styles/icons/Icons.styled';
import { globalColors } from 'components/AppLayout/config';

const LoggedInAsWrap = styled.div`
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
`;

const ContentWrap = styled.div`
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
`;

const LoggedInAsLabel = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 13px;
    color: ${globalColors.fontColors.grey.dark};
`;

const LoggedInAsIcon = styled(IconFont)`
    margin-right: 10px;
    color: ${globalColors.fontColors.grey.dark};
    font-size: 18px;
`;

const LoggedInAs = styled.div`
    color: ${theme.colors.black};
    font-weight: ${theme.fontWeights.semiBold};
    font-size: 14px;
`;

export default { LoggedInAsWrap, ContentWrap, LoggedInAsLabel, LoggedInAsIcon, LoggedInAs };
