import { destroy } from "redux-form";
import { ofType } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';

import {
    catchError, delay, filter, map, mergeMap, switchMap, switchMapTo, tap, withLatestFrom
} from 'rxjs/operators';
import { Epic } from 'models/meta/epic';
import { ApiError } from 'models/meta/api';
import { LoginStep, RegisterStep, ResetPasswordStep } from 'models/app/workflow';
import { actionErrorToast, clearStore, navigate } from 'store/actions/common';

import {
    loginFailure, loginSuccess, registerFailure, registerSuccess, resetPasswordSuccess, resetPasswordFailure,
    setLoginStep, setResetPasswordStep, setRegisterStep
} from 'store/actions/user';

import {
    LOGIN, LOGIN_SUCCESS, LOGOUT, REGISTER, REGISTER_SUCCESS, REMOVE_TOKEN,
    RESET_PASSWORD, RESET_PASSWORD_SUCCESS,
} from 'store/actions/user.types';

import { defaultLoggedInRoutePath, loginRoutePath } from 'config/config';
import { removeTokens } from 'utils/auth/token';
import { HttpStatus } from 'utils/constants/http-status';
import { NAVIGATE } from 'store/actions/common.types';
import { getCountriesInfo } from 'store/actions/dictionary';
import { ONBOARDING_FORM_NAME } from "components/pages/Onboarding/Onboarding.types";

export const onLoginSuccess: Epic = (action$, state$, { http }) =>
    action$.pipe(
        ofType(LOGIN_SUCCESS),
        tap((action) => http.storeTokens(action.payload)),
        switchMapTo(of(
            getCountriesInfo(),
            setLoginStep(LoginStep.SUCCESS)
        ))
    );

export const onLoginSuccessRedirect: Epic = (action$, state$, { http }) =>
    action$.pipe(
        ofType(LOGIN_SUCCESS),
        delay(2000),
        switchMapTo(of(
            navigate(defaultLoggedInRoutePath),
            setLoginStep(LoginStep.AUTHORISATION)
        ))
    );

export const onRegisterSuccess: Epic = (action$, state$, { http }) =>
    action$.pipe(
        ofType(REGISTER_SUCCESS),
        withLatestFrom(state$),
        mergeMap(() => of(
            setRegisterStep(RegisterStep.SUCCESS)
        ))
    );

export const onLogout: Epic = action$ =>
    action$.pipe(
        ofType(LOGOUT),
        tap(() => removeTokens()),
        switchMapTo(of(clearStore()))
    );

export const onLogin: Epic = (action$, _, { tpp }) =>
    action$.pipe(
        ofType(LOGIN),
        switchMap(({ payload }) => from(tpp.login(payload)).pipe(
            switchMap(user => {
                return of(loginSuccess(user), destroy(ONBOARDING_FORM_NAME));
            }),
            catchError((error: ApiError) => {
                const loginError = (error.status === HttpStatus.FORBIDDEN || error.status === HttpStatus.UNAUTHORIZED || error.status === HttpStatus.INTERNAL_SERVER_ERROR);
                const toastActions = loginError ? [actionErrorToast('common', error.message)] : error.actions;
                return of(loginFailure(), ...toastActions);
            })
        ))
    );

export const onRegister: Epic = (action$, _, { tpp }) =>
    action$.pipe(
        ofType(REGISTER),
        switchMap(({ payload }) => from(tpp.register(payload)).pipe(
            map(data => registerSuccess(data)),
            catchError((error: ApiError) => {
                const registerError = (error.status === HttpStatus.FORBIDDEN || error.status === HttpStatus.UNAUTHORIZED);
                // eslint-disable-next-line no-nested-ternary
                const toastActions = error.message === 'EMAIL_EXISTS'
                    ? [actionErrorToast('common', error.message)]
                    : registerError ? [actionErrorToast('common', error.message)] : error.actions;

                return of(registerFailure(), ...toastActions);
            })
        ))
    );

export const onResetPassword: Epic = (action$, _, { tpp }) =>
    action$.pipe(
        ofType(RESET_PASSWORD),
        switchMap(({ payload }) => from(tpp.sendPasswordResetEmail(payload)).pipe(
            map(data => resetPasswordSuccess(data)),
            catchError((error: ApiError) => {
                const resetPasswordError = (error.status === HttpStatus.FORBIDDEN || error.status === HttpStatus.UNAUTHORIZED);
                const toastActions = resetPasswordError ? [actionErrorToast('common', error.message)] : error.actions;

                return of(resetPasswordFailure(), ...toastActions);
            })
        ))
    );

export const onResetPasswordSuccess: Epic = (action$, state$, { http }) =>
    action$.pipe(
        ofType(RESET_PASSWORD_SUCCESS),
        switchMapTo(of(setResetPasswordStep(ResetPasswordStep.SUCCESS)))
    );

export const onRemoveToken: Epic = action$ =>
    action$.pipe(
        ofType(REMOVE_TOKEN),
        tap(() => {
            removeTokens();
        }),
        switchMap(() => EMPTY)
    );

export const onNavigateToLogin: Epic = (action$, state$) =>
    action$.pipe(
        ofType(NAVIGATE),
        filter(action => action.payload === loginRoutePath),
        withLatestFrom(state$),
        mergeMap(() => of(
            setLoginStep(LoginStep.AUTHORISATION)
        ))
    );
