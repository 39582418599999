import styled, { css } from 'styled-components';
import { FadeIn } from "assets/styles/animations/animations.styled";
import { ButtonBaseWithVariants } from "../Buttons/BaseButton";
import { StyledButtonProps } from "../generic.types";

export const BlurredBackground = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    background: rgba(250, 250, 240, 0.4);
    filter: brightness(0.5);
    ${FadeIn}
`;

const ModalWrapper = styled.article`
    box-shadow: 3px 0 0 0 ${({ theme }) => theme.colors.gray.dark};
    border: 1px solid ${({ theme }) => theme.colors.gray.dark};
    border-bottom: 4px solid ${({ theme }) => theme.colors.accent.light};
    min-height: 270px;
    width: 750px;
    height: auto;
    padding: 40px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    z-index: 501;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    ${FadeIn}
`;

const Title = styled.h3`
    text-align: center;
    font-size: 22px;
    margin: 0;
`;

const Description = styled.p`
    text-align: center;
    font-size: 18px;
`;

const standardDuration = '0.25s';

export const ColorAndFilterTransition = css`
    transition: color ${standardDuration} linear, filter ${standardDuration} linear;
`;

export const BgColorAndFilterTransition = css`
    transition: background-color ${standardDuration} linear, filter ${standardDuration} linear;
`;

export type CircleProps = Partial<StyledButtonProps & { rotated: boolean }>;

const rotatedIcon = css`
    i {
        transform: rotate(180deg);
        transform-origin: center;
    }
`;

export const Circle = styled(ButtonBaseWithVariants)<CircleProps>`
    width: 45px;
    height: 45px;
    border-radius: 100%;
    line-height: 1;
    ${BgColorAndFilterTransition};
    font-size: 13px;
    
    ${({ rotated }) => rotated ? rotatedIcon : ''}
`;

const CloseButton = styled(Circle)`
    position: absolute;
    top: 8px;
    right: 8px;
 
    & i {
        margin-left: 0;
    }
`;

export default {
    ModalWrapper,
    Description,
    Title,
    CloseButton
};
