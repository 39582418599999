import { compose, Dispatch } from "redux";
import { connect } from "react-redux";
import { untouch } from "redux-form";
import { Action } from "models/meta/action";
import { withTranslation } from "react-i18next";
import OnboardingForm from "./OnboardingForm.layout";
import { ONBOARDING_FORM_NAME } from "../Onboarding.types";
import { OnboardingFormDispatchProps, OnboardingFormOwnProps } from "./OnboardingForm.types";


function mapDispatchToProps(dispatch: Dispatch<Action>): OnboardingFormDispatchProps {
    return {
        dispatchUntouchOnboardingFormFields: (formSchemaObject: Record<string, string>) => {
            dispatch(untouch(ONBOARDING_FORM_NAME, ...Object.keys(formSchemaObject)));
        }
    };
}

export default compose<React.FC<OnboardingFormOwnProps>>(
    withTranslation('onboarding'),
    connect(null, mapDispatchToProps)
)(OnboardingForm);
