import React from "react";
import SectionLoadingOverlay from "components/reusable/Overalys/SectionLoadingOverlay.layout";

export const FileUploaderLoadingOverlay = () => {
    return (
        <SectionLoadingOverlay
            height={90}
            width={90}
            minHeight=""
            wrapperHeight="80px"
        />
    );
};
