import React, { useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import ArrowDown from 'assets/styles/icons/ArrowDown';
import { CustomLanguageChangerPostion } from 'components/atoms/CustomLanguageChanger/CustomLanguageChanger.types';
import Styled from './CustomLanguageChanger.styled';

// For now just placeholder component
const CustomLanguageChanger: React.FC<{ dropdownPostition?: CustomLanguageChangerPostion }> = ({ dropdownPostition }) => {

    const [opened, toggleOpened] = useState(false);

    const ref = useDetectClickOutside({ onTriggered: () => toggleOpened(false) });

    return (
        <Styled.Wrapper ref={ref}>
            {/* @ts-ignore */}
            <Styled.ContentWrapper onClick={() => toggleOpened(!opened)}>
                <Styled.Flag />
                <Styled.ChevronWrapper opened={opened}>
                    <ArrowDown />
                </Styled.ChevronWrapper>
            </Styled.ContentWrapper>

            <Styled.ListWrapper opened={opened} onClick={() => toggleOpened(false)} dropdownPostition={dropdownPostition}>
                <Styled.Option>
                    <div>
                        English
                    </div>
                    <Styled.Flag />
                </Styled.Option>
            </Styled.ListWrapper>
        </Styled.Wrapper>
    );
};

export default CustomLanguageChanger;
