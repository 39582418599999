import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Normalize } from 'styled-normalize';
import 'react-toastify/dist/ReactToastify.min.css';

import GettingStarted from 'components/pages/GettingStarted/GettingStarted';
import OpenBanking from 'components/pages/OpenBanking/OpenBanking';
import TermsOfService from 'components/pages/TermsOfService/TermsOfService';
import AvailabilityStatistics from 'components/pages/AvailabilityStatistics/AvailabilityStatistics';

import Login from 'components/pages/Login/Login';
import Register from 'components/pages/Register/Register';
import ResetPassword from 'components/pages/ResetPassword/ResetPassword';

import Onboarding from 'components/pages/Onboarding/Onboarding';
import Environments from 'components/pages/Environments/Environments';
import ApiKeys from 'components/pages/ApiKeys/ApiKeys';
import Certificates from 'components/pages/Certificates/Certificates';

import {
    defaultLoggedInRoutePath,
    defaultRoutePath,
    loginRoutePath,
    registerRoutePath,
    resetPasswordRoutePath,
} from 'config/config';
import { GlobalFonts } from 'assets/styles/global/fonts.styled';
import { GlobalStyles } from 'assets/styles/global/global.styled';

import { useTranslation } from 'react-i18next';
import { MainProps } from './Main.types';
import Documentation from './Documentation/Documentation.layout';
import AppLayout from './AppLayout/AppLayout';
import AppLoadingOverlay from './reusable/Overalys/AppLoadingOverlay.layout';

const Main: FunctionComponent<MainProps> = (
    {
        shouldShowPostApprovalItems,
        isAuthenticated,
    }
) => {
    const { ready: tReady } = useTranslation();

    return tReady ? (
        <>
            <Switch>
                <Route path={defaultRoutePath} exact><AppLayout><GettingStarted /></AppLayout></Route>
                <Route path="/open-banking" exact><AppLayout><OpenBanking /></AppLayout></Route>
                <Route path="/availability-statistics" exact><AppLayout><AvailabilityStatistics /></AppLayout></Route>
                <Route path="/terms-of-service" exact><AppLayout><TermsOfService /></AppLayout></Route>

                <Route path="/documentation" exact><Documentation /></Route>

                <Route path={loginRoutePath} exact><AppLayout><Login /></AppLayout></Route>
                <Route path={resetPasswordRoutePath} exact>
                    <AppLayout>
                        {isAuthenticated ? <Redirect to={defaultRoutePath} /> : <ResetPassword />}
                    </AppLayout>
                </Route>

                <Route path={registerRoutePath} exact>
                    <AppLayout>
                        {isAuthenticated ? <Redirect to={defaultRoutePath} /> : <Register />}
                    </AppLayout>
                </Route>

                <Route path={defaultLoggedInRoutePath} exact>
                    <AppLayout>
                        {isAuthenticated ? <Onboarding /> : <Redirect to={defaultRoutePath} />}
                    </AppLayout>
                </Route>

                <Route path="/environments" exact>
                    <AppLayout>
                        {
                            shouldShowPostApprovalItems
                                ? (
                                    <Environments shouldShowProductionEnvironment={false} />
                                )
                                : <Redirect to={defaultRoutePath} />
                        }
                    </AppLayout>
                </Route>

                <Route path="/certificates" exact>
                    <AppLayout>
                        {
                            shouldShowPostApprovalItems
                                ? <Certificates />
                                : <Redirect to={defaultRoutePath} />
                        }
                    </AppLayout>
                </Route>

                <Route path="/api-keys" exact>
                    <AppLayout>
                        {
                            shouldShowPostApprovalItems
                                ? <ApiKeys />
                                : <Redirect to={defaultRoutePath} />
                        }
                    </AppLayout>
                </Route>

                <Redirect from="*" to={{ pathname: defaultRoutePath }} />
            </Switch>

            <Normalize />
            <GlobalFonts />
            <GlobalStyles />
        </>
    ) : <AppLoadingOverlay />;
};


export default Main;
