import React from "react";
import { InfoRowWithTooltip } from "components/reusable/InfoRow/InfoRowWithTooltip/InfoRowWithTooltip.layout";
import { InfoRow } from "components/reusable/InfoRow/InfoRow.layout";
import { SubSectionHeader } from "components/reusable/SubSectionHeader/SubSectionHeader.layout";
import { Divider } from "components/reusable/CommonTypography.styled";
import { EnvironmentProps } from "./Environment.types";

export const Environment: React.FC<EnvironmentProps> = ({
    t,
    tppId = '',
    tooltipId,
    subSectionHeader,
    url
}) => {

    return (
        <>
            <Divider />

            <SubSectionHeader
                fontSize="18px"
                copy={subSectionHeader}
            />

            <InfoRowWithTooltip
                withoutColons
                tooltipId={tooltipId}
                tooltip={t('environments:tppId.tooltip')}
                copy={t('environments:tppId.label')}
                value={tppId}
            />

            <InfoRow
                withoutColons
                copy={t('environments:url.label')}
                value={url}
            />
        </>
    );
};
