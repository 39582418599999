import JwtDecode from 'jwt-decode';

import { tokenActivityDelay, tokenExpirationTime, tokenRefreshTimeout } from 'config/config';
import { AuthTokens } from 'models/domain/user';

export type AuthTokensProvider = () => AuthTokens;
export type AuthTokensConsumer = (tokens: AuthTokens) => void;

const TOKENS_STORAGE_KEY = "okeo-tokens";

function computeTokenTime(authToken: string, authTokenExpiration: string | undefined, time: number): number {
    const exp = authTokenExpiration ? new Date(authTokenExpiration).getTime() : JwtDecode<{ exp: number }>(authToken).exp * 1000;
    const now = new Date().getTime();
    return exp - now - tokenExpirationTime + time;
}

export function isTokenExpired(authToken: string, authTokenExpiration?: string): boolean {
    const timeToExpire = computeTokenTime(authToken, authTokenExpiration, tokenRefreshTimeout);

    return timeToExpire < 0;
}

export function doesTokenNeedRefresh(authToken: string, authTokenExpiration?: string): boolean {
    const timeToRefresh = computeTokenTime(authToken, authTokenExpiration, tokenActivityDelay);

    return timeToRefresh < 0;
}

export const loadTokens: AuthTokensProvider = () => {
    const tokens = window.sessionStorage.getItem(TOKENS_STORAGE_KEY);

    try {
        return (tokens && JSON.parse(tokens)) || {};

    } catch (e) {
        if (tokens) {
            // eslint-disable-next-line no-console
            console.log(`Decoding tokens failed`, e);
        }

        return {};
    }
};

export const saveTokens: AuthTokensConsumer = tokens => {
    try {
        window.sessionStorage.setItem(TOKENS_STORAGE_KEY, JSON.stringify(tokens));

    } catch (e) {
        if (tokens) {
            // eslint-disable-next-line no-console
            console.log(`Encoding tokens failed`, e);
        }
    }
};

export const removeTokens = () => {
    window.sessionStorage.removeItem(TOKENS_STORAGE_KEY);
};
