import React from 'react';
import { OnboardingStep } from "models/app/workflow";
import { SubSectionHeader } from "components/reusable/SubSectionHeader/SubSectionHeader.layout";
import OnboardingFormCompanyDetails from './OnboardingFormCompanyDetails';
import OnboardingFormRepresentativeDetails from './OnboardingFormRepresentativeDetails';
import {
    onboardingCompanyDetailsSchema,
    OnboardingFormProps, onboardingPSD2DetailsSchema,
    onboardingRepresentativeSchema
} from "./OnboardingForm.types";
import OnboardingFormPSD2Details from "./OnboardingFormPSD2Details/OnboardingFormPSD2Details";

const OnboardingForm: React.FC<OnboardingFormProps> = props => {

    const {
        t,
        onSubmit,
        dispatchSetOnboardingStep,
        dispatchUntouchOnboardingFormFields,
        onboardingStep
    } = props;

    const steps = {
        [OnboardingStep.COMPANY_DETAILS]: () => {
            return (
                <>
                    <SubSectionHeader
                        copy={t('onboarding:preSubmission.companyDetailsStep.sectionHeader')}
                        stepCount={1}
                    />

                    <OnboardingFormCompanyDetails
                        untouchFormFields={() => {
                            dispatchUntouchOnboardingFormFields(onboardingCompanyDetailsSchema);
                        }}
                        onSubmit={() => {
                            dispatchSetOnboardingStep(OnboardingStep.REPRESENTATIVE_DETAILS);
                        }}
                    />
                </>
            );
        },
        [OnboardingStep.REPRESENTATIVE_DETAILS]: () => {
            return (
                <>
                    <SubSectionHeader
                        copy={t('onboarding:preSubmission.representativeDetailsStep.sectionHeader')}
                        stepCount={2}
                    />

                    <OnboardingFormRepresentativeDetails
                        untouchFormFields={() => {
                            dispatchUntouchOnboardingFormFields(onboardingRepresentativeSchema);
                        }}
                        onPreviousButtonClick={() => {
                            dispatchSetOnboardingStep(OnboardingStep.COMPANY_DETAILS);
                        }}
                        onSubmit={() => {
                            dispatchSetOnboardingStep(OnboardingStep.PSD2_DETAILS);
                        }}
                    />

                </>
            );
        },
        [OnboardingStep.PSD2_DETAILS]: () => {
            return (
                <>
                    <SubSectionHeader
                        copy={t('onboarding:preSubmission.psd2DetailsStep.sectionHeader')}
                        stepCount={3}
                    />

                    <OnboardingFormPSD2Details
                        untouchFormFields={() => {
                            dispatchUntouchOnboardingFormFields(onboardingPSD2DetailsSchema);
                        }}
                        onPreviousButtonClick={() => {
                            dispatchSetOnboardingStep(OnboardingStep.REPRESENTATIVE_DETAILS);
                        }}
                        onSubmit={onSubmit}
                    />
                </>
            );
        }
    };

    return (onboardingStep && steps[onboardingStep]) ? steps[onboardingStep]() : <></>;
};

export default OnboardingForm;
