import styled from 'styled-components';
import { color, FontWeightProps, space, typography } from 'styled-system';

export const TextButton = styled('button')<FontWeightProps>(
    {
        transition: 'filter .25s linear',
        '&:hover, &:focus': { filter: 'brightness(0.7)' }
    },
    typography,
    space,
    color
);

TextButton.displayName = 'TextBtn';

export default TextButton;
