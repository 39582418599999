import React from 'react';
import { Field } from "redux-form";
import { FormField } from 'components/reusable/FormField/FormField.layout';
import { withCountriesDropdown } from 'components/reusable/FormField/CountriesDropdown/CountriesDropdown.layout';
import { OkeoButton } from 'components/atoms/OkeoButton/OkeoButton.styled';
import { OnboardingFormCompanyDetailsProps } from "./OnboardingFormCompanyDetails.types";
import { ContentOnTheRightWrapper, FormContentWrapper } from "./OnboardingFormCompanyDetails.styled";
import { OnboardingField } from "./OnboardingForm.helpers";

export const OnboardingFormCompanyDetails: React.FC<OnboardingFormCompanyDetailsProps> = props => {

    const { handleSubmit, untouchFormFields, t, countriesList } = props;

    return (
        <form onSubmit={(evt) => {
            (handleSubmit(evt) as unknown as { catch: Function })?.catch?.(() => null);
        }}
        >

            <Field
                name="companyName"
                label={t('onboarding:preSubmission.companyDetailsStep.fields.companyName.label')}
                outsideTooltip={t('onboarding:preSubmission.companyDetailsStep.fields.companyName.tooltip')}
                component={OnboardingField}
                type="text"
                id="companyName"
                t={t}
            />

            <Field
                name="companyDisplayName"
                label={t('onboarding:preSubmission.companyDetailsStep.fields.companyDisplayName.label')}
                outsideTooltip={t('onboarding:preSubmission.companyDetailsStep.fields.companyDisplayName.tooltip')}
                component={OnboardingField}
                type="text"
                id="companyDisplayName"
                t={t}
            />

            <Field
                name="companyEmail"
                label={t('onboarding:preSubmission.companyDetailsStep.fields.companyEmailAddress.label')}
                component={OnboardingField}
                type="text"
                id="companyEmail"
                t={t}
            />

            <Field
                name="companyRegistrationNumber"
                label={t('onboarding:preSubmission.companyDetailsStep.fields.companyRegistrationNumber.label')}
                outsideTooltip={t('onboarding:preSubmission.companyDetailsStep.fields.companyRegistrationNumber.tooltip')}
                component={OnboardingField}
                type="text"
                id="companyRegistrationNumber"
                t={t}
            />

            <FormContentWrapper>


                <Field
                    name="companyRegistrationCountry"
                    component={FormField}
                    renderCustomInput={withCountriesDropdown({ t, countriesList })}
                    t={t}
                    id="companyRegistrationCountry"
                    label={t('onboarding:preSubmission.companyDetailsStep.fields.companyRegistrationCountry.label')}
                />

                <ContentOnTheRightWrapper>
                    <OkeoButton
                        onClick={untouchFormFields}
                        type="submit"
                    >

                        {t('onboarding:postSubmission.buttons.nextStep.text')}
                    </OkeoButton>
                </ContentOnTheRightWrapper>

            </FormContentWrapper>
        </form>
    );
};
