import { ApiKey, ApiKeyId, GetApiKeyListResponse } from 'models/domain/api-keys';

import { ApiEnvironment } from 'models/app/api-environment';

import {
    ApiKeyRequest,
    ApiKeysRequest,
    CONCEAL_API_KEY,
    ConcealApiKey,
    DELETE_API_KEY,
    DELETE_API_KEY_FAILURE,
    DELETE_API_KEY_SUCCESS,
    DeleteApiKey,
    DeleteApiKeyFailure,
    DeleteApiKeySuccess,
    GET_API_KEY,
    GET_API_KEY_FAILURE,
    GET_API_KEY_LIST,
    GET_API_KEY_LIST_FAILURE,
    GET_API_KEY_LIST_SUCCESS,
    GET_API_KEY_SUCCESS,
    GetApiKey,
    GetApiKeyFailure,
    GetApiKeyList,
    GetApiKeyListFailure,
    GetApiKeyListSuccess,
    GetApiKeySuccess,
    INSERT_API_KEY,
    INSERT_API_KEY_FAILURE,
    INSERT_API_KEY_SUCCESS,
    InsertApiKey,
    InsertApiKeyFailure,
    InsertApiKeySuccess,
    REVEAL_API_KEY,
    RevealApiKey
} from './api-keys.types';


export const revealApiKey = (environment: ApiEnvironment, apiKeyId: ApiKeyId): RevealApiKey =>
    ({ type: REVEAL_API_KEY, payload: { environment, apiKeyId } });

export const concealApiKey = (environment: ApiEnvironment, apiKeyId: ApiKeyId): ConcealApiKey =>
    ({ type: CONCEAL_API_KEY, payload: { environment, apiKeyId } });

export const insertApiKey = (environment: ApiEnvironment): InsertApiKey =>
    ({ type: INSERT_API_KEY, payload: { environment } });

export const deleteApiKey = (environment: ApiEnvironment, apiKeyId: ApiKeyId): DeleteApiKey =>
    ({ type: DELETE_API_KEY, payload: { environment, apiKeyId } });

export const getApiKey = (environment: ApiEnvironment, apiKeyId: ApiKeyId): GetApiKey =>
    ({ type: GET_API_KEY, payload: { environment, apiKeyId } });

export const getApiKeyList = (environment: ApiEnvironment): GetApiKeyList =>
    ({ type: GET_API_KEY_LIST, payload: { environment } });

export const insertApiKeySuccess = (meta: ApiKeysRequest): InsertApiKeySuccess =>
    ({ type: INSERT_API_KEY_SUCCESS, meta });

export const deleteApiKeySuccess = (meta: ApiKeyRequest): DeleteApiKeySuccess =>
    ({ type: DELETE_API_KEY_SUCCESS, meta });

export const getApiKeySuccess = (payload: ApiKey, meta: ApiKeyRequest): GetApiKeySuccess =>
    ({ type: GET_API_KEY_SUCCESS, payload, meta });

export const getApiKeyListSuccess = (payload: GetApiKeyListResponse, meta: ApiKeysRequest): GetApiKeyListSuccess =>
    ({ type: GET_API_KEY_LIST_SUCCESS, payload, meta });

export const insertApiKeyFailure = (meta: ApiKeysRequest): InsertApiKeyFailure =>
    ({ type: INSERT_API_KEY_FAILURE, meta });

export const deleteApiKeyFailure = (meta: ApiKeyRequest): DeleteApiKeyFailure =>
    ({ type: DELETE_API_KEY_FAILURE, meta });

export const getApiKeyFailure = (meta: ApiKeyRequest): GetApiKeyFailure =>
    ({ type: GET_API_KEY_FAILURE, meta });

export const getApiKeyListFailure = (meta: ApiKeysRequest): GetApiKeyListFailure =>
    ({ type: GET_API_KEY_LIST_FAILURE, meta });
