import React from 'react';
import Icons from 'assets/styles/icons/icons';
import { DecorationIcon, SuccessSubHeader, InfoMessage } from 'components/reusable/Authentication/AuthenticationPanel/AuthenticationPanel.styled';

import AuthenticationPanel from 'components/reusable/Authentication/AuthenticationPanel/AuthenticationPanel';

import { globalColors } from 'components/AppLayout/config';
import { LoginSuccessProps } from './LoginSuccess.types';


export const LoginSuccess: React.FC<LoginSuccessProps> = ({ t, dispatchNavigate }) => {

    return (
        <AuthenticationPanel pageHeader={t('pageHeader.loginToApp')}>
            <SuccessSubHeader>{t('loginSuccessWindow.header')}</SuccessSubHeader>

            <DecorationIcon
                icon={Icons.checkMarkCircle}
                color={globalColors.fontColors.green.light}
            />

            <InfoMessage>{t('loginSuccessWindow.text')}</InfoMessage>

        </AuthenticationPanel>
    );
};
