import { Action } from "models/meta/action";
import { modalActionTypes } from "store/actions/modal";
import { ModalState } from "./modalReducer.types";

const initState: ModalState = {
    open: false,
    type: '',
    data: {
        title: '',
        desc: ''
    }
};

export default (state = initState, action: Action): ModalState => {

    return ({
        [modalActionTypes.OPEN_MODAL]: () => {
            return {
                ...state,
                ...action.payload,
                open: true
            };
        },
        [modalActionTypes.CLOSE_MODAL]: () => {
            return { ...state, ...initState };

        }
    }[action?.type] || (() => state))();

};
