import React from "react";
import { InfoRow } from "components/reusable/InfoRow/InfoRow.layout";
import { SubSectionHeader } from "components/reusable/SubSectionHeader/SubSectionHeader.layout";
import { OnboardingApplicationReviewCompanyDetailsProps } from "./OnboardingApplicationReviewCompanyDetails.types";

export const OnboardingApplicationReviewCompanyDetails: React.FC<OnboardingApplicationReviewCompanyDetailsProps> = ({ t, tppDetails, countriesList }) => {

    const country = countriesList.find(country => country.alpha3Code === tppDetails?.application?.companyRegistrationCountry);

    return (
        <>
            <SubSectionHeader
                fontSize="18px"
                copy={t('onboarding:postSubmission.sectionHeaders.companyDetails')}
            />

            <InfoRow
                value={tppDetails?.application?.companyName}
                copy={t('postSubmission.fields.companyName.label')}
            />

            <InfoRow
                value={tppDetails?.application?.companyDisplayName}
                copy={t('postSubmission.fields.companyDisplayName.label')}
            />

            <InfoRow
                value={tppDetails?.application?.companyEmail}
                copy={t('postSubmission.fields.companyEmailAddress.label')}
            />

            <InfoRow
                value={tppDetails?.application?.companyRegistrationNumber}
                copy={t('postSubmission.fields.companyRegistrationNumber.label')}
            />

            <InfoRow
                value={t(`dictionaries:countryCodes.${country?.alpha2Code}`)}
                copy={t('postSubmission.fields.companyRegistrationCountry.label')}
            />

        </>
    );
};
