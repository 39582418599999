import React from 'react';
import { Field } from "redux-form";
import FileUploaderWithTooltip from "components/reusable/FileUploader/FileUploaderWithTooltip/FileUploaderWithTooltip";
import { ONBOARDING_FILE_UPLOADER_NAME } from "store/actions/fileUploader.types";
import { UploaderFile } from "components/reusable/FileUploader/FileUploader.types";
import { CERT_MIME_TYPES } from "components/pages/Onboarding/Onboarding.types";
import ErrorMessage from "components/reusable/FormField/ErrorMessage/ErrorMessage.layout";
import { OkeoButton } from 'components/atoms/OkeoButton/OkeoButton.styled';
import { ActionVariant } from "components/atoms/OkeoButton/OkeoButton.types";
import { OnboardingFormPSD2DetailsProps } from "./OnboardingFormPSD2Details.types";
import { OnboardingFormPSD2DetailsTppStatusSelector } from "./OnboardingFormPSD2DetailsTppStatusSelector.layout";
import { SpaceBetween } from "../OnboardingFormRepresentativeDetails.styled";
import { FormContentWrapper } from "../OnboardingFormCompanyDetails.styled";
import OnboardingFormPSD2DetailsApplicableRoles from "./OnboardingFormPSD2DetailsApplicableRoles.layout";
import { CheckboxesErrorMessageWrapper } from "./OnboardingFormPSD2Details.styled";
import { declaredAispField, declaredPispField, eidasQwacFileField } from "../OnboardingForm.types";

export const OnboardingFormPSD2Details: React.FC<OnboardingFormPSD2DetailsProps> = props => {

    const {
        handleSubmit,
        formData,
        formErrors,
        dispatchDismissAllToasts,
        untouchFormFields,
        dispatchClearAsyncError,
        dispatchChangeForm,
        onPreviousButtonClick,
        t
    } = props;

    const showFileUploaderError = formErrors?.[eidasQwacFileField];
    const showError = !!(formErrors?.declaredAisp || formErrors?.declaredPisp);
    const aispOrPispCheckboxTouched = !!(formData?.fields?.declaredAisp?.touched || formData?.fields?.declaredPisp?.touched);

    return (
        <form onSubmit={(evt) => {
            (handleSubmit(evt) as unknown as { catch: Function })?.catch?.(() => null);
        }}
        >

            <Field
                t={t}
                name="tppStatus"
                component={OnboardingFormPSD2DetailsTppStatusSelector}
            />

            <OnboardingFormPSD2DetailsApplicableRoles
                showError={showError && aispOrPispCheckboxTouched}
                t={t}
            />

            <CheckboxesErrorMessageWrapper showError={showError}>
                {(aispOrPispCheckboxTouched) && showError && (
                    <ErrorMessage
                        translationKey={'common:validationMsg.roleMandatory'}
                        dataTestId={'applicable-roles-error-message'}
                    />
                )}
            </CheckboxesErrorMessageWrapper>

            <FileUploaderWithTooltip
                fieldLocationInFormState={eidasQwacFileField}
                onFileUploaderUnmounted={dispatchDismissAllToasts}
                acceptedFileTypes={CERT_MIME_TYPES}
                tooltip={t('onboarding:preSubmission.psd2DetailsStep.fields.eidasQwac.tooltip')}
                header={t('onboarding:preSubmission.psd2DetailsStep.fields.eidasQwac.label')}
                id={ONBOARDING_FILE_UPLOADER_NAME}
                showError={!!showFileUploaderError}
                onChangesToAttachedFiles={(files: UploaderFile[]) => {

                    if (files?.[0]) {

                        files[0].text().then((content: string) => {
                            dispatchChangeForm(eidasQwacFileField, content);
                        });
                    } else {
                        dispatchChangeForm(eidasQwacFileField, '');
                    }
                }}
            />

            <FormContentWrapper>

                <SpaceBetween>

                    <OkeoButton
                        onClick={() => {
                            untouchFormFields();

                            onPreviousButtonClick();
                        }}
                        actionVariant={ActionVariant.DANGER}
                        variant
                        type="button"
                    >
                        {t('onboarding:postSubmission.buttons.previousStep.text')}
                    </OkeoButton>

                    <OkeoButton
                        disabled={false}
                        onClick={() => {

                            [declaredAispField, declaredPispField, eidasQwacFileField].forEach(dispatchClearAsyncError);

                            untouchFormFields();
                        }}
                        type="submit"
                    >
                        {t('onboarding:postSubmission.buttons.submit.text')}
                    </OkeoButton>

                </SpaceBetween>

            </FormContentWrapper>

        </form>
    );
};
