import React from "react";
import Icons from "assets/styles/icons/icons";
import { OnboardingStep } from "models/app/workflow";
import { ApplicationStatus } from "models/domain/tpp";
import Tooltip from "components/reusable/Tooltip/Tooltip.layout";
import { OutsideTooltipIcon } from "components/reusable/Tooltip/Tooltip.styled";
import { OkeoButton } from "components/atoms/OkeoButton/OkeoButton.styled";
import { globalColors } from "components/AppLayout/config";
import {
    OnboardingApplicationReviewActionablesTooltip,
    OnboardingApplicationReviewActionablesWrapper,
    OnboardingApplicationReviewDivider
} from "./OnboardingApplicationReview.styled";
import { OnboardingApplicationReviewActionablesProps } from "./OnboardingApplicationReviewActionables.types";

export const OnboardingApplicationReviewActionables: React.FC<OnboardingApplicationReviewActionablesProps> = ({
    t,
    dispatchSetOnboardingStep,
    dispatchDestroyOnboardingForm,
    applicationStatus
}) => {

    const labelAndTooltip = applicationStatus && {
        [ApplicationStatus.ACCEPTED]: () => {
            return {
                label: t('postSubmission.buttons.editApplication.text'),
                tooltip: t('postSubmission.buttons.editApplication.tooltip')
            };
        },
        [ApplicationStatus.REJECTED]: () => {
            return {
                label: t('postSubmission.buttons.newApplication.text'),
                tooltip: t('postSubmission.buttons.newApplication.tooltip')
            };
        },
    }[applicationStatus];

    return labelAndTooltip ? (

        <>

            <OnboardingApplicationReviewDivider />

            <OnboardingApplicationReviewActionablesWrapper>

                <OnboardingApplicationReviewActionablesTooltip>
                    <OutsideTooltipIcon
                        show
                        icon={Icons.infoCircle}
                        color={globalColors.fontColors.green.light}
                        data-for="application-review"
                        data-tip={t(labelAndTooltip().tooltip)}
                    />

                    <Tooltip id="application-review" />
                </OnboardingApplicationReviewActionablesTooltip>

                <OkeoButton
                    onClick={() => {

                        if (applicationStatus === ApplicationStatus.REJECTED) {
                            dispatchDestroyOnboardingForm();
                        }

                        dispatchSetOnboardingStep(OnboardingStep.COMPANY_DETAILS);
                    }}
                    variant="light"
                    type="button"
                >
                    {labelAndTooltip().label}
                </OkeoButton>

            </OnboardingApplicationReviewActionablesWrapper>

        </>
    ) : null;

};
