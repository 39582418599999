import { ApiError } from "models/meta/api";
import { Action } from "models/meta/action";
import { HttpStatus } from "utils/constants/http-status";
import { actionErrorToast, dismissAllToasts, navigate } from "store/actions/common";
import { defaultRoutePath } from "config/config";
import { CsrUploadErrorMessages } from "models/domain/tpp";
import { logout } from "store/actions/user";
import {
    ErrorHandler,
    INVALID_CSR_STRUCTURE_TOAST_ID,
    INVALID_CSR_VALUES_TOAST_ID,
    UNATHORIZED_TOAST_ID
} from "./certificatesEpic.types";

export const handleUploadCsrErrors = (error: ApiError) => [
    {
        predicate: () => {
            return (
                (error.status === HttpStatus.BAD_REQUEST)
                && (error.message === CsrUploadErrorMessages.INVALID_FORMAT)
            );
        },
        handler: () => {
            return [
                dismissAllToasts(),
                actionErrorToast(
                    'common',
                    'invalidCsrStructure',
                    {},
                    {
                        pauseOnHover: true,
                        toastId: INVALID_CSR_STRUCTURE_TOAST_ID,
                        autoClose: 1000 * 60
                    }
                )];
        }
    },
    {
        predicate: () => {
            return (
                (error.status === HttpStatus.BAD_REQUEST)
                && (error.message === CsrUploadErrorMessages.INVALID_VALUES)
            );
        },
        handler: () => {
            return [
                dismissAllToasts(),
                actionErrorToast(
                    'common',
                    'invalidCsrValues',
                    {},
                    {
                        pauseOnHover: true,
                        toastId: INVALID_CSR_VALUES_TOAST_ID,
                        autoClose: 1000 * 60
                    }
                )];
        }
    }
];

export function handleCertificateErrors(error: ApiError, errorsHandler?: ErrorHandler): Action[] {

    const handlers = [
        {
            predicate: () => {
                return error.status === HttpStatus.FORBIDDEN;
            },
            handler: () => {

                return [
                    logout(),
                    navigate(defaultRoutePath),
                    actionErrorToast(
                        'common',
                        'genericUnauthorizedMessage',
                        {},
                        { toastId: UNATHORIZED_TOAST_ID }
                    )
                ];
            }
        }
    ].concat(errorsHandler ? errorsHandler?.(error) : []);

    const handler = handlers?.find(({ predicate }) => predicate());

    const defaultActions = [
        dismissAllToasts(),
        ...(error.actions || []),
    ];

    return handler ? handler.handler() : defaultActions;
}
