import React, { FunctionComponent } from "react";

import SpinnerPaletteRing from 'components/reusable/Spinners/SpinnerPaletteRing';
import Styled from 'components/reusable/Overalys/Overlays.styled';

const AppLoadingOverlay: FunctionComponent = () => (
    <Styled.AppLoadingPlaceholder>
        <Styled.CenteredSpinnerPaletteRing>
            <SpinnerPaletteRing height={200} width={200} strokeWidth={10} />
        </Styled.CenteredSpinnerPaletteRing>
    </Styled.AppLoadingPlaceholder>
);

export default AppLoadingOverlay;
