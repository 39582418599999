import React from 'react';

import AuthorizationPage from 'components/reusable/Authentication/AuthenticationPage/AuthenticationPage';
import { RegisterStep } from 'models/app/workflow';
import RegisterForm from './panels/RegisterForm/RegisterForm';
import RegisterSuccess from './panels/RegisterSuccess/RegisterSuccess';

import { RegisterProps } from './Register.types';

const renderRegisterStep = (currentStep: RegisterStep): JSX.Element => {
    const conditions: Record<RegisterStep, JSX.Element> = {
        REGISTRATION: <RegisterForm />,
        SUCCESS: <RegisterSuccess />,
    };

    return conditions[currentStep ?? RegisterStep.REGISTRATION];
};

export const Register: React.FC<RegisterProps> = ({ registerStep }) => {

    return <AuthorizationPage>{renderRegisterStep(registerStep)}</AuthorizationPage>;

};
