import { AppState } from 'models/app/store';
import {
    AppActions,
    END_APP_LOADING,
    START_APP_LOADING
} from 'store/actions/app.types';

import { CLEAR_STORE, ClearStore } from 'store/actions/common.types';
import { createReducer, Handlers } from './reducer';
import { SET_LANGUAGE } from '../actions/app.types';


export const initialState: AppState = {
    isAppLoading: false,
    language: 'en-GB',
};

const handlers: Handlers<AppState, AppActions & ClearStore> = {
    [START_APP_LOADING]: () => {
        return { isAppLoading: true };
    },
    [SET_LANGUAGE]: (payload: string) => {
        return { language: payload };
    },
    [END_APP_LOADING]: () => {
        return { isAppLoading: false };
    },
    [CLEAR_STORE]: () => {
        return initialState;
    }
};

export default createReducer(initialState, handlers);
