import React from 'react';

import AuthorizationPage from 'components/reusable/Authentication/AuthenticationPage/AuthenticationPage';
import { LoginStep } from 'models/app/workflow';
import LoginForm from './panels/LoginForm/LoginForm';
import LoginSuccess from './panels/LoginSuccess/LoginSuccess';

import { LoginProps } from './Login.types';

const renderLoginStep = (currentStep: LoginStep): JSX.Element => {
    const conditions: Record<LoginStep, JSX.Element> = {
        AUTHORISATION: <LoginForm />,
        SUCCESS: <LoginSuccess />,
    };

    return conditions[currentStep ?? LoginStep.AUTHORISATION];
};

export const Login: React.FC<LoginProps> = ({ loginStep }) => {

    return <AuthorizationPage>{renderLoginStep(loginStep)}</AuthorizationPage>;
};
