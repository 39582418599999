import styled from 'styled-components';
import flags from 'assets/img/flags/flags32.png';

const CountryFlag = styled.span.attrs(props => ({ style: { background: `url(${flags}) 0 ${props.prefix}px` } }))`
    display: inline-block;
    height: 32px;
    width: 32px;
    margin: 0 16px;
    background-image: url(${flags});
    background-repeat: no-repeat;
    transform: scale(.8);
`;


export default { CountryFlag };

export const flagPositions = {
    EU: -256,
    AD: -704,
    AE: -736,
    AF: -768,
    AG: -800,
    AI: -832,
    AL: -864,
    AM: -896,
    AO: -928,
    AQ: -960,
    AR: -992,
    AS: -1024,
    AT: -1056,
    AU: -1088,
    HM: -1088,
    CX: -1088,
    NF: -1088,
    CC: -1088,
    AW: -1120,
    AX: -1152,
    AZ: -1184,
    BA: -1216,
    BB: -1248,
    BD: -1280,
    BE: -1312,
    BF: -1344,
    BG: -1376,
    BH: -1408,
    BI: -1440,
    BJ: -1472,
    BM: -1504,
    BN: -1536,
    BO: -1568,
    BR: -1600,
    BS: -1632,
    BT: -1664,
    BW: -1696,
    BY: -1728,
    BZ: -1760,
    CA: -1792,
    CD: -1824,
    CF: -1856,
    CG: -1888,
    CH: -1920,
    CI: -1952,
    CK: -1984,
    CL: -2016,
    CM: -2048,
    CN: -2080,
    CO: -2112,
    CR: -2144,
    CU: -2176,
    CV: -2208,
    CY: -2240,
    CZ: -2272,
    DE: -2304,
    DJ: -2336,
    DK: -2368,
    DM: -2400,
    DO: -2432,
    DZ: -2464,
    EC: -2496,
    EE: -2528,
    EG: -2560,
    EH: -2592,
    ER: -2624,
    ES: -2656,
    ET: -2688,
    FI: -2720,
    FJ: -2752,
    FM: -2784,
    FO: -2816,
    FR: -2848,
    WF: -2848,
    GF: -2848,
    PM: -2848,
    TF: -2848,
    BL: -2848,
    CP: -2848,
    MF: -2848,
    YT: -2848,
    GA: -2880,
    GB: -2912,
    FK: -2912,
    GS: -2912,
    IO: -2912,
    PN: -2912,
    SH: -2912,
    GD: -2944,
    GE: -2976,
    GG: -3008,
    GH: -3040,
    GI: -3072,
    GL: -3104,
    GM: -3136,
    GN: -3168,
    GP: -3200,
    GQ: -3232,
    GR: -3264,
    GT: -3296,
    GU: -3328,
    GW: -3360,
    GY: -3392,
    HK: -3424,
    HN: -3456,
    HR: -3488,
    HT: -3520,
    HU: -3552,
    ID: -3584,
    MC: -3584,
    IE: -3616,
    IL: -3648,
    IM: -3680,
    IN: -3712,
    IQ: -3744,
    IR: -3776,
    IS: -3808,
    IT: -3840,
    JE: -3872,
    JM: -3904,
    JO: -3936,
    JP: -3968,
    KE: -4000,
    KG: -4032,
    KH: -4064,
    KI: -4096,
    KM: -4128,
    KN: -4160,
    KP: -4192,
    KR: -4224,
    KW: -4256,
    KY: -4288,
    KZ: -4320,
    LA: -4352,
    LB: -4384,
    LC: -4416,
    LI: -4448,
    LK: -4480,
    LR: -4512,
    LS: -4544,
    LT: -4576,
    LU: -4608,
    LV: -4640,
    LY: -4672,
    MA: -4704,
    MD: -4736,
    ME: -4768,
    MG: -4800,
    MH: -4832,
    MK: -4864,
    ML: -4896,
    MM: -4928,
    MN: -4960,
    MO: -4992,
    MQ: -5024,
    MR: -5056,
    MS: -5088,
    MT: -5120,
    MU: -5152,
    MV: -5184,
    MW: -5216,
    MX: -5248,
    MY: -5280,
    MZ: -5312,
    NA: -5344,
    NC: -5376,
    NE: -5408,
    NG: -5440,
    NI: -5472,
    NL: -5504,
    BQ: -5504,
    NO: -5536,
    BV: -5536,
    NQ: -5536,
    SJ: -5536,
    NP: -5568,
    NR: -5600,
    NZ: -5632,
    TK: -5632,
    OM: -5664,
    PA: -5696,
    PE: -5728,
    PF: -5760,
    PG: -5792,
    PH: -5824,
    PK: -5856,
    PL: -5888,
    PR: -5920,
    PS: -5952,
    PT: -5984,
    PW: -6016,
    PY: -6048,
    QA: -6080,
    RE: -6112,
    RO: -6144,
    RS: -6176,
    RU: -6208,
    RW: -6240,
    SA: -6272,
    SB: -6304,
    SC: -6336,
    SD: -6368,
    SE: -6400,
    SG: -6432,
    SI: -6464,
    SK: -6496,
    SL: -6528,
    SM: -6560,
    SN: -6592,
    SO: -6624,
    SR: -6656,
    ST: -6688,
    SV: -6720,
    SY: -6752,
    SZ: -6784,
    TC: -6816,
    TD: -6848,
    TG: -6880,
    TH: -6912,
    TJ: -6944,
    TL: -6976,
    TM: -7008,
    TN: -7040,
    TO: -7072,
    TR: -7104,
    TT: -7136,
    TV: -7168,
    TW: -7200,
    TZ: -7232,
    UA: -7264,
    UG: -7296,
    US: -7328,
    MP: -7328,
    UM: -7328,
    UY: -7360,
    UZ: -7392,
    VA: -7424,
    VC: -7456,
    VE: -7488,
    VG: -7520,
    VI: -7552,
    VN: -7584,
    VU: -7616,
    WS: -7648,
    YE: -7680,
    ZA: -7712,
    ZM: -7744,
    ZW: -7776,
    SX: -7808,
    CW: -7840,
    SS: -7872,
    NU: -7904,
};
