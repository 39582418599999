import React from 'react';
import {
    loginRoutePath,
    registerRoutePath,
} from 'config/config';

import StandardTransition from 'components/reusable/StandardTransition/StandardTransition.layout';

import { OkeoButton } from 'components/atoms/OkeoButton/OkeoButton.styled';
import { LoginOrRegisterProps } from './LoginOrRegister.types';
import Styled from './LoginOrRegister.styled';

export const LoginOrRegister: React.FC<LoginOrRegisterProps> = ({ t, path, dispatchNavigate }) => {

    return (
        <Styled.TransitionWrap>
            <StandardTransition inProp={path === loginRoutePath}>
                <Styled.ContentWrap>
                    <Styled.Label>{t('leftSideBar.user.registerText')}</Styled.Label>
                    <OkeoButton onClick={() => dispatchNavigate(registerRoutePath)}>
                        {t('buttons.register.text')}
                    </OkeoButton>
                </Styled.ContentWrap>
            </StandardTransition>

            <StandardTransition inProp={path !== loginRoutePath}>
                <Styled.ContentWrap>
                    <Styled.Label>{path === registerRoutePath && t('leftSideBar.user.loginText')}</Styled.Label>
                    <OkeoButton onClick={() => dispatchNavigate(loginRoutePath)}>
                        {t('buttons.login.text')}
                    </OkeoButton>
                </Styled.ContentWrap>
            </StandardTransition>
        </Styled.TransitionWrap>
    );

};
