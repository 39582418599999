import { css, keyframes } from 'styled-components';

const standardDuration = '0.25s';

export const BgColorTransition = css`
    transition: background-color ${standardDuration} linear;
`;

export const ColorTransition = css`
    transition: color ${standardDuration} linear;
`;

export const FontSizeTransition = css`
    transition: font-size ${standardDuration} linear;
`;

export const BorderColorTransition = css`
    transition: border-top-color ${standardDuration} linear, 
                border-right-color ${standardDuration} linear,
                border-bottom-color ${standardDuration} linear, 
                border-left-color ${standardDuration} linear;
`;

export const ColorAndFilterTransition = css`
    transition: color ${standardDuration} linear, filter ${standardDuration} linear;
`;

export const FillTransition = css`
    transition: fill ${standardDuration} linear;
`;

export const FadeInKeyframe = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const FadeIn = css`
    opacity: 0;
    animation: ${FadeInKeyframe} 0.5s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 0.2s;
`;
