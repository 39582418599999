import { TFunction } from "i18next";
import { DropdownOption } from "models/app/common";

export const createTppStatuses = (t: TFunction): DropdownOption[] => {
    return [
        {
            key: 'REGULATED_TPP',
            text: t('onboarding:postSubmission.fields.tppStatus.valueRegulatedTpp'),
            value: 'REGULATED_TPP'
        },

        {
            key: 'OBTAINING_TPP_LICENSE',
            text: t('onboarding:postSubmission.fields.tppStatus.valueObtainingLicense'),
            value: 'OBTAINING_TPP_LICENSE'
        },
    ];
};
