import React from "react";
import Icons from "assets/styles/icons/icons";
import { globalColors } from "components/AppLayout/config";
import { AnimatedLabelWrap } from "components/reusable/FormField/FormField.styled";
import { Dropdown } from "components/reusable/Dropdown/Dropdown.layout";
import AnimatedLabel from "components/reusable/FormField/AnimatedLabel/AnimatedLabel.layout";
import ErrorMessage from "components/reusable/FormField/ErrorMessage/ErrorMessage.layout";
import Tooltip from "components/reusable/Tooltip/Tooltip.layout";
import { OutsideTooltipIcon } from "components/reusable/Tooltip/Tooltip.styled";
import { CenteredSpaceBetween } from "../OnboardingForm.styled";
import { TppStatusSelector, TppStatusSelectorWrapper } from "./OnboardingFormPSD2DetailsTppStatusSelector.styled";
import { createTppStatuses } from "./OnboardingFormPSD2DetailsTppStatusSelector.helpers";
import { OnboardingFormPSD2DetailsTppStatusSelectorProps } from "./OnboardingFormPSD2DetailsTppStatusSelector.types";

export const OnboardingFormPSD2DetailsTppStatusSelector: React.FC<OnboardingFormPSD2DetailsTppStatusSelectorProps> = (
    { input, meta, t }
) => {

    const showError = meta.touched && meta.error;

    return (

        <CenteredSpaceBetween>
            <TppStatusSelectorWrapper showError={showError}>
                <TppStatusSelector>
                    <AnimatedLabelWrap hasError={showError}>

                        <Dropdown
                            input={input}
                            options={createTppStatuses(t)}
                            dropdownPosition="bottom"
                        />

                        <AnimatedLabel
                            hasError={showError}
                            hasValue={input.value}
                            text={t('onboarding:postSubmission.fields.tppStatus.label')}
                        />

                    </AnimatedLabelWrap>

                </TppStatusSelector>

                {showError && (
                    <ErrorMessage
                        translationKey={meta.error.key || meta.error}
                        translationValues={meta.error.values}
                        dataTestId={input.name}
                    />
                )}
            </TppStatusSelectorWrapper>

            <div>
                <OutsideTooltipIcon
                    show
                    icon={Icons.infoCircle}
                    color={globalColors.fontColors.green.light}
                    data-for="applicable-roles"
                    data-test-id="applicable-roles"
                    data-tip={t('onboarding:preSubmission.psd2DetailsStep.fields.tppStatus.tooltip')}
                />

                <Tooltip id="applicable-roles" />
            </div>
        </CenteredSpaceBetween>
    );
};
