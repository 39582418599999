import React, { ReactNode } from 'react';
import { toast, ToastOptions } from 'react-toastify';

import { ResultType } from 'models/domain/common';
import Icons from 'assets/styles/icons/icons';
import { IconFont } from 'assets/styles/icons/Icons.styled';

import CloseToastButton from '../CloseToastButton/CloseToastButton.layout';
import Styled from './AppToast.styled';
import { defaultToastOptions, toastTypes } from './AppToast.types';

export default function showToast(content: string | ReactNode, type: ResultType, options?: ToastOptions) {
    const { borderColor, dataTestId, color, iconName, closeable } = toastTypes[type];

    const container = (
        <Styled.ToastMessageWrapper borderColor={borderColor} data-test-id={dataTestId}>
            <IconFont icon={Icons[iconName]} fontSize={4} mr={3} color={color} />
            {content}
        </Styled.ToastMessageWrapper>
    );

    return toast(
        container, {
            closeButton: closeable && <CloseToastButton />,
            ...defaultToastOptions,
            ...options
        }
    );
}
