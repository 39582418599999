import React from "react";
import Icons from "assets/styles/icons/icons";
import Tooltip from "components/reusable/Tooltip/Tooltip.layout";
import { OutsideTooltipIcon } from "components/reusable/Tooltip/Tooltip.styled";
import { InfoRow } from "../InfoRow.layout";
import { InfoRowWithTooltipWrapper, TooltipWrapper } from "./InfoRowWithTooltip.styled";
import { InfoRowWithTooltipProps } from "./InfoRowWithTooltip.types";

export const InfoRowWithTooltip: React.FC<InfoRowWithTooltipProps> = ({
    copy,
    value,
    tooltip,
    tooltipId,
    withoutColons = false
}) => {

    return (

        <InfoRowWithTooltipWrapper>

            <InfoRow
                withoutMargin
                withoutColons={withoutColons}
                copy={copy}
                value={value}
            />

            <TooltipWrapper>
                <OutsideTooltipIcon
                    show={!!tooltip}
                    icon={Icons.infoCircle}
                    color="accent.light"
                    data-for={tooltipId}
                    data-tip={tooltip}
                    data-test-id={tooltipId}
                />

                <Tooltip id={tooltipId} />
            </TooltipWrapper>
        </InfoRowWithTooltipWrapper>
    );
};
