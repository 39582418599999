import { dictionary } from 'services/api/dictionary';
import { CountriesInfo } from 'models/domain/dictionary';


class DictionaryService {
    static getAllCountryCodesDetails(countryCodes?: string[]): Promise<CountriesInfo> {
        return dictionary.getAllCountryCodesDetails(countryCodes);
    }
}

export default DictionaryService;
