import { LoginStep, RegisterStep, ResetPasswordStep } from 'models/app/workflow';
import { LoginRequest, RegisterRequest, ResetPasswordRequest, User } from 'models/domain/user';

import {
    LOGOUT,
    LOGIN,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    Logout,
    Login,
    LoginFailure,
    LoginSuccess,
    REGISTER,
    REGISTER_FAILURE,
    REGISTER_SUCCESS,
    Register,
    RegisterFailure,
    RegisterSuccess,
    RESET_PASSWORD,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_SUCCESS,
    ResetPassword,
    ResetPasswordFailure,
    ResetPasswordSuccess,
    RemoveToken,
    REMOVE_TOKEN,
    SET_LOGIN_STEP,
    SET_REGISTER_STEP,
    SET_RESET_PASSWORD_STEP,
    SetLoginStep,
    SetRegisterStep,
    SetResetPasswordStep,
} from './user.types';


export const setRegisterStep = (registerStep: RegisterStep): SetRegisterStep => ({ type: SET_REGISTER_STEP, payload: registerStep });
export const register = (credentials: RegisterRequest): Register => ({ type: REGISTER, payload: credentials });
export const registerSuccess = (status: unknown): RegisterSuccess => ({ type: REGISTER_SUCCESS, payload: status });
export const registerFailure = (): RegisterFailure => ({ type: REGISTER_FAILURE });

export const setLoginStep = (loginStep: LoginStep): SetLoginStep => ({ type: SET_LOGIN_STEP, payload: loginStep });
export const login = (credentials: LoginRequest): Login => ({ type: LOGIN, payload: credentials });
export const loginSuccess = (user: User): LoginSuccess => ({ type: LOGIN_SUCCESS, payload: user });
export const loginFailure = (): LoginFailure => ({ type: LOGIN_FAILURE });

export const setResetPasswordStep = (resetPasswordStep: ResetPasswordStep): SetResetPasswordStep => ({ type: SET_RESET_PASSWORD_STEP, payload: resetPasswordStep });
export const resetPassword = (payload: ResetPasswordRequest): ResetPassword => ({ type: RESET_PASSWORD, payload });
export const resetPasswordSuccess = (status: unknown): ResetPasswordSuccess => ({ type: RESET_PASSWORD_SUCCESS, payload: status });
export const resetPasswordFailure = (): ResetPasswordFailure => ({ type: RESET_PASSWORD_FAILURE });

export const logout = (): Logout => ({ type: LOGOUT });

export const removeToken = (): RemoveToken => ({ type: REMOVE_TOKEN });

