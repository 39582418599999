import { ApplicationStatus } from "models/domain/tpp";
import theme from "config/theme";

export const getApplicationStatusTranslationPath = (
    applicationStatus?: ApplicationStatus
): string => {

    return ({
        [ApplicationStatus.ACCEPTED]: () => {
            return 'postSubmission.sectionHeaders.applicationApproved';
        },
        [ApplicationStatus.REJECTED]: () => {
            return 'postSubmission.sectionHeaders.applicationRejected';
        },
    }[applicationStatus || ''] || (() => {
        return 'postSubmission.sectionHeaders.applicationSubmitted';
    }))();
};

export const getApplicationStatusColorAndTranslationPath = (
    applicationStatus?: ApplicationStatus): (
) => { color?: string, copy: string } => {

    return {
        [ApplicationStatus.ACCEPTED]: () => {
            return { copy: 'postSubmission.fields.applicationStatus.valueAccepted' };
        },
        [ApplicationStatus.REJECTED]: () => {
            return {
                color: theme.colors.error.base,
                copy: 'postSubmission.fields.applicationStatus.valueRejected'
            };
        },
        [ApplicationStatus.SUBMITTED]: () => {
            return { copy: 'postSubmission.fields.applicationStatus.valueSubmitted' };
        },
        [ApplicationStatus.IN_REVIEW]: () => {
            return { copy: 'postSubmission.fields.applicationStatus.valueInreview' };
        },
        [ApplicationStatus.PENDING_CHANGES]: () => {
            return { copy: 'postSubmission.fields.applicationStatus.valuePending' };
        },
    }[applicationStatus || ''] || (() => ({ copy: '' }));
};
