import React from 'react';
import { Trans } from 'react-i18next';
import {
    Anchor,
    Divider,
    Emphasis,
    InternalLink,
    ListItem,
    OrderedList,
    Paragraph,
    Quotation,
    Underline,
    UnorderedList,
} from 'components/reusable/CommonTypography.styled';

import { TranslationWithMarkupProps } from './TranslationWithMarkup.type';

const defaultComponents: Record<string, React.ReactNode> = {
    p: <Paragraph />,
    strong: <Emphasis />,
    u: <Underline />,
    q: <Quotation />,
    ol: <OrderedList />,
    ul: <UnorderedList />,
    li: <ListItem />,
    a: <Anchor />,
    ref: <InternalLink />,
    hr: <Divider />
};

const TranslationWithMarkup: React.FC<TranslationWithMarkupProps> = ({
    translationKey,
    components,
    values,
    ns,
    t,
    parent
}) => {

    return (
        <Trans
            i18nKey={translationKey}
            ns={ns}
            t={t}
            values={{ ...values }}
            components={{ ...defaultComponents, ...components }}
            parent={parent}
        />
    );
};

export default TranslationWithMarkup;
