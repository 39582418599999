import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { getFormAsyncErrors, reduxForm } from 'redux-form';

import { State } from 'models/app/store';
import { Dispatch } from 'models/meta/action';
import { resetPassword } from 'store/actions/user';

import { createAsyncValidate, shouldAsyncValidate } from "utils/form";
import {
    RESET_FORM,
    ResetPasswordFormDispatchProps,
    ResetPasswordFormProps,
    ResetPasswordFormStateProps,
    schema,
} from './ResetPasswordForm.types';

import { ResetPasswordForm } from './ResetPasswordForm.layout';

const mapStateToProps = (state: State): ResetPasswordFormStateProps => ({
    fieldsValues: state.form?.[RESET_FORM]?.values || {},
    formErrors: getFormAsyncErrors(RESET_FORM)(state) || {},
    loginStep: state.user.loginStep,
    language: state.app.language
});

export const mapDispatchToProps = (dispatch: Dispatch): ResetPasswordFormDispatchProps =>
    ({ dispatchResetPassword: ({ email, languageCode }) => dispatch(resetPassword({ email, languageCode })) });

const decorate = compose<React.FC<Partial<ResetPasswordFormProps>>>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withTranslation('common'),
    reduxForm({
        form: RESET_FORM,
        initialValues: { emailAddress: '' },
        asyncValidate: createAsyncValidate(schema),
        shouldAsyncValidate
    })
);

export default decorate(ResetPasswordForm);
