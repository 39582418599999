import React from 'react';
import Icons from 'assets/styles/icons/icons';

import { SideBarLinkProps } from './SideBarLink.types';
import Styled from './SideBarLink.styled';

export const SideBarLink: React.FC<SideBarLinkProps> = ({ path, iconName, text, isExternal }) => {
    return isExternal
        ? (
            <Styled.SideBarExternalLink
                href={path}
                target="_blank"
                rel="noreferrer"
            >
                {iconName && <Styled.SideBarLinkIcon icon={Icons[iconName]} />}
                { text }
            </Styled.SideBarExternalLink>
        ) : (
            <Styled.SideBarLink
                to={path}
                exact
            >
                {iconName && <Styled.SideBarLinkIcon icon={Icons[iconName]} />}
                { text }
            </Styled.SideBarLink>
        );

};
