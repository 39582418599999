import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import { debugMode } from 'config/config';

i18next.use(Backend)
    .use(initReactI18next)
    .init({
        backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
        lng: 'en-GB',
        ns: [
            'common',
            'getting-started',
            'availability-reports',
            'open-banking',
            'onboarding',
            'environments',
            'test-certificates',
            'dictionaries'
        ],
        interpolation: { escapeValue: false }, // React already does escaping
        load: 'currentOnly',
        fallbackLng: 'en-GB',
        preload: ['en-GB'],
        react: { useSuspense: false },
        saveMissing: debugMode,
        missingKeyHandler: (lngs: string[], ns: string, key: string, fallbackValue: string) => {
            if (debugMode) {
                // eslint-disable-next-line no-console
                console.warn(`Missing ‘${lngs.join('’, ’')}’ translation key\n  key: ‘${ns}:${key}’\n  fallback: ‘${fallbackValue}’\n`);
            }
        }
    });
