import { UUID } from "./common";

export type TppId = UUID;

export interface TppStatus {
    accessStatus: AccessStatus,
    tppId: TppId
}

export enum CsrUploadErrorMessages {
    INVALID_FORMAT = 'CSR has invalid format',
    INVALID_VALUES = 'CSR is not compatible with TPP data'
}

export enum AccessStatus {
    PENDING = 'PENDING',
    GRANTED = 'GRANTED',
    REJECTED = 'REJECTED',
    REVOKED = 'REVOKED'
}

export enum ApplicationStatus {
    SUBMITTED = 'SUBMITTED',
    IN_REVIEW = 'IN_REVIEW',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    PENDING_CHANGES = 'PENDING_CHANGES'
}

export type CountryCode = string;

export interface TppDetails {
    tppId: TppId;
    accessStatus: AccessStatus;
    eidasQwacUploaded: boolean;
}

export interface GetTppDetailsResponse {
    application: TppApplication;
    tppDetails: TppDetails;
}

export interface TppApplication {
    eidasQwacFile?: string,
    applicationStatus: ApplicationStatus;
    applicationSubmissionDate: Date;
    applicationApprovalDate: Date;
    companyName: string;
    companyRegistrationNumber: string;
    companyDisplayName: string;
    companyRegistrationCountry: CountryCode;
    companyEmail: string;
    tppStatus: TppApplicationStatus;
    declaredAisp: boolean;
    declaredPisp: boolean;
    representativeFirstName: string;
    representativeLastName: string;
    representativePosition: string;
}

export enum TppApplicationStatus {
    REGULATED_TPP = 'REGULATED_TPP',
    OBTAINING_TPP_LICENSE = 'OBTAINING_TPP_LICENSE'
}
