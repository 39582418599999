import {
    GetTppDetailsResponse,
    TppApplication,
    TppId,
    TppStatus,
} from "models/domain/tpp";
import { onboarding } from "./api/onboarding";

class OnboardingService {
    static getTppStatus(): Promise<TppStatus | null> {
        return onboarding.getTppStatus();
    }

    static getTppDetails(tppId: TppId): Promise<GetTppDetailsResponse> {
        return onboarding.getTppDetails(tppId);
    }

    static submitTppApplication(tppApplication: TppApplication): Promise<string> {
        const { declaredAisp, declaredPisp, ...rest } = tppApplication;

        return onboarding.submitTppApplication({
            ...rest,
            declaredAisp: declaredAisp || false,
            declaredPisp: declaredPisp || false
        });
    }

    static updateTppApplication(tppId: TppId, tppApplication: TppApplication): Promise<string> {
        return onboarding.updateTppApplication(tppId, tppApplication);
    }
}

export default OnboardingService;
