import { ToastOptions } from 'react-toastify/dist/types';

import { ResultType } from 'models/domain/common';
import Icons from "assets/styles/icons/icons";
import { errorColor, infoColor, successColor, warningColor } from 'components/AppLayout/config';

interface ToastType {
    borderColor: string;
    dataTestId: string;
    color: string;
    iconName: keyof typeof Icons,
    closeable: boolean;
}

type ToastTypes = Record<ResultType, ToastType>;

export const toastTypes: ToastTypes = {
    INFO: {
        borderColor: infoColor,
        dataTestId: 'toast-message-info',
        color: 'gray.xdark',
        iconName: 'infoCircle',
        closeable: false
    },
    WARN: {
        borderColor: warningColor,
        dataTestId: 'toast-message-warn',
        color: warningColor,
        iconName: 'warning',
        closeable: true
    },
    DANGER: {
        borderColor: errorColor,
        dataTestId: 'toast-message-danger',
        color: errorColor,
        iconName: 'closeCircle',
        closeable: true
    },
    SUCCESS: {
        borderColor: successColor,
        dataTestId: 'toast-message-success',
        color: successColor,
        iconName: 'checkMarkCircle',
        closeable: true
    }
};

export const defaultToastOptions: ToastOptions = {
    hideProgressBar: true,
    draggable: false,
    closeOnClick: false
};
