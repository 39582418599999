import React, { FormEvent } from 'react';
import { Field } from 'redux-form';

import { FormField } from 'components/reusable/FormField/FormField.layout';

import { PageSubHeader, Form } from 'components/reusable/Authentication/AuthenticationPanel/AuthenticationPanel.styled';
import AuthenticationPanel from 'components/reusable/Authentication/AuthenticationPanel/AuthenticationPanel';

import Styled from './ResetPasswordForm.styled';
import { ResetPasswordFormProps } from './ResetPasswordForm.types';

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props) => {
    const {
        t,
        dispatchResetPassword,
        language,
        fieldsValues,
        formErrors,
    } = props;

    const isValid = (): boolean => {
        return !!fieldsValues.emailAddress && !Object.keys(formErrors).length;
    };

    const handleSubmit = (event: FormEvent): void => {
        event.preventDefault();

        if (isValid()) {
            dispatchResetPassword({
                email: fieldsValues.emailAddress,
                languageCode: language
            });
        }
    };

    return (
        <AuthenticationPanel
            pageHeader={t('pageHeader.restoreAccess')}
        >
            <PageSubHeader>{t('restoreAccess.header')}</PageSubHeader>

            <Form
                onSubmit={handleSubmit}
                autoComplete="off"
            >
                <Field
                    name="emailAddress"
                    label={t('restoreAccess.emailField.label')}
                    iconName="envelope"
                    component={FormField}
                    type="text"
                    id="emailAddress"
                    t={t}
                />

                <Styled.CenteredButtonWithMargin
                    width="auto"
                    type="submit"
                    disabled={!isValid()}
                >
                    {t('buttons.restoreAccess.text')}
                </Styled.CenteredButtonWithMargin>
            </Form>
        </AuthenticationPanel>
    );
};
