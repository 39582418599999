import styled, { css } from 'styled-components';

import theme, { WithTheme } from 'config/theme';
import { IconFont } from 'assets/styles/icons/Icons.styled';
import Icons from 'assets/styles/icons/icons';
import { ApiKeyTileMode, ApiKeyTileProps } from './ApiKeyHolder.types';


const horizontalSpacing = 44;


const ApiKeyHolder = styled.div``;

const ApiKeyTile = styled.div`
    height: 78px;
    border: 1px solid ${({ theme }: WithTheme) => theme.colors.gray.dark};
    box-shadow: ${({ theme }: WithTheme) => theme.boxShadow.containerBlurred};
    font-size: ${({ theme }: WithTheme) => theme.fontSizes[1]}px;
    font-weight: ${({ theme }: WithTheme) => theme.fontWeights.normal};
    line-height: 19px;
    margin-bottom: 23px;
    display: flex;
`;

const ApiKeyTileContent = styled.div<ApiKeyTileProps>`
    position: relative;
    width: 100%;
    display: grid;
    align-self: center;
    justify-content: center;
    margin: 0 ${horizontalSpacing}px;
    color: ${({ mode }) => mode === ApiKeyTileMode.PLACEHOLDER ? theme.colors.gray.xdark : theme.colors.accent.light};
    ${({ mode }) => mode === ApiKeyTileMode.REVEALED && css`font-family: monospace; font-size: 12px;`}

    :before {
        content: '';
        display: ${({ mode }) => mode === ApiKeyTileMode.CONCEALED ? 'block' : 'none'};
        width: 100%;
        border-bottom: 8px dotted ${theme.colors.accent.light};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }
`;

const ApiKeyTileIcon = styled(IconFont).attrs(({ icon: Icons.eye }))<ApiKeyTileProps>`
    display: ${({ mode }) => mode === ApiKeyTileMode.CONCEALED ? 'block' : 'none'};
    margin-right: ${horizontalSpacing}px;
    align-self: center;
    font-size: 39px;
    color: ${theme.colors.gray.ash};
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        color: ${theme.colors.accent.light}
    }
`;

export default { ApiKeyHolder, ApiKeyTile, ApiKeyTileIcon, ApiKeyTileContent };
