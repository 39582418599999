import React from 'react';

import AuthorizationPage from 'components/reusable/Authentication/AuthenticationPage/AuthenticationPage';
import { ResetPasswordStep } from 'models/app/workflow';

import ResetPasswordFrom from './panels/ResetPasswordForm/ResetPasswordForm';
import ResetPasswordSuccess from './panels/ResetPasswordSuccess/ResetPasswordSuccess';

import { ResetPasswordProps } from './ResetPassword.types';


const renderResetPasswordStep = (currentStep: ResetPasswordStep): JSX.Element => {
    const conditions: Record<ResetPasswordStep, JSX.Element> = {
        RESET_FORM: <ResetPasswordFrom />,
        SUCCESS: <ResetPasswordSuccess />,
    };

    return conditions[currentStep ?? ResetPasswordStep.RESET_FORM];
};

export const ResetPassword: React.FC<ResetPasswordProps> = ({ resetPasswordStep }) => {

    return <AuthorizationPage>{renderResetPasswordStep(resetPasswordStep)}</AuthorizationPage>;

};
