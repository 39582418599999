import React, { useEffect } from 'react';

import { ApiEnvironment, apiEnvironments } from 'models/app/api-environment';
import { NarrowContentWrap } from 'components/AppLayout/AppLayout.styled';
import CommonStyled from 'components/reusable/CommonTypography.styled';
import TranslationWithMarkup from 'components/reusable/TranslationWithMarkup/TranslationWithMarkup.layout';
import LogoutButton from 'components/reusable/Buttons/LogoutButton/LogoutButton';

import ApiKeySection from './ApiKeySection/ApiKeySection';
import { ApiKeysProps } from './ApiKeys.types';
import Styled from './ApiKeys.styled';


const createApiKeySection = (environment: ApiEnvironment) =>
    <ApiKeySection key={environment} environment={environment} />;

const apiKeySections = apiEnvironments.map(createApiKeySection);


const ApiKeys: React.FC<ApiKeysProps> = props => {
    const { t, dispatchGetKeyList } = props;

    useEffect(() => apiEnvironments.forEach(dispatchGetKeyList), []);

    return (
        <NarrowContentWrap>
            <LogoutButton />

            <CommonStyled.PageHeading>{t("pageHeader")}</CommonStyled.PageHeading>
            <TranslationWithMarkup translationKey="text" t={t} parent={Styled.Introduction} />
            {apiKeySections}
        </NarrowContentWrap>
    );
};

export default ApiKeys;
