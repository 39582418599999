import { compose } from "redux";
import { reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import { createAsyncValidate, shouldAsyncValidate } from "utils/form";
import { OnboardingFormRepresentativeDetails } from "./OnboardingFormRepresentativeDetails.layout";
import { OnboardingFormRepresentativeDetailsOwnProps } from "./OnboardingFormRepresentativeDetails.types";
import { onboardingRepresentativeSchemaObject } from "./OnboardingForm.types";
import { ONBOARDING_FORM_NAME } from "../Onboarding.types";

export default compose<React.FC<OnboardingFormRepresentativeDetailsOwnProps>>(
    withTranslation('common'),
    reduxForm({
        form: ONBOARDING_FORM_NAME,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        asyncValidate: createAsyncValidate(onboardingRepresentativeSchemaObject),
        shouldAsyncValidate
    })
)(OnboardingFormRepresentativeDetails);
