import styled from "styled-components";
import { BorderColorTransition } from "assets/styles/animations/animations.styled";
import { WithTheme } from "config/theme";

export const widthForTooltips = 54;

export const FormContentWrapper = styled.div`
    width: calc(100% - ${widthForTooltips}px);
`;

export const ContentOnTheRightWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const CountrySelector = styled.select<{ showError: boolean }>`

    ${BorderColorTransition};
    
    color: ${({ theme, showError }) => showError ? theme.colors.error.base : 'initial'};
    height: 56px;
    padding-left: 18px;
    background: white;
    border: 1px solid ${({ showError, theme }: WithTheme & { showError: boolean }) => showError ? theme.colors.error.base : theme.colors.gray.base};
    width: 100%;
`;

export const CountrySelectorWrapper = styled.div`
    width: 100%;
    padding-bottom: 30px;
    position: relative;
`;

export const CountryOption = styled.option`
    color: ${({ theme }: WithTheme) => theme.colors.black};
`;

export default {
    CountrySelector,
    CountrySelectorWrapper,
    CountryOption,
};
