import React from "react";
import { Field } from "redux-form";
import { InfoRow } from "components/reusable/InfoRow/InfoRow.layout";
import { SubSectionHeader } from "components/reusable/SubSectionHeader/SubSectionHeader.layout";
import { OnboardingApplicationReviewPSD2DetailsProps } from "./OnboardingApplicationReviewPSD2Details.types";
import { getTppStatusTranslationPath } from "./OnboardingApplicationReviewPSD2Details.helpers";
import OnboardingFormPSD2DetailsApplicableRoles
    from "../OnboardingForm/OnboardingFormPSD2Details/OnboardingFormPSD2DetailsApplicableRoles.layout";
import { QwacFileUploadedField } from "./OnboardingQwacFileUploadedField.layout";
import { QwacFileUploadedFieldOwnProps } from "./OnboardingQwacFileUploadedField.types";
import { QwacFileUploadedWrapper } from "./OnboardingApplicationReviewPSD2Details.styled";

export const OnboardingApplicationReviewPSD2Details: React.FC<OnboardingApplicationReviewPSD2DetailsProps> = ({ t, tppDetails }) => (
    <>
        <SubSectionHeader
            fontSize="18px"
            copy={t('onboarding:postSubmission.sectionHeaders.psd2Details')}
        />

        <InfoRow
            withoutMargin
            value={t(getTppStatusTranslationPath(tppDetails?.application?.tppStatus))}
            copy={t('onboarding:postSubmission.fields.tppStatus.label')}
        />

        <OnboardingFormPSD2DetailsApplicableRoles
            showError={false}
            labels={[
                t('onboarding:postSubmission.fields.aisp.label'),
                t('onboarding:postSubmission.fields.pisp.label')
            ]}
            disabled
            withTooltips={false}
            withLabel={false}
            t={t}
        />

        <QwacFileUploadedWrapper>
            <Field<QwacFileUploadedFieldOwnProps>
                name=""
                component={QwacFileUploadedField}
                t={t}
                tppDetails={tppDetails}
            />
        </QwacFileUploadedWrapper>
    </>
);
