import { from, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { Epic } from 'models/meta/epic';
import { Action } from 'models/meta/action';
import { ApiEnvironment } from 'models/app/api-environment';
import { actionSuccessToast, showWarnToast } from 'store/actions/common';
import {
    ApiKeysRequest,
    DELETE_API_KEY,
    DELETE_API_KEY_SUCCESS,
    DeleteApiKey,
    DeleteApiKeySuccess,
    GET_API_KEY,
    GET_API_KEY_LIST,
    GetApiKey,
    GetApiKeyList,
    INSERT_API_KEY,
    INSERT_API_KEY_SUCCESS,
    InsertApiKey,
    InsertApiKeySuccess,
    REVEAL_API_KEY,
    RevealApiKey
} from 'store/actions/api-keys.types';
import {
    deleteApiKeyFailure,
    deleteApiKeySuccess,
    getApiKey,
    getApiKeyFailure,
    getApiKeyList,
    getApiKeyListFailure,
    getApiKeyListSuccess,
    getApiKeySuccess,
    insertApiKeyFailure,
    insertApiKeySuccess
} from 'store/actions/api-keys';


function wipMessage(name: string, request: ApiKeysRequest): Action {
    const message = `‘${name}’ operation for ${request.environment} environment is not yet supported!`;
    console.log(message); /* eslint-disable-line no-console */ // WIP message to be removed with whole function
    return showWarnToast("Work in progress", message);
}

export const onRevealApiKey: Epic = (action$, state$) =>
    action$.pipe(
        ofType(REVEAL_API_KEY),
        mergeMap(({ payload: request }: RevealApiKey) => {
            if (request.environment === ApiEnvironment.PRODUCTION) {
                return of(wipMessage("Reveal key", request));
            }

            const { environment } = request;
            const { apiKeyId } = state$.value.apiKeys.keys[environment];
            return of(getApiKey(environment, apiKeyId));
        })
    );

export const onInsertApiKey: Epic = (action$, state$, { apiKeys }) =>
    action$.pipe(
        ofType(INSERT_API_KEY),
        mergeMap(({ payload: request }: InsertApiKey) => {
            if (request.environment === ApiEnvironment.PRODUCTION) {
                return of(wipMessage("Generate new key", request));
            }

            const { tppId } = state$.value.onboarding.tppStatus!;

            return from(apiKeys.insert(tppId)).pipe(
                switchMap(() => of(insertApiKeySuccess(request))),
                catchError(() => of(insertApiKeyFailure(request)))
            );
        })
    );

export const onDeleteApiKey: Epic = (action$, state$, { apiKeys }) =>
    action$.pipe(
        ofType(DELETE_API_KEY),
        mergeMap(({ payload: request }: DeleteApiKey) => {
            if (request.environment === ApiEnvironment.PRODUCTION) {
                return of(wipMessage("Delete existing key", request));
            }

            const { tppId } = state$.value.onboarding.tppStatus!;
            const { apiKeyId } = request;

            return from(apiKeys.delete(tppId, apiKeyId)).pipe(
                switchMap(() => of(deleteApiKeySuccess(request))),
                catchError(() => of(deleteApiKeyFailure(request)))
            );
        })
    );

export const onGetApiKey: Epic = (action$, state$, { apiKeys }) =>
    action$.pipe(
        ofType(GET_API_KEY),
        mergeMap(({ payload: request }: GetApiKey) => {
            if (request.environment === ApiEnvironment.PRODUCTION) {
                return of(wipMessage("Fetch key", request));
            }

            const { tppId } = state$.value.onboarding.tppStatus!;
            const { apiKeyId } = request;

            return from(apiKeys.get(tppId, apiKeyId)).pipe(
                switchMap((apiKey) => of(getApiKeySuccess(apiKey, request))),
                catchError(() => of(getApiKeyFailure(request)))
            );
        })
    );

export const onGetApiKeyList: Epic = (action$, state$, { apiKeys }) =>
    action$.pipe(
        ofType(GET_API_KEY_LIST),
        mergeMap(({ payload: request }: GetApiKeyList) => {
            if (request.environment === ApiEnvironment.PRODUCTION) {
                return of(wipMessage("Fetch key list", request));
            }

            const { tppId } = state$.value.onboarding.tppStatus!;

            return from(apiKeys.getList(tppId)).pipe(
                switchMap(response => of(getApiKeyListSuccess(response, request))),
                catchError(() => of(getApiKeyListFailure(request)))
            );
        })
    );

export const onApiKeyOperationSuccess: Epic = action$ =>
    action$.pipe(
        ofType(INSERT_API_KEY_SUCCESS, DELETE_API_KEY_SUCCESS),
        mergeMap(({ type, meta }: InsertApiKeySuccess | DeleteApiKeySuccess) => of(
            getApiKeyList(meta.environment),
            actionSuccessToast('common', type === INSERT_API_KEY_SUCCESS ? 'apiKeyGenerated' : 'apiKeyDeleted')
        ))
    );


export default [
    onRevealApiKey,
    onInsertApiKey,
    onDeleteApiKey,
    onGetApiKey,
    onGetApiKeyList,
    onApiKeyOperationSuccess,
];
