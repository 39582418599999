export enum ApiEnvironment {
    SANDBOX = 'sandbox',
    PRODUCTION = 'production'
}

export const apiEnvironments: ApiEnvironment[] = [ApiEnvironment.SANDBOX];

export interface EnvironmentDetails {
    url: string;
    headerKey: string;
}

export const apiDocumentationUrl = `api-docs/okeo-open-api-specification.json`;

export const apiEnvironmentDetails: Record<ApiEnvironment, EnvironmentDetails> = {
    [ApiEnvironment.SANDBOX]: { url: "https://okeo.com/sandbox/xs2a/api/v1/", headerKey: "sandboxEnvironment" },
    [ApiEnvironment.PRODUCTION]: { url: "https://okeo.com/prod/xs2a/api/v1/", headerKey: "productionEnvironment" },
};
