import React, { FormEvent } from 'react';
import { Field } from 'redux-form';

import { FormField } from 'components/reusable/FormField/FormField.layout';

import Icons from 'assets/styles/icons/icons';
import {
    PageSubHeader,
    Form,
    InfoIcon,
    // SecondaryActionWrap,
    TooltipWrapper,
    RowWithTooltipWrapper,
} from "components/reusable/Authentication/AuthenticationPanel/AuthenticationPanel.styled";
import AuthenticationPanel from 'components/reusable/Authentication/AuthenticationPanel/AuthenticationPanel';
// import TranslationWithMarkup from 'components/reusable/TranslationWithMarkup/TranslationWithMarkup.layout';

import { IconFont } from 'assets/styles/icons/Icons.styled';
import Tooltip from 'components/reusable/Tooltip/Tooltip.layout';
import { OkeoButton } from 'components/atoms/OkeoButton/OkeoButton.styled';
import { globalColors } from 'components/AppLayout/config';
import { RegisterFormProps } from './RegisterForm.types';

export const RegisterForm: React.FC<RegisterFormProps> = (props) => {
    const {
        t,
        dispatchRegister,
        fieldsValues,
        formErrors,
    } = props;

    const isValid = (): boolean => {
        return !!fieldsValues.emailAddress && !!fieldsValues.password && !Object.keys(formErrors).length;
    };

    const handleSubmit = (event: FormEvent): void => {
        event.preventDefault();

        if (isValid()) {
            dispatchRegister(fieldsValues.emailAddress, fieldsValues.password);
        }
    };

    const tooltipParts = (): string => {
        const object = t(
            `registerForm.passwordField.tooltip`,
            { returnObjects: true }
        ) as string[];

        return Array.isArray(object) ? object.join('<br/>') : '';
    };

    return (
        <AuthenticationPanel pageHeader={t('pageHeader.register')}>
            <PageSubHeader>{t('registerForm.header')}</PageSubHeader>

            <InfoIcon
                icon={Icons.infoCircle}
                color={globalColors.backgrounds.green.light}
                data-tip={t('registerForm.infoIcon.tooltip')}
            />

            <Form
                onSubmit={handleSubmit}
                autoComplete="off"
            >
                <Field
                    name="emailAddress"
                    label={t('registerForm.emailField.label')}
                    iconName="envelope"
                    component={FormField}
                    type="text"
                    id="emailAddress"
                    t={t}
                />
                <RowWithTooltipWrapper>
                    <Field
                        name="password"
                        type="password"
                        label={t('registerForm.passwordField.label')}
                        id="password"
                        iconName="key"
                        component={FormField}
                        t={t}
                    />
                    <TooltipWrapper>
                        <IconFont
                            icon={Icons.infoCircle}
                            color={globalColors.backgrounds.green.light}
                            data-for="application-review"
                            data-tip={tooltipParts()}
                        />

                        <Tooltip id="application-review" multiline />
                    </TooltipWrapper>
                </RowWithTooltipWrapper>

                <OkeoButton
                    width="auto"
                    type="submit"
                    disabled={!isValid()}
                >
                    {t('buttons.register.text')}
                </OkeoButton>
            </Form>

            {/* TODO to be restored */}
            {/*<SecondaryActionWrap>*/}
            {/*    <TranslationWithMarkup translationKey={'common:registerForm.forgotPasswordText'} />*/}
            {/*</SecondaryActionWrap>*/}

        </AuthenticationPanel>
    );
};
