export enum LoginStep {
    AUTHORISATION = 'AUTHORISATION',
    SUCCESS = 'SUCCESS',
}

export enum RegisterStep {
    REGISTRATION = 'REGISTRATION',
    SUCCESS = 'SUCCESS',
}

export enum ResetPasswordStep {
    RESET_FORM = 'RESET_FORM',
    SUCCESS = 'SUCCESS',
}

export enum OnboardingStep {
    INTRODUCTION = 'INTRODUCTION',
    COMPANY_DETAILS = 'COMPANY_DETAILS',
    REPRESENTATIVE_DETAILS = 'REPRESENTATIVE_DETAILS',
    PSD2_DETAILS = 'PSD2_DETAILS',
    APPLICATION_SUBMITTED = 'APPLICATION_SUBMITTED'
}
