import { createReducer, Handlers } from './reducer';
import { SET_TOAST_ID, SetToastId, ToastState } from "./toastsReducer.types";

export const initialState: ToastState = {};

const handlers: Handlers<ToastState, SetToastId> = {

    [SET_TOAST_ID]: ({ toastId }) => {
        return { [toastId]: toastId };
    },
};

export default createReducer(initialState, handlers);
