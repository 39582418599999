import { ApiKey, ApiKeyId, GetApiKeyListResponse } from 'models/domain/api-keys';
import { ApiEnvironment } from 'models/app/api-environment';

import { ClearStore } from './common.types';


type REVEAL_API_KEY = 'REVEAL_API_KEY';
type CONCEAL_API_KEY = 'CONCEAL_API_KEY';

type INSERT_API_KEY = 'INSERT_API_KEY';
type INSERT_API_KEY_SUCCESS = 'INSERT_API_KEY_SUCCESS';
type INSERT_API_KEY_FAILURE = 'INSERT_API_KEY_FAILURE';

type DELETE_API_KEY = 'DELETE_API_KEY';
type DELETE_API_KEY_SUCCESS = 'DELETE_API_KEY_SUCCESS';
type DELETE_API_KEY_FAILURE = 'DELETE_API_KEY_FAILURE';

type GET_API_KEY_LIST = 'GET_API_KEY_LIST';
type GET_API_KEY_LIST_SUCCESS = 'GET_API_KEY_LIST_SUCCESS';
type GET_API_KEY_LIST_FAILURE = 'GET_API_KEY_LIST_FAILURE';

type GET_API_KEY = 'GET_API_KEY';
type GET_API_KEY_SUCCESS = 'GET_API_KEY_SUCCESS';
type GET_API_KEY_FAILURE = 'GET_API_KEY_FAILURE';

export const REVEAL_API_KEY: REVEAL_API_KEY = 'REVEAL_API_KEY';
export const CONCEAL_API_KEY: CONCEAL_API_KEY = 'CONCEAL_API_KEY';

export const INSERT_API_KEY: INSERT_API_KEY = 'INSERT_API_KEY';
export const INSERT_API_KEY_SUCCESS: INSERT_API_KEY_SUCCESS = 'INSERT_API_KEY_SUCCESS';
export const INSERT_API_KEY_FAILURE: INSERT_API_KEY_FAILURE = 'INSERT_API_KEY_FAILURE';

export const DELETE_API_KEY: DELETE_API_KEY = 'DELETE_API_KEY';
export const DELETE_API_KEY_SUCCESS: DELETE_API_KEY_SUCCESS = 'DELETE_API_KEY_SUCCESS';
export const DELETE_API_KEY_FAILURE: DELETE_API_KEY_FAILURE = 'DELETE_API_KEY_FAILURE';

export const GET_API_KEY_LIST: GET_API_KEY_LIST = 'GET_API_KEY_LIST';
export const GET_API_KEY_LIST_SUCCESS: GET_API_KEY_LIST_SUCCESS = 'GET_API_KEY_LIST_SUCCESS';
export const GET_API_KEY_LIST_FAILURE: GET_API_KEY_LIST_FAILURE = 'GET_API_KEY_LIST_FAILURE';

export const GET_API_KEY: GET_API_KEY = 'GET_API_KEY';
export const GET_API_KEY_SUCCESS: GET_API_KEY_SUCCESS = 'GET_API_KEY_SUCCESS';
export const GET_API_KEY_FAILURE: GET_API_KEY_FAILURE = 'GET_API_KEY_FAILURE';


export interface ApiKeysRequest {
    environment: ApiEnvironment;
}

export interface ApiKeyRequest extends ApiKeysRequest {
    apiKeyId: ApiKeyId;
}

export interface RevealApiKey {
    type: REVEAL_API_KEY;
    payload: ApiKeyRequest;
}

export interface ConcealApiKey {
    type: CONCEAL_API_KEY;
    payload: ApiKeyRequest;
}

export interface InsertApiKey {
    type: INSERT_API_KEY;
    payload: ApiKeysRequest;
}

export interface InsertApiKeySuccess {
    type: INSERT_API_KEY_SUCCESS;
    meta: ApiKeysRequest;
}

export interface InsertApiKeyFailure {
    type: INSERT_API_KEY_FAILURE;
    meta: ApiKeysRequest;
}

export interface DeleteApiKey {
    type: DELETE_API_KEY;
    payload: ApiKeyRequest;
}

export interface DeleteApiKeySuccess {
    type: DELETE_API_KEY_SUCCESS;
    meta: ApiKeyRequest;
}

export interface DeleteApiKeyFailure {
    type: DELETE_API_KEY_FAILURE;
    meta: ApiKeyRequest;
}

export interface GetApiKey {
    type: GET_API_KEY;
    payload: ApiKeyRequest;
}

export interface GetApiKeySuccess {
    type: GET_API_KEY_SUCCESS;
    payload: ApiKey;
    meta: ApiKeyRequest;
}

export interface GetApiKeyFailure {
    type: GET_API_KEY_FAILURE;
    meta: ApiKeyRequest;
}

export interface GetApiKeyList {
    type: GET_API_KEY_LIST;
    payload: ApiKeysRequest;
}

export interface GetApiKeyListSuccess {
    type: GET_API_KEY_LIST_SUCCESS;
    payload: GetApiKeyListResponse;
    meta: ApiKeysRequest;
}

export interface GetApiKeyListFailure {
    type: GET_API_KEY_LIST_FAILURE;
    meta: ApiKeysRequest;
}

type ApiKeyDisplayActions = RevealApiKey | ConcealApiKey;
type InsertApiKeyActions = InsertApiKey | InsertApiKeySuccess | InsertApiKeyFailure;
type DeleteApiKeyActions = DeleteApiKey | DeleteApiKeySuccess | DeleteApiKeyFailure;
type GetApiKeyActions = GetApiKey | GetApiKeySuccess | GetApiKeyFailure;
type GetApiKeyListActions = GetApiKeyList | GetApiKeyListSuccess | GetApiKeyListFailure;

type ApiKeyActions = ApiKeyDisplayActions | InsertApiKeyActions | DeleteApiKeyActions | GetApiKeyActions | GetApiKeyListActions;

export type ApiKeysActions = ApiKeyActions | ClearStore;
