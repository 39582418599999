import { DictionaryState } from 'models/app/store';

import { createReducer, Handlers } from './reducer';
import { DictionaryActions } from '../actions/dictionary.types';

export const initialState: DictionaryState = { countries: [] };

const handlers: Handlers<DictionaryState, DictionaryActions> = {
    GET_COUNTRIES_INFO_SUCCESS: ({ countries }, dictionary): DictionaryState => {
        return { ...dictionary, countries };
    },
};

export default createReducer(initialState, handlers);

