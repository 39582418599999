import React from 'react';

import CircleButton from '../CircleButton/CircleButton.layout';
import { LogoutButtonProps } from './LogoutButton.types';


const LogoutButton: React.FC<LogoutButtonProps> = ({
    t,
    shouldShow,
    dispatchLogout
}) => {

    return (
        shouldShow
            ? (
                <CircleButton
                    onClick={dispatchLogout}
                    iconName="reject"
                    iconTooltip={t('buttons.exitCircleButton.closedPartTitle')}
                />
            )
            : <></>
    );
};


export default LogoutButton;
