import { AsyncValidateCallback } from "redux-form";
import { Schema } from "yup";
import yup from 'utils/yup';
import { asyncValidate as yupValidate } from "redux-form-yup";

export const shouldAsyncValidate = ({ trigger }: Pick<AsyncValidateCallback<{}, {}>, 'trigger'>) => {
    return trigger === 'submit' || trigger === 'blur';
};

export const createAsyncValidate = <T>(schema: Schema<T>) => (values: yup.InferType<typeof schema>) => {

    return new Promise((resolve, reject) => {
        return yupValidate(schema)(values).then((errors) => {
            if (errors) {
                reject(errors);
            } else {
                resolve();
            }
        });
    });
};
