import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Dispatch } from 'models/meta/action';
import State from 'models/app/store';
import { getApiKeyList } from 'store/actions/api-keys';
import createWithLoading from 'hocs/loading/createWithLoading';
import { WithLoadingProps } from 'hocs/loading/withLoading.types';
import LoadingOverlaySection from 'components/reusable/Overalys/SectionLoadingOverlay.layout';

import { ApiKeyDispatchProps } from './ApiKeys.types';
import ApiKeys from './ApiKeys.layout';


const mapDispatchToProps = (dispatch: Dispatch): ApiKeyDispatchProps =>
    ({ dispatchGetKeyList: (environment) => { dispatch(getApiKeyList(environment)); } });


const loadingSelector = (state: State) => state.apiKeys.isLoading;

const loadingProps: WithLoadingProps = {
    loadingSelector,
    OverlayComponent: LoadingOverlaySection,
    positionLock: true
};

export default createWithLoading(connect(null, mapDispatchToProps)(withTranslation("api-keys")(ApiKeys) as React.FC), loadingProps);
