import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { connect } from "react-redux";
import State from "models/app/store";
import Environments from "./Environments.layout";
import { EnvironmentsOwnProps, EnvironmentsStateProps } from "./Environments.types";

function mapStateToProps(state: State): EnvironmentsStateProps {
    return { tppId: state.onboarding.tppStatus?.tppId };
}

export default compose<React.FC<EnvironmentsOwnProps>>(
    connect(mapStateToProps),
    withTranslation('environments')
)(Environments);
