import State from "models/app/store";
import createWithLoading from "hocs/loading/createWithLoading";
import LoadingOverlaySection from "components/reusable/Overalys/SectionLoadingOverlay.layout";
import { CertificatesFileUploaderWrapper } from "./Certificates.styled";


const loadingSelector = (state: State) => {
    return state.certificates.isSelfSignedCertificateBeingGenerated;
};

export default createWithLoading<{}>(CertificatesFileUploaderWrapper, {
    loadingSelector,
    OverlayComponent: LoadingOverlaySection,
    positionLock: true
});
