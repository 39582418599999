import React from 'react';
import { IconFont } from "assets/styles/icons/Icons.styled";
import Icons from "assets/styles/icons/icons";
import TextButton from "components/reusable/Buttons/TextButton";
import { globalColors } from 'components/AppLayout/config';
import Styled from './FileUploader.styled';
import { FileUploaderProps } from "./FileUploader.types";
import {
    allowForInstantReselectionOfTheSameFile,
    handleDrag,
    handleDragIn,
    handleDragOut,
    handleFileDropUpload,
    handleUpload,
    uploadedFilesMapper
} from "./FileUploader.helpers";

export const FileUploader = (props: FileUploaderProps) => {

    const {
        files,
        maxFiles,
        t,
        dispatchRegisterField,
        isBeingDraggedOver,
        onChangesToAttachedFiles,
        dataTestId
    } = props;

    const uploadAllowed = files?.length < maxFiles;
    const uploadInputRef: React.RefObject<HTMLInputElement> = React.useRef({} as HTMLInputElement);

    React.useEffect(
        () => {
            onChangesToAttachedFiles?.(files);
        },
        [files]
    );

    React.useEffect(() => {

        if (props.fieldLocationInFormState) {
            dispatchRegisterField(props.fieldLocationInFormState);
        }

        return () => {
            props.onFileUploaderUnmounted?.();
            props.removeFilesOnUnmount && props.dispatchSetFileUploaderFiles([]);
            onChangesToAttachedFiles?.([]);
        };
    }, []);

    return (
        <>
            <Styled.FileUploaderWrapper
                showError={!!props.showError}
                title={t('attachmentsArea.title')}
                onDragEnter={handleDragIn(props)}
                onDragLeave={handleDragOut(props)}
                onDragOver={handleDrag}
                onDrop={(e: React.DragEvent<HTMLDivElement>) => handleFileDropUpload(props)(e)}
                data-test-id={`${dataTestId}-file-uploader`}
            >
                <Styled.UploadContentWrapper blur={!uploadAllowed && isBeingDraggedOver}>

                    <Styled.UploaderContent>
                        <IconFont icon={Icons.dropFile} fontSize={22} color="accent.base" />

                        <Styled.AttachmentsArea>{t('attachmentsArea.text')}</Styled.AttachmentsArea>

                        <Styled.FileUploadInput
                            type="file"
                            ref={uploadInputRef}
                            onChange={event => {
                                handleUpload(props)(event.target.files);
                            }}
                            onClick={allowForInstantReselectionOfTheSameFile}
                        />

                        <TextButton
                            type="button"
                            color={globalColors.fontColors.green.light}
                            fontWeight="bold"
                            onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                            data-test-id={`${dataTestId}-file-uploader-browse-button`}
                        >
                            {t('buttons.browse.text')}
                        </TextButton>
                    </Styled.UploaderContent>

                </Styled.UploadContentWrapper>

                {isBeingDraggedOver && <Styled.OverlayOnDragOver allowed={uploadAllowed} />}

            </Styled.FileUploaderWrapper>

            <Styled.FilesList aria-label="Uploaded files">
                {uploadedFilesMapper(props)}
            </Styled.FilesList>
        </>
    );

};
