import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withTranslation } from 'react-i18next';
import { Dispatch } from 'models/meta/action';
import { logout } from 'store/actions/user';
import { navigate } from 'store/actions/common';
import { AuthenticationPageDispatchProps } from './AuthenticationPage.types';
import AuthenticationPanel from './AuthenticationPage.layout';


export const mapDispatchToProps = (dispatch: Dispatch): AuthenticationPageDispatchProps => ({
    dispatchLogout: () => dispatch(logout()),
    dispatchNavigate: (location, options) => dispatch(navigate(location, options))
});

export default compose<React.FC>(
    withTranslation('common'),
    connect(null, mapDispatchToProps)
)(AuthenticationPanel);

