/* eslint-disable consistent-return */
import styled, { css } from "styled-components";
import { Button } from "components/reusable/Buttons/Button";
import { OperationResultClass } from "components/AppLayout/config.types";
import { globalColors, standardBorder, standardTransition } from "components/AppLayout/config";
import { ActionVariant, OkeoButtonAction, OkeoButtonProps } from "./OkeoButton.types";

const type = 'linear-gradient';
const deg = '169deg';
const opc1 = 0.73;
const opc2 = 0.9;

export const okeoGradient = `${type}(
    ${deg},
    ${globalColors.backgrounds.green.light} 0%,
    rgba(73, 165, 30, ${opc1}) 100%)`;

const okeoInfoButtonGradient = `${type}(
    ${deg},
    ${globalColors.backgrounds.blue.base} 0%,
    rgba(3, 132, 158, ${opc1}) 50%,
    rgba(2, 102, 122, ${opc2}) 100%)`;


const okeoWarnButtonGradient = `${type}(
    ${deg},
    ${globalColors.backgrounds.yellow.light} 0%,
    ${globalColors.backgrounds.yellow.base} 50%,
    rgb(235, 141, 0) 100%)
`;

const okeoDangerButtonGradient = `${type}(
    ${deg},
    ${globalColors.backgrounds.orange.light} 0%,
    ${globalColors.backgrounds.orange.base} 50%,
    rgb(213, 98, 105) 100%)
`;

const standardButtonStyles = css`
    box-shadow: none;
    padding: 0 32px;
    height: 48px;

    ${standardTransition};
`;

const defaultFontButtonStyles = css`
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 600;
`;

const sharedButtonStyles = css`
    color: ${globalColors.fontColors.white};
    border: none;
    background-color: transparent;
    ${defaultFontButtonStyles};

`;

export const okeoButtonBasicStyles = css`
    background-size: 200% auto;
    ${sharedButtonStyles};

    &:hover, &:active, &:focus {
        background-color: transparent;
    }
`;

export const primaryButtonStyles = css`
    background: linear-gradient(169deg, ${globalColors.backgrounds.green.light} 0%, rgba(73,165,30,0.73) 100%);

    ${sharedButtonStyles};

    &:hover, &:active, &:focus {
        background: linear-gradient(169deg, ${globalColors.backgrounds.green.dark} 0%, ${globalColors.backgrounds.green.dark} 100%);
    }
`;

const secondaryButtonStyles = css`
    ${standardBorder};
    background: ${globalColors.backgrounds.white};
    color: ${globalColors.backgrounds.green.dark};
    ${defaultFontButtonStyles};

    &:hover, &:active  {
        color: ${globalColors.fontColors.white};
        border-color: ${globalColors.backgrounds.green.dark};
        background-color: ${globalColors.backgrounds.green.dark};
    }
`;

const secondaryInfoButtonStyles = css`
    ${standardBorder};
    background: ${globalColors.backgrounds.white};
    color: ${globalColors.backgrounds.blue.dark};
    ${defaultFontButtonStyles};

    &:hover, &:active, &:focus {
        color: ${globalColors.fontColors.white};
        border-color: ${globalColors.backgrounds.blue.dark};
        background-color: ${globalColors.backgrounds.blue.dark};
    }
`;

const secondaryWarnButtonStyles = css`
    ${standardBorder};
    background: ${globalColors.backgrounds.white};
    color: ${globalColors.backgrounds.yellow.dark};
    ${defaultFontButtonStyles};

    &:hover, &:active, &:focus {
        color: ${globalColors.fontColors.white};
        border-color: ${globalColors.backgrounds.yellow.dark};
        background-color: ${globalColors.backgrounds.yellow.dark};
    }
`;

const secondaryErrorButtonStyles = css`
    ${standardBorder};
    background: ${globalColors.backgrounds.white};
    color: ${globalColors.fontColors.orange};
    ${defaultFontButtonStyles};

    &:hover, &:active, &:focus {
        color: ${globalColors.fontColors.white};
        border-color: ${globalColors.backgrounds.orange.base};
        background-color: ${globalColors.backgrounds.orange.base};
    }
`;

const infoButtonStyles = css`
    ${okeoButtonBasicStyles};
    background: ${okeoInfoButtonGradient};

    &:hover, &:active, &:focus {
        color: ${globalColors.fontColors.white};
        background: linear-gradient(169deg, ${globalColors.backgrounds.blue.dark} 0%, ${globalColors.backgrounds.blue.dark} 100%);
    }
`;

const warnButtonStyles = css`
    ${okeoButtonBasicStyles};
    background: ${okeoWarnButtonGradient};

    &:hover, &:active, &:focus {
        color: ${globalColors.fontColors.white};
        background: linear-gradient(169deg, ${globalColors.backgrounds.yellow.dark} 0%, ${globalColors.backgrounds.yellow.dark} 100%);
    }
`;

const dangerButtonStyles = css`
    ${okeoButtonBasicStyles};
    background: ${okeoDangerButtonGradient};

    &:hover, &:active, &:focus {
        color: ${globalColors.fontColors.white};
        background: linear-gradient(169deg, ${globalColors.backgrounds.orange.dark} 0%, ${globalColors.backgrounds.orange.dark} 100%);
    }
`;

const disabledStyles = css`
    color: ${globalColors.fontColors.white};
    background: ${globalColors.backgrounds.grey.dark};
    pointer-events: none;
    cursor: default;
`;

// @ts-ignore
const createVariantButtonStyles = (variant?: ButtonVariant, actionVariant?: OkeoButtonAction | OperationResultClass) => {
    if (variant) {
        switch (variant) {
            case 'primary':
                return primaryButtonStyles;

            case 'secondary':
                if (actionVariant === 'INFO') {
                    return secondaryInfoButtonStyles;
                }
                if (actionVariant === 'WARN') {
                    return secondaryWarnButtonStyles;
                }
                if (actionVariant === 'ERROR' || actionVariant === 'DANGER') {
                    return secondaryErrorButtonStyles;
                }
                return secondaryButtonStyles;

            case 'ternary':
                return warnButtonStyles;

            default:
                return primaryButtonStyles;
        }
    }
};

// @ts-ignore
const createActionButtonStyles = (actionVariant?: OkeoButtonAction | OperationResultClass) => {
    if (actionVariant) {
        switch (actionVariant) {
            case ActionVariant.SUCCESS:
            case OperationResultClass.SUCCESS:
                return primaryButtonStyles;

            case ActionVariant.INFO:
            case OperationResultClass.INFO:
                return infoButtonStyles;

            case ActionVariant.WARN:
            case OperationResultClass.WARN:
                return warnButtonStyles;

            case ActionVariant.DANGER:
            case OperationResultClass.DANGER:
            case ActionVariant.ERROR:
            case OperationResultClass.ERROR:
                return dangerButtonStyles;

            default:
                return primaryButtonStyles;
        }
    }
};


export const OkeoButton = styled(Button)<OkeoButtonProps>`
    ${standardButtonStyles};

    ${({ variant, actionVariant }) => variant && createVariantButtonStyles(variant, actionVariant)};

    ${({ actionVariant, variant }) => (actionVariant && variant !== 'secondary') && createActionButtonStyles(actionVariant)};

    ${({ variant, actionVariant }) => (!variant && !actionVariant) && primaryButtonStyles}

    ${({ disabled }) => disabled && disabledStyles};
`;

