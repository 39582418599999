import { loginUrl, registerUrl, restPasswordUrl } from 'models/meta/api';
import { LoginRequest, RegisterRequest, ResetPasswordRequest, User } from 'models/domain/user';

import { HttpService } from '../HttpService';

const tppManagement = {
    register: (credentials: LoginRequest): Promise<User> => {
        return HttpService.post(registerUrl, credentials, { noErrorHandling: true });
    },
    login: (credentials: RegisterRequest): Promise<User> => {
        return HttpService.post(loginUrl, credentials, { noErrorHandling: true });
    },
    sendPasswordResetEmail: (payload: ResetPasswordRequest): Promise<void> => {
        return HttpService.post(restPasswordUrl, null, { params: { ...payload }, noErrorHandling: true });
    },
};

export { tppManagement };
