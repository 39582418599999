import React from "react";
import { OnboardingStep } from "models/app/workflow";
import TranslationWithMarkup from "components/reusable/TranslationWithMarkup/TranslationWithMarkup.layout";
import { Divider } from "components/reusable/CommonTypography.styled";
import { OkeoButton } from "components/atoms/OkeoButton/OkeoButton.styled";
import { OnboardingIntroductionProps } from "./OnboardingIntroduction.types";
import { CenteredContent } from "./OnboardingIntroduction.styled";

export const OnboardingIntroduction: React.FC<OnboardingIntroductionProps> = (
    {
        dispatchResetOnboardingForm,
        dispatchGetTppStatus,
        dispatchSetOnboardingStep,
        isLoading,
        dispatchResetFileUploaderFiles,
        t
    }
) => {

    React.useEffect(() => {

        dispatchResetOnboardingForm();
        dispatchResetFileUploaderFiles();
        dispatchGetTppStatus();

        return () => {
            dispatchResetOnboardingForm();
            dispatchResetFileUploaderFiles();
        };
    }, []);

    return (
        <>
            {!isLoading && (

                <>
                    <TranslationWithMarkup translationKey="onboarding:preSubmission.noSubmissionStep.text" />

                    <Divider />

                    <CenteredContent>
                        <OkeoButton
                            type="button"
                            onClick={() => {
                                dispatchSetOnboardingStep(OnboardingStep.COMPANY_DETAILS);
                            }}
                        >
                            {t('onboarding:postSubmission.buttons.start.text')}
                        </OkeoButton>
                    </CenteredContent>

                </>

            )}

        </>
    );
};
