import { compose, Dispatch } from "redux";
import { connect } from "react-redux";
import { Action } from "models/meta/action";
import { withTranslation } from "react-i18next";
import State from "models/app/store";
import createWithLoading from "hocs/loading/createWithLoading";
import LoadingOverlaySection from "components/reusable/Overalys/SectionLoadingOverlay.layout";
import { resetOnboardingState } from "store/actions/onboarding";
import { OnboardingApplicationSummaryOwnProps } from "./OnboardingApplicationSummary.types";
import { OnboardingApplicationSummary } from "./OnboardingApplicationSummary.layout";

export const isTppApplicationBeingSubmittedSelector = (state: State) => {
    return state.onboarding.isTppApplicationBeingSubmitted;
};

const mapStateToProps = (state: State) => {
    return { isTppApplicationBeingSubmitted: isTppApplicationBeingSubmittedSelector(state) };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        dispatchResetOnboardingState: () => {
            dispatch(resetOnboardingState());
        }
    };
};

const ConnectedOnboardingApplicationSummary = compose<React.FC<OnboardingApplicationSummaryOwnProps>>(
    withTranslation('onboarding'),
    connect(mapStateToProps, mapDispatchToProps)
)(OnboardingApplicationSummary);

export default createWithLoading<OnboardingApplicationSummaryOwnProps>(ConnectedOnboardingApplicationSummary, {
    loadingSelector: isTppApplicationBeingSubmittedSelector,
    OverlayComponent: LoadingOverlaySection,
    positionLock: true
});
