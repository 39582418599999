import { WithTranslation } from 'react-i18next';
import yup from "utils/yup";
import { FormErrors } from 'redux-form';
import { NavigateOptions } from 'store/actions/common.types';

export const LOGIN_FORM = 'login';

const commonPath = 'common:validationMsg';
const maxLength = 255;

export const loginSchema = {
    emailAddress: yup
        .string()
        .email(`${commonPath}.emailInvalidFormat`)
        .max(maxLength)
        .required(`${commonPath}.fieldMandatory`),

    password: yup
        .string()
        .required(`${commonPath}.fieldMandatory`)
};

export const schema = yup.object(loginSchema);

export interface LoginFormStateProps {
    fieldsValues: Record<string, string>,
    formErrors: FormErrors,
    loginStep: string,
}

export interface LoginFormDispatchProps {
    dispatchLogin: (emailAddress: string, password: string) => void;
    dispatchNavigate: (location: string, options?: NavigateOptions) => void;
}

export type LoginFormProps = LoginFormStateProps & LoginFormDispatchProps & WithTranslation;
