import styled from 'styled-components';

import { BorderColorTransition } from 'assets/styles/animations/animations.styled';
import { space, SpaceProps } from "styled-system";
import { errorColor, globalColors, successColor } from 'components/AppLayout/config';
import { ErrorProp } from '../FormField.types';

const Input = styled.input<ErrorProp & SpaceProps>`
    width: 100%;
    padding: 18px 16px 16px 23px;
    border: 1px solid;
    min-height: 16px;
    line-height: 100%;
    color: ${globalColors.fontColors.black};
    border-color: ${props => (props.hasError ? errorColor : globalColors.backgrounds.grey.xdark)};
    ${BorderColorTransition};
    ${space};
        
    &:focus,
    &:hover {
      border-color: ${props => (props.hasError ? errorColor : successColor)};
    }
    
    ::placeholder {
        color: ${globalColors.fontColors.grey.dark};
    }
`;

export default { Input };
