import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { Dropdown } from 'components/reusable/Dropdown/Dropdown.layout';
import { SelectKeyDown, SelectOnDropdownCloseRequest, SelectRenderer } from 'react-dropdown-select';
import Styled from './CountriesDropdown.styled';
import { CountriesDropdownOwnProps, CountriesDropdownProps } from './CountriesDropdown.types';
import {
    createCountriesOptions,
    createCustomContentRenderer,
    createCustomItemRenderer
} from './CountriesDropdown.helpers';


export const CountriesDropdown: React.FC<CountriesDropdownProps> = props => {
    const { countriesList, t, input, hasError } = props;

    const dropdownRef = React.useRef({} as SelectRenderer<any>);
    const contentRenderer = createCustomContentRenderer(t, input);
    const itemRenderer = createCustomItemRenderer(t);

    const onDropdownWrapFocus = (e: React.FocusEvent) => {
        const dropdown = dropdownRef.current;
        dropdown.methods.dropDown('open');

        if (dropdown.state.values.length === 0) {
            document.getElementById(`${input.name}-search-input`)?.focus();
        }

        input.onFocus(e);
    };

    const onDropdownCloseRequest = ({ close, state, methods }: SelectOnDropdownCloseRequest<{ value: string }>) => {
        const suggestions = methods.searchResults();
        const currentItem = suggestions[state.cursor];
        const currentValue = state.values[0];

        if (currentValue) {
            input.onBlur(currentValue.value);
        } else if (currentItem) {
            input.onBlur(currentItem.value);
        } else if (state.search.length > 2 && suggestions.length) {
            input.onBlur(suggestions[0].value);
        } else {
            input.onBlur('');
        }
        close();
        return [];
    };


    const handleKeyDownFn = ({ event }: SelectKeyDown<{value: string}>) => {
        const tab = event.key === 'Tab' && !event.shiftKey;

        if (tab) {
            event.stopPropagation();
            event.preventDefault();
        }
    };

    return (
        <Styled.CountriesDropdownWrap
            hasError={hasError}
            onFocus={onDropdownWrapFocus}
        >
            <Dropdown
                refProp={dropdownRef}
                searchable
                options={createCountriesOptions(countriesList, t)}
                input={input}
                contentRenderer={contentRenderer}
                itemRenderer={itemRenderer}
                dropdownHeight="160px"
                handleKeyDownFn={handleKeyDownFn}
                onDropdownCloseRequest={onDropdownCloseRequest}
            />
        </Styled.CountriesDropdownWrap>
    );
};

export default CountriesDropdown;

export const withCountriesDropdown = (props: CountriesDropdownOwnProps) => (wrappedFieldProps: WrappedFieldProps, hasError?: boolean) => {
    return (
        <CountriesDropdown
            hasError={hasError}
            {...props}
            {...wrappedFieldProps}
        />

    );
};
