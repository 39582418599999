import React from 'react';
import { NarrowContentWrap, NarrowContentWrapMinusTooltips } from 'components/AppLayout/AppLayout.styled';
import { OnboardingStep } from "models/app/workflow";
import Styled from "components/reusable/CommonTypography.styled";
import { TppApplication } from "models/domain/tpp";
import LogoutButton from "components/reusable/Buttons/LogoutButton/LogoutButton";
import OnboardingForm from "./OnboardingForm/OnboardingForm";
import OnboardingApplicationSummary from "./OnboardingApplicationSummary";
import { OnboardingIntroduction } from "./OnboardingIntroduction.layout";
import { OnboardingProps } from "./Onboarding.types";
import OnboardingApplicationReview from "./OnboardingApplicationReview/OnboardingApplicationReview";

const Onboarding: React.FC<OnboardingProps> = (
    {
        dispatchSubmitTppApplication,
        dispatchResetOnboardingState,
        isLoading,
        dispatchResetOnboardingForm,
        dispatchGetTppStatus,
        onboardingStep,
        dispatchResetFileUploaderFiles,
        dispatchSetOnboardingStep,
        t
    }
) => {

    React.useEffect(() => {
        return () => {
            dispatchResetOnboardingState();
        };
    }, []);

    return (
        <NarrowContentWrap>

            <LogoutButton />

            {
                (!isLoading && onboardingStep !== OnboardingStep.APPLICATION_SUBMITTED)
                            && <Styled.PageHeading>{t('onboarding:preSubmission.pageHeader')}</Styled.PageHeading>
            }

            {
                !onboardingStep && (
                    <OnboardingApplicationReview />
                )
            }

            {
                onboardingStep === OnboardingStep.INTRODUCTION && (
                    <OnboardingIntroduction
                        isLoading={isLoading}
                        dispatchResetFileUploaderFiles={dispatchResetFileUploaderFiles}
                        t={t}
                        dispatchResetOnboardingForm={dispatchResetOnboardingForm}
                        dispatchGetTppStatus={dispatchGetTppStatus}
                        dispatchSetOnboardingStep={dispatchSetOnboardingStep}
                    />
                )
            }

            <NarrowContentWrapMinusTooltips>
                <OnboardingForm
                    onboardingStep={onboardingStep}
                    dispatchSetOnboardingStep={dispatchSetOnboardingStep}
                    onSubmit={(tppApplication: TppApplication) => {
                        dispatchSubmitTppApplication(tppApplication);
                    }}
                />
            </NarrowContentWrapMinusTooltips>

            {
                onboardingStep === OnboardingStep.APPLICATION_SUBMITTED && (
                    <OnboardingApplicationSummary
                        dispatchSetOnboardingStep={dispatchSetOnboardingStep}
                    />
                )
            }

        </NarrowContentWrap>
    );
};

export default Onboarding;
