import styled, { css, keyframes } from 'styled-components';
import { errorColor } from 'components/AppLayout/config';

const FadeInKeyframe = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const FadeIn = css`
    opacity: 0;
    animation: ${FadeInKeyframe} 0.5s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 0.2s;
`;

export const ErrorMessageWrapper = styled.div`
    position: absolute;
    height: 17px;
    ${FadeIn};
`;

export const ErrorMessageText = styled.span`
    line-height: 14px;
    font-size: 10px;
    color: ${errorColor};
`;

export default { ErrorMessageWrapper, ErrorMessageText };
