import React from 'react';
import { NarrowContentWrap } from 'components/AppLayout/AppLayout.styled';
import FileUploaderWithTooltip from "components/reusable/FileUploader/FileUploaderWithTooltip/FileUploaderWithTooltip";
import { CSR_FILE_UPLOADER_NAME } from "store/actions/fileUploader.types";
import { noop } from "utils/function-tools";
import TranslationWithMarkup from "components/reusable/TranslationWithMarkup/TranslationWithMarkup.layout";
import { Divider, GrayParagraph, PageHeading } from "components/reusable/CommonTypography.styled";
import LogoutButton from "components/reusable/Buttons/LogoutButton/LogoutButton";
import { OkeoButton } from 'components/atoms/OkeoButton/OkeoButton.styled';
import { CertificatesProps, CSR_MIME_TYPES } from "./Certificates.types";
import CertificatesWrapper from "./CertificatesWrapper";


const Certificates: React.FC<CertificatesProps> = (
    {
        dispatchGenerateSelfSignedCertificate,
        isCsrFileAttached,
        isCsrFileBeingAsyncValidated,
        dispatchDismissAllButUnauthToasts,
        dispatchUploadCsr,
        t
    }
) => {

    return (
        <NarrowContentWrap>

            <LogoutButton />

            <PageHeading mb={5}>
                {t('pageHeader')}
            </PageHeading>

            <TranslationWithMarkup
                components={{ p: <GrayParagraph /> }}
                translationKey="text"
                t={t}
            />

            <Divider />

            <CertificatesWrapper>

                <FileUploaderWithTooltip
                    onFileUploaderUnmounted={dispatchDismissAllButUnauthToasts}
                    acceptedFileTypes={CSR_MIME_TYPES}
                    header={t('test-certificates:sectionHeader')}
                    tooltip={t('test-certificates:fileTooltip')}
                    removeFilesOnUnmount
                    onChangesToAttachedFiles={noop}
                    id={CSR_FILE_UPLOADER_NAME}
                    dispatchAsynchronousValidation={dispatchUploadCsr}
                />

                <OkeoButton
                    mt={4}
                    disabled={isCsrFileBeingAsyncValidated || !isCsrFileAttached}
                    data-test-id="generate-certificate-button"
                    onClick={dispatchGenerateSelfSignedCertificate}
                    type="button"
                >
                    {t('test-certificates:buttons.generateCertificate.text')}
                </OkeoButton>

            </CertificatesWrapper>

        </NarrowContentWrap>
    );
};

export default Certificates;
