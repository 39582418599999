import { TppId } from "models/domain/tpp";
import { UploaderFile } from "components/reusable/FileUploader/FileUploader.types";
import { certificates } from "./api/certificates";

class CertificatesService {

    static uploadCSR(files: UploaderFile[], tppId: TppId) {

        return files[0].text().then((fileContent: string) => {

            return certificates.uploadCSR(fileContent, tppId);
        });
    }

    static generateSelfSignedCertificate(tppId: TppId) {

        return certificates.generateSelfSignedCertificate(tppId);
    }
}

export default CertificatesService;
