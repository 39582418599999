import styled, { css } from 'styled-components';
import theme from 'config/theme';
import { IconFont } from 'assets/styles/icons/Icons.styled';
import { BorderColorTransition } from 'assets/styles/animations/animations.styled';
import { globalColors } from 'components/AppLayout/config';

const getErrorBorderColor = () => ({ borderColor: globalColors.backgrounds.orange.dark });

const getBorderColor = () => ({ borderColor: globalColors.backgrounds.grey.xdark });

const getFocusBorderColor = () => ({ borderColor: globalColors.backgrounds.green.light });

const CountriesDropdownWrap = styled.div<{hasError?: boolean}>`

    .react-dropdown-select {
        border: 1px solid;
        height: 58px;
        line-height: 100%;
        color: ${({ theme }) => theme.colors.black};
        ${props => (props.hasError ? getErrorBorderColor() : getBorderColor)};
        ${BorderColorTransition};

        &:focus,
        &:focus-within,
        &:hover {
          ${props => (props.hasError ? getErrorBorderColor() : getFocusBorderColor())};
        }
    }

    .react-dropdown-select-content.react-dropdown-select-content {
      padding: 0;
      display: flex;
      justify-content: normal;
    }
    
    
    .react-dropdown-select-dropdown.react-dropdown-select-dropdown {
      top: 58px;
      left: -1px;
      right: -1px;
      border-top: 1px transparent;
    }
    
    .react-dropdown-select-item.react-dropdown-select-item  {
      justify-content: normal;
      padding: 0 18px 0 0;
    }
   
`;

const ClearIcon = styled(IconFont)`
    margin-left: auto;
    font-size: 14px;
    cursor: pointer;
    
    &:hover {
      color: ${theme.colors.accent.light};
    }
`;

const Input = styled.input<{show?: boolean}>`
    padding: 18px 16px 16px 23px;
    border: none;
    width: 100%;
    line-height: 100%;
    opacity: 0;
    position: absolute;
    z-index: -1;
    pointer-events: none;
    
    
    ${({ show }) => show && css`
      opacity: 1;
      z-index: 1;
      pointer-events: initial;
      background-color: transparent;
    `}
    
`;

export default { CountriesDropdownWrap, ClearIcon, Input };
