import { OnboardingStep } from "models/app/workflow";
import { TppApplication, TppStatus } from "models/domain/tpp";
import { WithTranslation } from "react-i18next";
import { MimeType } from "models/app/common";
import { id } from "utils/function-tools";

export const ONBOARDING_FORM_NAME = 'onboarding';

export type DispatchSetOnboardingStep = (onboardingStep: OnboardingStep) => void
export type DispatchGetTppStatus = (isAuthenticated: boolean) => void
export type DispatchResetFileUploaderFiles = () => void
export type DispatchResetOnboardingState = () => void
export type DispatchSubmitTppApplication = (tppApplication: TppApplication) => void

export interface OnboardingStateProps {
    isAuthenticated: boolean,
    isLoading: boolean,
    onboardingStep: OnboardingStep | null,
    tppStatus: TppStatus | null,
}

export type OnboardingMergeProps = WithTranslation & OnboardingStateProps & OnboardingDispatchProps & {
    dispatchGetTppStatus: () => void
}

export type DispatchResetOnboardingForm = () => void;

export interface OnboardingDispatchProps {
    dispatchResetFileUploaderFiles: DispatchResetFileUploaderFiles,
    dispatchResetOnboardingForm: DispatchResetOnboardingForm,
    dispatchSubmitTppApplication: DispatchSubmitTppApplication,
    dispatchResetOnboardingState: DispatchResetOnboardingState,
    dispatchSetOnboardingStep: DispatchSetOnboardingStep,
    dispatchGetTppStatus: DispatchGetTppStatus,
}

export interface OnboardingProps extends OnboardingStateProps, OnboardingDispatchProps, WithTranslation {
    dispatchGetTppStatus: () => void
}

const extensions = ['pem', 'cert', 'crt', 'der'];

const certificateMimeTypes = [
    'application/x-x509-ca-cert',
    'application/pkix-cert',
    'application/x-pem-file',
    'text/plain'
];

export const CERT_MIME_TYPES: MimeType[] = extensions.map((extension) => {
    return certificateMimeTypes.map((mime) => {
        return {
            mime,
            extension
        };
    });
}).flatMap(id);
