import React from 'react';
import { loginRoutePath } from 'config/config';
import Icons from 'assets/styles/icons/icons';
import { globalColors } from 'components/AppLayout/config';

import { DecorationIcon, SuccessSubHeader, CenteredButton } from 'components/reusable/Authentication/AuthenticationPanel/AuthenticationPanel.styled';

import AuthenticationPanel from 'components/reusable/Authentication/AuthenticationPanel/AuthenticationPanel';
import { RegisterSuccessProps } from './RegisterSuccess.types';


export const RegisterSuccess: React.FC<RegisterSuccessProps> = ({ t, dispatchNavigate }) => {

    return (
        <AuthenticationPanel pageHeader={t('pageHeader.register')}>
            <SuccessSubHeader>{t('registerSuccessWindow.header')}</SuccessSubHeader>

            <DecorationIcon
                icon={Icons.checkMarkCircle}
                color={globalColors.fontColors.green.light}
            />

            <CenteredButton
                width="auto"
                type="button"
                onClick={() => dispatchNavigate(loginRoutePath)}
            >
                {t('buttons.login.text')}
            </CenteredButton>


        </AuthenticationPanel>
    );
};
