import { createGlobalStyle } from "styled-components";

import { commonLayout, appLayout } from './layout.styled';
import reset from './reset.styled';
import dropdown from './dropdown.styled';
import transitions from '../animations/transitions.styled';

export const GlobalStyles = createGlobalStyle`
    ${commonLayout}
    ${dropdown}
    ${reset}
    ${transitions}
`;

export const AppStyles = createGlobalStyle`
    ${appLayout}
`;
