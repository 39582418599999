import { State } from 'models/app/store';
import { connect } from 'react-redux';
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { reduxForm } from "redux-form";
import { createAsyncValidate, shouldAsyncValidate } from "utils/form";
import { OnboardingFormCompanyDetails } from "./OnboardingFormCompanyDetails.layout";
import {
    OnboardingFormCompanyDetailsOwnProps,
    OnboardingFormCompanyDetailsStateProps,
} from './OnboardingFormCompanyDetails.types';
import { onboardingCompanyDetailsSchemaObject } from "./OnboardingForm.types";
import { ONBOARDING_FORM_NAME } from "../Onboarding.types";

const mapStateToProps = (state: State): OnboardingFormCompanyDetailsStateProps =>
    ({ countriesList: state.dictionary.countries });

export default compose<React.FC<OnboardingFormCompanyDetailsOwnProps>>(
    connect(mapStateToProps),
    withTranslation('common'),
    reduxForm({
        form: ONBOARDING_FORM_NAME,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        asyncValidate: createAsyncValidate(onboardingCompanyDetailsSchemaObject),
        shouldAsyncValidate
    })
)(OnboardingFormCompanyDetails);
