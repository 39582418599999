import { IModalData } from "store/reducers/modalReducer.types";
import { IModalOrchestrator } from "components/reusable/Modals/ModalOrchestrator.types";

export const modalActionTypes = {
    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL'
};

export const openModal = <T extends IModalData>(payload: IModalOrchestrator<T>) => ({
    type: modalActionTypes.OPEN_MODAL,
    payload
});

export const closeModal = () => ({ type: modalActionTypes.CLOSE_MODAL });
