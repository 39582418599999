import React from 'react';
import { TFunction } from 'i18next';
import { SelectItemRenderer } from "react-dropdown-select";
import { WrappedFieldInputProps } from 'redux-form/lib/Field';

import { DropdownOption } from 'models/app/common';
import Icons from 'assets/styles/icons/icons';
import { CountriesInfo } from 'models/domain/dictionary';
import CountryFlag from './CountryFlag/CountryFlag.layout';
import Styled from './CountriesDropdown.styled';


export const getFlagCode = (name: string): string => {
    return name.substr(0, 2);
};

type ItemProps = { t: TFunction, renderer: SelectItemRenderer<DropdownOption> };

const ItemComponent: React.FunctionComponent<ItemProps> = props => {
    const { item, methods, props: { disabled }, itemIndex, state } = props.renderer;

    const itemRef = React.useRef({} as HTMLSpanElement);
    const isSelected = methods?.isSelected(item);
    const isActive = state.cursor === itemIndex;
    const scrollIntoView = () => itemRef?.current?.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });

    React.useLayoutEffect(() => {
        if (state.cursor !== 0 && isActive) {
            scrollIntoView();
        }
    }, [state.cursor, itemIndex]);

    const classNames = "react-dropdown-select-item"
        + `${isSelected ? ` react-dropdown-select-item-selected` : ''}`
        + `${isActive ? ` react-dropdown-select-item-active ` : ''}`;

    const onClick = disabled ? undefined : () => methods?.addItem(item);

    return (
        <span
            ref={itemRef}
            role="option"
            aria-disabled={disabled}
            key={`${item?.key}`}
            tabIndex={-1}
            aria-label={item?.text}
            aria-selected={isSelected}
            onKeyDown={onClick}
            onClick={onClick}
            className={classNames}
        >
            <CountryFlag name={getFlagCode(item?.key)} />
            <span>{item?.text}</span>
        </span>
    );
};

export const createCustomItemRenderer = (t: TFunction) => (renderer: SelectItemRenderer<DropdownOption>) => {
    return <ItemComponent t={t} renderer={renderer} />;
};

export const createCustomContentRenderer = (t: TFunction, input: WrappedFieldInputProps) => (select: SelectItemRenderer<DropdownOption>) => {
    const { methods, state } = select;
    const firstValue = state.values[0];

    const uploadInputRef: React.RefObject<HTMLInputElement> = React.useRef({} as HTMLInputElement);

    const handleClear = () => {
        input.onChange('');
        uploadInputRef.current?.focus();
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        const tab = e.key === 'Tab' && !e.shiftKey;

        if (e.key === 'Enter' || tab) {
            e.stopPropagation();
            e.preventDefault();
            const suggestions = methods.searchResults();
            const currentItem = suggestions[state.cursor];

            if (currentItem) {
                methods.addItem(currentItem);
            } else if (state.search.length && suggestions.length && e.key === 'Enter') {
                methods.addItem(suggestions[0]);
            }
            uploadInputRef.current?.blur();
            methods.dropDown('close');
        }

        if (e.key === 'Escape') {
            uploadInputRef.current?.blur();
        }
    };

    return (
        <>
            <Styled.Input
                type="text"
                autoComplete="new-password"
                value={state.search}
                onChange={e => methods.setSearch(e)}
                onKeyDown={onKeyDown}
                ref={uploadInputRef}
                show={!firstValue}
                id={`${input.name}-search-input`}
                data-test-id={`${input.name}-search-input`}
            />

            {firstValue && (
                <>
                    <CountryFlag name={getFlagCode(firstValue?.key)} />
                    <span>{firstValue?.text}</span>
                    <Styled.ClearIcon icon={Icons.reject} onClick={handleClear} />
                </>
            ) }
        </>
    );
};


export const createCountriesOptions = (countriesList: CountriesInfo, t: TFunction) => {
    return countriesList.map(item => ({
        key: `${item.alpha2Code}${item.subdivisionAlpha5Code || ''}`,
        value: item.alpha3Code,
        text: `${t(`dictionaries:countryCodes.${item.alpha2Code}`)}${item.subdivisionAlpha5Code ? ` - ${t(`dictionaries:countryCodes.${item.subdivisionAlpha5Code}`)}` : ''}`,
    }));
};
