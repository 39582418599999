import { TppId } from 'models/domain/tpp';
import { ApiKey, ApiKeyId, GetApiKeyListResponse } from 'models/domain/api-keys';

import { apiKeys } from './api/apiKeys';


class ApiKeysService {
    static insert(tppId: TppId): Promise<void> {
        return apiKeys.insertApiKey(tppId);
    }

    static delete(tppId: TppId, apiKeyId: ApiKeyId): Promise<void> {
        return apiKeys.deleteApiKey(tppId, apiKeyId);
    }

    static get(tppId: TppId, apiKeyId: ApiKeyId): Promise<Required<ApiKey>> {
        return apiKeys.getApiKey(tppId, apiKeyId);
    }

    static getList(tppId: TppId): Promise<GetApiKeyListResponse> {
        return apiKeys.getApiKeyList(tppId);
    }

}

export default ApiKeysService;
