import React from 'react';
import theme from 'config/theme';
import Styled from './Tooltip.styled';

export default function Tooltip({ ...rest }): JSX.Element {
    return (
        <Styled.StandardTooltip
            {...rest}
            border
            effect="solid"
            backgroundColor={theme.colors.gray.light}
            textColor={theme.colors.font.onPrimaryDarker}
            borderColor={theme.colors.gray.dark}
        />
    );
}
