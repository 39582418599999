import styled from 'styled-components';
import { footerHeight, navBarDecorationHeight, sideBarWidth } from 'assets/styles/global/layout.styled';
import { TransitionGroup } from 'react-transition-group';

const PageWrap = styled.div`
   height: 100vh;
   display: grid;
   grid-template-rows: ${navBarDecorationHeight}px 1fr ${footerHeight}px;
`;

const ContentWrap = styled.div`
  overflow-y: auto;
  display: grid;
  grid-template-columns: ${sideBarWidth}px 1fr;
  grid-column-gap: 0px;
`;

const MainContentWrap = styled.main`
  padding: 72px 70px 0;
  width: 100%;
`;

const RouteTransitionGroup = styled(TransitionGroup)`
  position: relative;
  width: 100%;
  max-width: 100%;
`;

export const NarrowContentWrap = styled.main`
  max-width: 567px;
`;

export const NarrowContentWrapMinusTooltips = styled.main`
  max-width: 500px;
  padding-bottom: 50px;
`;

export default { PageWrap, NarrowContentWrapMinusTooltips, ContentWrap, MainContentWrap, NarrowContentWrap, RouteTransitionGroup };
