import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { State } from 'models/app/store';

import { MainStateProps } from './Main.types';
import Main from './Main.layout';
import { AccessStatus } from "../models/domain/tpp";

const mapStateToProps = (state: State): MainStateProps => {
    return {
        shouldShowPostApprovalItems: state.onboarding.tppStatus?.accessStatus === AccessStatus.GRANTED,
        isAuthenticated: state.user.isAuthenticated
    };
};

export default connect(mapStateToProps)(withRouter(Main));
