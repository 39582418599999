import React, { FormEvent } from 'react';
import { Field } from 'redux-form';

import { resetPasswordRoutePath } from 'config/config';
import { FormField } from 'components/reusable/FormField/FormField.layout';
import TextButton from 'components/reusable/Buttons/TextButton.styled';

import Icons from 'assets/styles/icons/icons';
import {
    PageSubHeader,
    Form,
    InfoIcon,
    SecondaryActionWrap,
    SecondaryActionLabel,
} from 'components/reusable/Authentication/AuthenticationPanel/AuthenticationPanel.styled';

import AuthenticationPanel from 'components/reusable/Authentication/AuthenticationPanel/AuthenticationPanel';
import { OkeoButton } from 'components/atoms/OkeoButton/OkeoButton.styled';
import { successColor } from 'components/AppLayout/config';
import { LoginFormProps } from './LoginForm.types';

export const LoginForm: React.FC<LoginFormProps> = (props) => {
    const {
        t,
        dispatchLogin,
        dispatchNavigate,
        fieldsValues,
        formErrors,
    } = props;

    const isValid = (): boolean => {
        return !!fieldsValues.emailAddress && !!fieldsValues.password && !Object.keys(formErrors).length;
    };

    const handleSubmit = (event: FormEvent): void => {
        event.preventDefault();

        if (isValid()) {
            dispatchLogin(fieldsValues.emailAddress, fieldsValues.password);
        }
    };

    return (
        <AuthenticationPanel pageHeader={t('pageHeader.loginToApp')}>
            <PageSubHeader>{t('loginForm.header')}</PageSubHeader>

            <InfoIcon
                icon={Icons.infoCircle}
                color={successColor}
                data-tip={t('loginForm.infoIcon.tooltip')}
            />

            <Form
                onSubmit={handleSubmit}
                autoComplete="off"
            >
                <Field
                    name="emailAddress"
                    label={t('loginForm.emailField.label')}
                    iconName="envelope"
                    component={FormField}
                    type="text"
                    id="emailAddress"
                    t={t}
                />

                <Field
                    name="password"
                    type="password"
                    label={t('loginForm.passwordField.label')}
                    id="password"
                    iconName="key"
                    component={FormField}
                    t={t}
                />

                <OkeoButton
                    width="auto"
                    type="submit"
                    disabled={!isValid()}
                >
                    {t('buttons.login.text')}
                </OkeoButton>
            </Form>

            <SecondaryActionWrap>
                <SecondaryActionLabel>{t('loginForm.forgotPasswordText')}</SecondaryActionLabel>
                <TextButton onClick={() => dispatchNavigate(resetPasswordRoutePath)}>{t('buttons.restoreAccess.text')}</TextButton>
            </SecondaryActionWrap>

        </AuthenticationPanel>
    );
};
