import { compose, Dispatch } from "redux";
import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { dismissAllToasts, showErrorToast } from "store/actions/common";
import { DropDepth } from "store/reducers/fileUploaderReducer.types";
import {
    setFileUploaderBeingDraggedOverState,
    setFileUploaderDropDepth,
    setFileUploaderErrors,
    setFileUploaderFiles,
    startFileUploaderAsyncValidation,
    stopFileUploaderAsyncValidation
} from "store/actions/fileUploader";
import { Action } from "models/meta/action";
import State from "models/app/store";
import { openModal } from "store/actions/modal";
import { ONBOARDING_FORM_NAME } from "components/pages/Onboarding/Onboarding.types";
import createWithLoading from "hocs/loading/createWithLoading";
import { LoadingSelector } from "hocs/loading/withLoading.types";
import { registerField } from "redux-form";
import {
    FileUploaderBehavioralProps,
    FileUploaderDispatchProps,
    FileUploaderOwnProps,
    FileUploaderProps,
    FileUploaderStateProps
} from "./FileUploader.types";
import { FileUploader } from './FileUploader.layout';
import { createDispatchChangeForm, showSwapModal } from "./FileUploader.helpers";
import { IModalOrchestrator } from "../Modals/ModalOrchestrator.types";
import { BiActionModal } from "../Modals/BiActionModal/BiActionModal.types";
import { FileUploaderLoadingOverlay } from "./FileUploaderLoadingOverlay.layout";

export const mapDispatchToProps = (
    dispatch: Dispatch<Action>,
    {
        id,
        onFileUploaderUnmounted,
        dispatchAsynchronousValidation,
        fieldLocationInFormState,
        onChangesToAttachedFiles,
        onSingleFileLimitReached
    }: FileUploaderOwnProps
): FileUploaderDispatchProps => {

    return ({
        openModal: (payload: IModalOrchestrator<BiActionModal>) => {
            dispatch(openModal(payload));
        },
        dispatchSetFileUploaderBeingDraggedOverState: (isBeingDraggedOver: boolean) => {
            dispatch(setFileUploaderBeingDraggedOverState(id, isBeingDraggedOver));
        },
        dispatchSetFileUploaderDropDept: (dropDepth: DropDepth) => {
            dispatch(setFileUploaderDropDepth(id, dropDepth));
        },
        onFileUploaderUnmounted: () => {

            if (onFileUploaderUnmounted) {
                onFileUploaderUnmounted();
            } else {
                dispatch(dismissAllToasts());
            }
        },
        dispatchStartFileUploaderAsyncValidation: () => {
            dispatch(startFileUploaderAsyncValidation(id));
        },
        onChangesToAttachedFiles: (files: File[]) => {

            if (dispatchAsynchronousValidation) {
                dispatch(stopFileUploaderAsyncValidation(id));
            }

            if (onChangesToAttachedFiles) {

                onChangesToAttachedFiles(files);

            } else if (fieldLocationInFormState) {

                createDispatchChangeForm(dispatch)(ONBOARDING_FORM_NAME, fieldLocationInFormState, files);
            }
        },
        dispatchRegisterField: (fieldLocationInFormState) => {
            dispatch(registerField(ONBOARDING_FORM_NAME, fieldLocationInFormState, "Field"));
        },
        dispatchSetFileUploaderFiles: (files: File[]) => {
            dispatch(setFileUploaderFiles(id, files));
        },
        onSingleFileLimitReached: (props: FileUploaderProps) => {

            return (uploaded) => {

                if (onSingleFileLimitReached) {
                    onSingleFileLimitReached(props)(uploaded);
                } else {
                    showSwapModal(props)(uploaded);
                }
            };
        },
        dispatchErrors: (errors: string[], shouldDismissPreviousErrorToasts = false) => {

            dispatch(setFileUploaderErrors(id, errors));

            if (errors.length && errors[0]) {
                const [title, ...description] = errors[0].split('.');

                if (shouldDismissPreviousErrorToasts) {
                    dispatch(dismissAllToasts());
                }

                dispatch(
                    showErrorToast(
                        title,
                        description.join(' '),
                        {
                            pauseOnHover: true,
                            autoClose: 1000 * 60
                        },
                        { shouldTranslate: false }
                    )
                );
            }
        }
    });
};

const loadingSelector: LoadingSelector<FileUploaderOwnProps> = (state: State, ownProps: FileUploaderOwnProps) => {

    return state.fileUploader[ownProps.id].isBeingAsyncValidated;
};

const WrappedFileUploader = compose<React.FC<Partial<FileUploaderBehavioralProps>>>(
    connect(
        (state: State, ownProps: FileUploaderOwnProps): FileUploaderStateProps => {

            const { fileUploader } = state;
            const { id } = ownProps;

            const particularFileUploader = fileUploader[id];

            return {
                isBeingAsyncValidated: !!loadingSelector(state, ownProps),
                dropDepth: particularFileUploader.dropDepth,
                isBeingDraggedOver: particularFileUploader.isBeingDraggedOver,
                errors: particularFileUploader.errors,
                files: particularFileUploader.files
            };
        },
        mapDispatchToProps
    ),
    withTranslation('common')
)(FileUploader);

export default createWithLoading<Partial<FileUploaderBehavioralProps>>(WrappedFileUploader, {
    loadingSelector,
    OverlayComponent: FileUploaderLoadingOverlay,
    positionLock: true
});
