import React from 'react';
import { SpaceProps } from "styled-system";
import Styled from './TextInput.styled';
import { TextInputProps } from './TextInput.types';

export const TextInput = ({
    name,
    showError,
    type,
    tabIndex,
    value,
    placeholder,
    onBlur,
    onChange,
    onFocus,
    dataTestId,
    ...spaceProps
}: TextInputProps) => {

    return (
        <Styled.Input
            data-test-id={dataTestId}
            hasError={showError}
            type={type}
            placeholder={placeholder}
            tabIndex={tabIndex}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            autoComplete="new-password"
            autoCorrect="off"
            spellCheck="false"
            {...spaceProps as SpaceProps}
        />
    );
};
