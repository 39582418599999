import React, { FunctionComponent } from 'react';

import Styled from 'components/reusable/Overalys/Overlays.styled';
import SpinnerPaletteRing from 'components/reusable/Spinners/SpinnerPaletteRing';
import { SectionLoadingOverlayProps } from "./SectionLoadingOverlay.types";

const SectionLoadingOverlay: FunctionComponent<SectionLoadingOverlayProps> = ({
    height = 200,
    width = 200,
    minHeight = '200px',
    wrapperHeight
}) => {

    return (
        <Styled.SectionLoadingPlaceholder
            height={wrapperHeight}
            minHeight={minHeight}
        >
            <Styled.CenteredSpinnerPaletteRing>

                <SpinnerPaletteRing
                    height={height}
                    width={width}
                />

            </Styled.CenteredSpinnerPaletteRing>
        </Styled.SectionLoadingPlaceholder>
    );
};

export default SectionLoadingOverlay;
