import { css } from 'styled-components';
import theme from 'config/theme';

export const minContentWidth = '1024px';
export const navBarDecorationHeight = 5;
export const docNavBarHeight = 88;
export const footerHeight = 60;
export const sideBarWidth = 300;
export const fontFamily = `'Prompt', 'Open Sans', Arial, Helvetica, sans-serif`;

export const commonLayout = css`
    body {
        margin: 0;
        font-family: ${fontFamily};
    }
`;

export const appLayout = css`
    html {
        scroll-behavior: smooth;
    }
    
    body {
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        position: relative;
        background: ${theme.colors.white};
        min-width: ${minContentWidth};
    }
    
    .appOuterWrapper {
        width: 100%;
        position: relative;
    }
`;

export default { commonLayout, appLayout };
