import styled from 'styled-components';
import { okeoGradient } from 'components/AppLayout/config';

const NavigationBarDecoration = styled.div`
    z-index: 100;
    background-image: ${okeoGradient};
`;

const NavigationBarWrapper = styled.div`
    display: flex;
`;

export default {
    NavigationBarWrapper,
    NavigationBarDecoration,
};
