import { compose, Dispatch } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Action } from "models/meta/action";
import { UploaderFile } from "components/reusable/FileUploader/FileUploader.types";
import { CSR_FILE_UPLOADER_NAME } from "store/actions/fileUploader.types";
import State from "models/app/store";
import { generateSelfSignedCertificate, uploadCsr } from "store/actions/certificates";
import { dismissAllButUnathToasts } from "store/actions/common";
import { CertificatesDispatchProps, CertificatesOwnProps, CertificatesStateProps } from "./Certificates.types";
import Certificates from "./Certificates.layout";

function mapStateToProps(state: State): CertificatesStateProps {
    return {
        isCsrFileBeingAsyncValidated: state.fileUploader[CSR_FILE_UPLOADER_NAME].isBeingAsyncValidated,
        isCsrFileAttached: !!state.fileUploader[CSR_FILE_UPLOADER_NAME]?.files.length
    };
}

function mapDispatchToProps(dispatch: Dispatch<Action>): CertificatesDispatchProps {

    return {
        dispatchDismissAllButUnauthToasts: () => {
            dispatch(dismissAllButUnathToasts());
        },
        dispatchGenerateSelfSignedCertificate: () => {
            dispatch(generateSelfSignedCertificate());
        },
        dispatchUploadCsr: (files: UploaderFile[]) => {
            dispatch(uploadCsr(files));
        }
    };
}

export default compose<React.FC<CertificatesOwnProps>>(
    withTranslation('test-certificates'),
    connect(mapStateToProps, mapDispatchToProps)
)(Certificates);
