import React from "react";
import { InfoRow } from "components/reusable/InfoRow/InfoRow.layout";
import { SubSectionHeader } from "components/reusable/SubSectionHeader/SubSectionHeader.layout";
import { OnboardingApplicationReviewStatusProps } from "./OnboardingApplicationReviewStatus.types";
import {
    getApplicationStatusColorAndTranslationPath,
    getApplicationStatusTranslationPath
} from "./OnboardingApplicationReviewStatus.helpers";

export const OnboardingApplicationReviewStatus:
    React.FC<OnboardingApplicationReviewStatusProps> = ({ t, tppDetails, language }) => {

        const applicationSubmissionDate = tppDetails?.application?.applicationSubmissionDate;
        const applicationApprovalDate = tppDetails?.application?.applicationApprovalDate;

        const applicationStatus = tppDetails?.application?.applicationStatus;

        const noValue = t('onboarding:postSubmission.fields.applicationApprovalDate.noValue');

        return (
            <>

                <SubSectionHeader
                    fontSize="18px"
                    copy={t(getApplicationStatusTranslationPath(applicationStatus))}
                />

                <InfoRow
                    valueColor={getApplicationStatusColorAndTranslationPath(applicationStatus)().color}
                    value={t(getApplicationStatusColorAndTranslationPath(applicationStatus)().copy)}
                    copy={t('onboarding:postSubmission.fields.applicationStatus.label')}
                />

                <InfoRow
                    value={(applicationSubmissionDate ? new Date(applicationSubmissionDate).toLocaleDateString(language) : noValue)}
                    copy={t('onboarding:postSubmission.fields.applicationSubmissionDate.label')}
                />

                <InfoRow
                    value={applicationApprovalDate ? new Date(applicationApprovalDate).toLocaleDateString(language) : noValue}
                    copy={t('postSubmission.fields.applicationApprovalDate.label')}
                />

            </>
        );
    };
