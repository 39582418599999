import React from "react";
import { InfoRow } from "components/reusable/InfoRow/InfoRow.layout";
import { SubSectionHeader } from "components/reusable/SubSectionHeader/SubSectionHeader.layout";
import { OnboardingApplicationReviewRepresentativeDetailsProps } from "./OnboardingApplicationReviewRepresentativeDetails.types";

export const OnboardingApplicationReviewRepresentativeDetails:
    React.FC<OnboardingApplicationReviewRepresentativeDetailsProps> = ({ t, tppDetails }) => (
        <>
            <SubSectionHeader
                fontSize="18px"
                copy={t('onboarding:postSubmission.sectionHeaders.representativeDetails')}
            />

            <InfoRow
                value={tppDetails?.application?.representativeFirstName}
                copy={t('postSubmission.fields.representativeFirstName.label')}
            />

            <InfoRow
                value={tppDetails?.application?.representativeLastName}
                copy={t('postSubmission.fields.representativeLastName.label')}
            />

            <InfoRow
                value={tppDetails?.application?.representativePosition}
                copy={t('postSubmission.fields.representativePosition.label')}
            />

        </>
    );
