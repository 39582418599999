import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import State from "models/app/store";
import { Action } from "models/meta/action";
import { closeModal } from "store/actions/modal";
import BiActionModalLayout from './BiActionModal/BiActionModal.layout';
import { BlurredBackground } from './ModalOrchestrator.styled';
import { IProps } from './ModalOrchestrator.types';

const renderProperModal = (props: IProps, type: string) => {

    const { data, closeModal } = props;

    return (
        (type === BiActionModalLayout.type)
            ? <BiActionModalLayout {...data} close={closeModal} />
            : null
    );
};

export function ModalOrchestratorLayout(props: IProps) {
    const { type, open } = props;

    return (
        <>
            {open && <BlurredBackground />}
            {renderProperModal(props, type)}
        </>
    );
}

const mapStateToProps = ({ modal }: State) => ({
    data: modal.data,
    type: modal.type,
    open: modal.open
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({ closeModal: () => dispatch(closeModal()) });

export default connect(mapStateToProps, mapDispatchToProps)(ModalOrchestratorLayout);
