export const GENERATE_SELF_SIGNED_CERTIFICATE = 'GENERATE_SELF_SIGNED_CERTIFICATE';
export const GENERATE_SELF_SIGNED_CERTIFICATE_SUCCESS = 'GENERATE_SELF_SIGNED_CERTIFICATE_SUCCESS';
export const GENERATE_SELF_SIGNED_CERTIFICATE_FAILURE = 'GENERATE_SELF_SIGNED_CERTIFICATE_FAILURE';
export const UPLOAD_CSR = 'UPLOAD_CSR';

export interface GenerateSelfSignedCertificate {
    type: typeof GENERATE_SELF_SIGNED_CERTIFICATE
}

export interface GenerateSelfSignedCertificateSuccess {
    type: typeof GENERATE_SELF_SIGNED_CERTIFICATE_SUCCESS
}

export interface GenerateSelfSignedCertificateFailure {
    type: typeof GENERATE_SELF_SIGNED_CERTIFICATE_FAILURE
}
