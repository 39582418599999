import React from "react";
import { withCheckbox } from "components/reusable/FormField/Checkbox/Checkbox.layout";
import { QwacFileUploadedFieldProps } from "./OnboardingQwacFileUploadedField.types";

export const QwacFileUploadedField: React.FC<QwacFileUploadedFieldProps> = ({
    t,
    input,
    meta,
    tppDetails
}) => {

    const Checkbox = withCheckbox({
        disabled: true,
        label: t('postSubmission.fields.eidasQwacUploaded.label'),
        tooltip: ''
    });

    return (
        <Checkbox
            input={{ ...input, value: tppDetails?.tppDetails?.eidasQwacUploaded }}
            meta={meta}
        />
    );
};
