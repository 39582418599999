import { OnboardingState } from 'models/app/store';

import { OnboardingStep } from 'models/app/workflow';
import { GetTppDetailsResponse, TppId, TppStatus } from "models/domain/tpp";
import { createReducer, Handlers } from './reducer';
import {
    GET_TPP_DETAILS,
    GET_TPP_DETAILS_SUCCESS,
    GET_TPP_STATUS,
    GET_TPP_STATUS_FAILURE,
    GET_TPP_STATUS_SUCCESS,
    OnboardingActions,
    RESET_ONBOARDING_STATE,
    SET_ONBOARDING_STEP,
    SUBMIT_TPP_APPLICATION,
    SUBMIT_TPP_APPLICATION_FAILURE,
    SUBMIT_TPP_APPLICATION_SUCCESS
} from "../actions/onboarding.types";
import { CLEAR_STORE, ClearStore } from "../actions/common.types";

export const initialState: OnboardingState = {
    onboardingStep: OnboardingStep.INTRODUCTION,
    tppStatus: null,
    isTppStatusBeingLoaded: false,
    areTppDetailsBeingLoaded: false,
    isTppApplicationBeingSubmitted: false,
    tppDetails: null
};

const handlers: Handlers<OnboardingState, OnboardingActions | ClearStore> = {

    [SET_ONBOARDING_STEP]: (onboardingStep: OnboardingStep) => {
        return { onboardingStep };
    },

    [GET_TPP_STATUS]: () => {
        return { isTppStatusBeingLoaded: true };
    },

    [SUBMIT_TPP_APPLICATION]: () => {
        return { isTppApplicationBeingSubmitted: true };
    },

    [GET_TPP_DETAILS]: () => {
        return { areTppDetailsBeingLoaded: true };
    },

    [GET_TPP_DETAILS_SUCCESS]: (tppDetails: GetTppDetailsResponse) => {
        return { tppDetails, areTppDetailsBeingLoaded: false };
    },

    [SUBMIT_TPP_APPLICATION_SUCCESS]: (tppId: TppId) => {
        return { isTppApplicationBeingSubmitted: false, tppStatus: null };
    },

    [SUBMIT_TPP_APPLICATION_FAILURE]: () => {
        return { isTppApplicationBeingSubmitted: false };
    },

    [GET_TPP_STATUS_FAILURE]: () => {
        return { isTppStatusBeingLoaded: false };
    },

    [GET_TPP_STATUS_SUCCESS]: (tppStatus: TppStatus) => {
        return {
            isTppStatusBeingLoaded: false,
            tppStatus
        };
    },

    [CLEAR_STORE]: () => {
        return initialState;
    },

    [RESET_ONBOARDING_STATE]: () => {

        const { tppStatus, ...rest } = initialState;

        return rest;
    }
};

export default createReducer(initialState, handlers);
