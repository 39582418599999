import { DropDepth, FileUploaderId } from "store/reducers/fileUploaderReducer.types";
import {
    SET_FILE_UPLOADER_BEING_DRAGGED_OVER_STATE,
    SET_FILE_UPLOADER_DROP_DEPTH,
    SET_FILE_UPLOADER_ERRORS,
    SET_FILE_UPLOADER_FILES,
    START_FILE_UPLOADER_ASYNC_VALIDATION,
    STOP_FILE_UPLOADER_ASYNC_VALIDATION
} from "./fileUploader.types";

export function setFileUploaderErrors(id: FileUploaderId, errors: string[]) {
    return {
        type: SET_FILE_UPLOADER_ERRORS,
        payload: {
            id,
            errors
        }
    };
}

export function setFileUploaderFiles(id: FileUploaderId, files: File[]) {
    return {
        type: SET_FILE_UPLOADER_FILES,
        payload: {
            id,
            files
        }
    };
}

export function setFileUploaderDropDepth(
    id: FileUploaderId,
    dropDepth: DropDepth
) {

    return {
        type: SET_FILE_UPLOADER_DROP_DEPTH,
        payload: {
            id,
            dropDepth
        }
    };
}

export function setFileUploaderBeingDraggedOverState(
    id: FileUploaderId,
    isBeingDraggedOver: boolean
) {

    return {
        type: SET_FILE_UPLOADER_BEING_DRAGGED_OVER_STATE,
        payload: {
            isBeingDraggedOver,
            id
        }
    };
}

export function stopFileUploaderAsyncValidation(id: FileUploaderId) {
    return {
        type: STOP_FILE_UPLOADER_ASYNC_VALIDATION,
        payload: id
    };
}

export function startFileUploaderAsyncValidation(id: FileUploaderId) {
    return {
        type: START_FILE_UPLOADER_ASYNC_VALIDATION,
        payload: id
    };
}
