import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { State } from 'models/app/store';

import { ResetPasswordStateProps } from './ResetPassword.types';
import { ResetPassword } from './ResetPassword.layout';

const mapStateToProps = (state: State): ResetPasswordStateProps => ({ resetPasswordStep: state.user.resetPasswordStep });

export default connect(mapStateToProps)(withTranslation('common')(ResetPassword));
