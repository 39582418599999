import { ApiEnvironment } from 'models/app/api-environment';
import { ApiKeysState, ApiKeyState } from 'models/app/store';
import { ApiKey } from 'models/domain/api-keys';
import { CLEAR_STORE } from 'store/actions/common.types';
import {
    ApiKeysActions,
    CONCEAL_API_KEY,
    DELETE_API_KEY,
    DELETE_API_KEY_FAILURE,
    GET_API_KEY_FAILURE,
    GET_API_KEY_LIST,
    GET_API_KEY_LIST_FAILURE,
    GET_API_KEY_LIST_SUCCESS,
    GET_API_KEY_SUCCESS,
    INSERT_API_KEY,
    INSERT_API_KEY_FAILURE
} from 'store/actions/api-keys.types';

import { createReducer, Handlers } from './reducer';


export const initialState: ApiKeysState = {
    keys: {} as Record<ApiEnvironment, ApiKeyState>,
    isLoading: false
};

function createUpdatedApiKeyState(
    state: ApiKeysState,
    environment: ApiEnvironment,
    content?: { update?: Partial<ApiKeyState>, override?: ApiKeyState },
    isLoading?: boolean
): Partial<ApiKeysState> {
    const apiKeys: ApiKeyState | undefined = content?.update ? { ...state.keys[environment], ...content.update } : content?.override;
    return { keys: { ...state.keys, [environment]: apiKeys }, isLoading };
}

function toApiKeyState(apiKey: ApiKey): ApiKeyState {
    const { apiKeyId, apiKeyValue } = apiKey;
    return { apiKeyId, apiKeyValue };
}

const handlers: Handlers<ApiKeysState, ApiKeysActions> = {
    [CONCEAL_API_KEY]: (request, state) => {
        return createUpdatedApiKeyState(state, request.environment, { update: { apiKeyValue: undefined } });
    },

    [INSERT_API_KEY]: () => ({ isLoading: true }),
    [INSERT_API_KEY_FAILURE]: () => ({ isLoading: false }),

    [DELETE_API_KEY]: () => ({ isLoading: true }),
    [DELETE_API_KEY_FAILURE]: () => ({ isLoading: false }),

    [GET_API_KEY_LIST]: () => ({ isLoading: true }),
    [GET_API_KEY_LIST_SUCCESS]: (response, state, request) => {
        const apiKey: ApiKeyState = response.apiKeys.map(toApiKeyState)?.[0];
        return createUpdatedApiKeyState(state, request.environment, { override: apiKey }, false);
    },
    [GET_API_KEY_LIST_FAILURE]: () => ({ isLoading: false }),

    [GET_API_KEY_SUCCESS]: (response, state, request) => {
        const apiKey: ApiKeyState = toApiKeyState(response);
        return createUpdatedApiKeyState(state, request.environment, { override: apiKey }, false);
    },
    [GET_API_KEY_FAILURE]: () => ({ isLoading: false }),

    [CLEAR_STORE]: () => {
        return initialState;
    }
};

export default createReducer(initialState, handlers);
