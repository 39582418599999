export enum DropDepth {
    'OUTSIDE_DROP_ZONE' = 0,
    'ON_THE_EDGE_OF_DROP_ZONE' = 1,
    'INSIDE_DROP_ZONE' = 2
}

export type FileUploaderId = string;
export type FileUploaderError = string;

export type FileUploaderState = Record<FileUploaderId, {
    files: File[],
    errors: FileUploaderError[],
    isBeingAsyncValidated: boolean,
    isBeingDraggedOver: boolean,
    dropDepth: DropDepth
}>
