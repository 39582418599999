import { compose, Dispatch } from "redux";
import { connect } from "react-redux";
import { downloadURI } from "store/actions/common";
import { Action } from "models/meta/action";
import ReportTile from "./ReportTile.layout";
import { ReportTileDispatchProps, ReportTileOwnProps } from "./ReportTile.types";

function mapDispatchToProps(
    dispatch: Dispatch<Action>
): ReportTileDispatchProps {

    return {
        dispatchDownloadURI: (uri, fileName) => {
            dispatch(downloadURI(uri, fileName));
        }
    };
}

export default compose<React.FC<ReportTileOwnProps>>(
    connect(null, mapDispatchToProps)
)(ReportTile);
