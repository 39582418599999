import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Dispatch } from 'models/meta/action';
import { ApiEnvironment } from 'models/app/api-environment';
import { ApiKeyState, State } from 'models/app/store';
import { concealApiKey, deleteApiKey, insertApiKey, revealApiKey } from 'store/actions/api-keys';

import { ApiKeySection } from './ApiKeySection.layout';
import { ApiKeySectionDispatchProps, ApiKeySectionStateProps } from './ApiKeySection.types';


const mapStateToProps = (state: State): ApiKeySectionStateProps => {
    const { isLoading } = state.apiKeys;
    const getApiKey: (environment: ApiEnvironment) => ApiKeyState = environment => {
        const apiKeyState = state.apiKeys.keys[environment];
        return { ...apiKeyState };
    };

    return { isLoading, getApiKey };
};

const mapDispatchToProps = (dispatch: Dispatch): ApiKeySectionDispatchProps => ({
    dispatchInsertKey: (environment) => { dispatch(insertApiKey(environment)); },
    dispatchDeleteKey: (environment, apiKeyId) => { dispatch(deleteApiKey(environment, apiKeyId)); },
    dispatchRevealKey: (environment, apiKeyId) => { dispatch(revealApiKey(environment, apiKeyId)); },
    dispatchConcealKey: (environment, apiKeyId) => { dispatch(concealApiKey(environment, apiKeyId)); }
});


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("api-keys")(ApiKeySection));
