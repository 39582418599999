import React from 'react';

import { defaultLoggedInRoutePath, defaultRoutePath, loginRoutePath, registerRoutePath } from 'config/config';

import AppLogo from 'components/reusable/AppLogo/AppLogo.layout';
import StandardTransition from 'components/reusable/StandardTransition/StandardTransition.layout';

import { SideBarLink } from './SideBarLink/SideBarLink.layout';
import LoggedInAs from './LoggedInAs/LoggedInAs';
import LoginOrRegister from './LoginOrRegister/LoginOrRegister';

import { SideBarProps } from './SideBar.types';
import Styled from './SideBar.styled';

const SideBar: React.FC<SideBarProps> = ({ shouldShowPostApprovalItems, isAuthenticated, t, location }) => {

    return (
        <Styled.SideBarWrap>
            <Styled.SideBarInnerWrap>

                <Styled.AppLogoWrap>
                    <AppLogo
                        linksToDefaultPath
                        greyOut={[loginRoutePath, registerRoutePath].includes(location.pathname)}
                    />
                </Styled.AppLogoWrap>

                <Styled.PublicLinksWrap>
                    <SideBarLink path={defaultRoutePath} text={t('leftSideBar.navItems.gettingStarted')} />
                    <SideBarLink path="/open-banking" text={t('leftSideBar.navItems.openBanking')} />
                    <SideBarLink
                        path="/availability-statistics"
                        text={t('leftSideBar.navItems.availabilityStatistics')}
                    />
                    <SideBarLink
                        path="/documentation"
                        text={t('leftSideBar.navItems.documentation')}
                    />
                </Styled.PublicLinksWrap>

                <Styled.UserPart>
                    <StandardTransition inProp={isAuthenticated}>
                        <LoggedInAs />
                    </StandardTransition>

                    <StandardTransition inProp={!isAuthenticated}>
                        <LoginOrRegister t={t} path={location.pathname} />
                    </StandardTransition>
                </Styled.UserPart>

                <Styled.AuthorisedLinksTransitionWrap>
                    <StandardTransition inProp={isAuthenticated}>
                        <Styled.AuthorisedLinksWrap>
                            <SideBarLink path={defaultLoggedInRoutePath} text={t('leftSideBar.navItems.onboarding')} />

                            {
                                shouldShowPostApprovalItems
                                    ? (
                                        <>
                                            <SideBarLink path="/environments" text={t('leftSideBar.navItems.environments')} />
                                            <SideBarLink
                                                path="/certificates"
                                                text={t('leftSideBar.navItems.testCertificates')}
                                            />
                                            <SideBarLink path="/api-keys" text={t('leftSideBar.navItems.apiKeys')} />
                                        </>
                                    )
                                    : <></>
                            }

                        </Styled.AuthorisedLinksWrap>
                    </StandardTransition>
                </Styled.AuthorisedLinksTransitionWrap>
            </Styled.SideBarInnerWrap>
        </Styled.SideBarWrap>
    );
};

export default SideBar;
