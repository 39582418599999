import { CertificatesActions } from "store/actions/certificates";
import { createReducer, Handlers } from './reducer';
import {
    GENERATE_SELF_SIGNED_CERTIFICATE,
    GENERATE_SELF_SIGNED_CERTIFICATE_FAILURE,
    GENERATE_SELF_SIGNED_CERTIFICATE_SUCCESS
} from "../actions/certificates.types";
import { CertificatesState } from "./certificatesReducer.types";

export const initialState: CertificatesState = { isSelfSignedCertificateBeingGenerated: false };

const handlers: Handlers<CertificatesState, CertificatesActions> = {

    [GENERATE_SELF_SIGNED_CERTIFICATE]: () => {
        return { isSelfSignedCertificateBeingGenerated: true };
    },

    [GENERATE_SELF_SIGNED_CERTIFICATE_SUCCESS]: () => {
        return { isSelfSignedCertificateBeingGenerated: false };
    },

    [GENERATE_SELF_SIGNED_CERTIFICATE_FAILURE]: () => {
        return { isSelfSignedCertificateBeingGenerated: false };
    },
};

export default createReducer(initialState, handlers);
