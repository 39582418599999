import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import State from "models/app/store";
import { AccessStatus } from "models/domain/tpp";
import SideBar from './SideBar.layout';

export default withRouter(withTranslation('common')(connect((state: State) => {
    return { shouldShowPostApprovalItems: state.onboarding.tppStatus?.accessStatus === AccessStatus.GRANTED };
})(SideBar)));
