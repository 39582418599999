import { OnboardingStep } from "models/app/workflow";
import { FormSubmitHandler, WrappedFieldProps } from "redux-form";
import * as yup from "yup";
import { WithTranslation } from "react-i18next";
import { TppApplicationStatus } from "models/domain/tpp";
import { DispatchSetOnboardingStep } from "../Onboarding.types";

type DeclaredAispField = 'declaredAisp';
type DeclaredPispField = 'declaredPisp';
type EidasQwacFileField = 'eidasQwacFile';

export const declaredAispField: DeclaredAispField = 'declaredAisp';
export const declaredPispField: DeclaredPispField = 'declaredPisp';
export const eidasQwacFileField: EidasQwacFileField = 'eidasQwacFile';

export type DispatchUntouchOnboardingFormFields = Function

export interface OnboardingFormProps extends OnboardingFormDispatchProps, OnboardingFormOwnProps, WithTranslation {

}

export interface OnboardingFormDispatchProps {
    dispatchUntouchOnboardingFormFields: DispatchUntouchOnboardingFormFields
}

export interface OnboardingFormOwnProps {
    onSubmit: FormSubmitHandler,
    onboardingStep: OnboardingStep | null,
    dispatchSetOnboardingStep: DispatchSetOnboardingStep,
}

export type OnPreviousButtonClick = () => void

export interface WithPreviousButtonClickHandler {
    onPreviousButtonClick: OnPreviousButtonClick
}

function testAgainstDeclarations(this: yup.TestContext) {

    const { parent } = this;

    if (parent.tppStatus === TppApplicationStatus.REGULATED_TPP) {
        return (
            [declaredAispField, declaredPispField].some((declarationName) => {
                return !!parent[declarationName];
            })
        );
    } else {
        return true;
    }
}

const declarationsTest = {
    name: 'roleMandatory',
    message: 'roleMandatory',
    test: testAgainstDeclarations
};

const fieldMandatory = 'common:validationMsg.fieldMandatory';

export const onboardingCompanyDetailsSchema = {
    companyName: yup.string()
        .max(255)
        .required(fieldMandatory),
    companyDisplayName: yup.string()
        .max(70)
        .required(fieldMandatory),
    companyEmail: yup.string()
        .max(255)
        .matches(/^([^.@]+)(\.[^.@]+)*@([^.@]+\.)+([^.@]+)$/, `common:validationMsg.validEmail`)
        .required(fieldMandatory),
    companyRegistrationNumber: yup.string().max(255).required(fieldMandatory),
    companyRegistrationCountry: yup.string()
        .required(fieldMandatory),
};

export const onboardingRepresentativeSchema = {
    representativeFirstName: yup.string().max(255).required(fieldMandatory),
    representativeLastName: yup.string().max(255).required(fieldMandatory),
    representativePosition: yup.string().max(255).required(fieldMandatory),
};

export const onboardingPSD2DetailsSchema = {
    [eidasQwacFileField]: yup.string().test({
        name: 'CheckIfRequired',
        message: `fileRequired`,
        test(value) {
            return !(!value && this.parent.tppStatus === TppApplicationStatus.REGULATED_TPP);
        }
    }),
    tppStatus: yup.string().required(fieldMandatory),
    [declaredAispField]: yup.boolean().test(declarationsTest),
    [declaredPispField]: yup.boolean().test(declarationsTest),
};

export const onboardingCompanyDetailsSchemaObject = yup.object(onboardingCompanyDetailsSchema);

export const onboardingRepresentativeSchemaObject = yup.object(onboardingRepresentativeSchema);

export const onboardingPSD2DetailsSchemaObject = yup.object(onboardingPSD2DetailsSchema);

export const onboardingSchema = yup.object({
    ...onboardingCompanyDetailsSchema,
    ...onboardingRepresentativeSchema,
    ...onboardingPSD2DetailsSchema
});

export interface StepHeaderProps {
    fontSize?: string;
    copy: string,
    stepCount?: number
}

export interface OnboardingFieldProps extends WrappedFieldProps {
    outsideTooltip: string,
    placeholder: string,
    id: string
}
