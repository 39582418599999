import styled, { css } from 'styled-components';
import { IconFont, IconFontProps } from "assets/styles/icons/Icons.styled";
import { BorderColorTransition, FadeIn, FadeInKeyframe } from "assets/styles/animations/animations.styled";
import { WithTheme } from "config/theme";
import { WithDisabled } from "models/app/common";

const FileUploaderWrapper = styled.section<{
    showError: boolean,
}>`
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    border-radius: 4px;
    margin-top: 8px;
    padding: 25px 20px 25px 25px;
    
    ${BorderColorTransition};

    color: ${({ theme, showError }) => showError ? theme.colors.error.base : 'initial'};
    border: 1px solid ${({ showError, theme }: WithTheme & { showError: boolean }) => showError ? theme.colors.error.base : theme.colors.gray.base}; 
`;

const UploadContentWrapper = styled.div<{ blur: boolean }>`
    filter: ${props => props.blur ? 'blur(2px)' : 'none'};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const UploaderContent = styled.div`
    display: inline-flex;
    align-items: center;
`;

const OverlayOnDragOver = styled.div<{ allowed?: boolean }>`
    background-color: ${props => props.allowed ? 'rgba(128, 128, 128, .1)' : 'rgba(251, 209, 209, .21)'};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const OverlayNotAllowed = styled.div`
    background-color: rgba(251, 209, 209, 0.21);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
`;

export const List = styled.ul`
    list-style: none;
    padding: 8px 16px;
    margin: 0;
    text-align: left;
`;

const FilesList = styled(List)`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    transition: opacity 0.5s ease-in;
    padding: 4px 0;
`;

const FileListElementFilename = styled.span<{ width: number }>`
    overflow: hidden;
    max-width: 70%;
    text-overflow: ellipsis;
    display: inline-block;
    width: ${({ width }) => `${width + 2}ch`};
`;

const FilesListElement = styled.li`
    max-width: 500px;
    white-space: nowrap;
    color: #767676;
    font-size: 14px;
    margin: 8px 0;
    padding: 4px;
    opacity: 0;
    display: flex;
    align-items: center;
    animation: ${FadeInKeyframe} 0.3s ease-in;
    animation-fill-mode: forwards;
`;

const FileListIcon = styled<React.FC<WithDisabled & WithTheme & IconFontProps>>(IconFont)`
    margin-right: 15px;
    
    ${({ disabled, theme }) => disabled ? `color: ${theme.colors.gray.darkest}` : ''}
`;

const FileListButton = styled.button`
    margin-left: 15px;
`;

const FileUploadInput = styled.input`
    display: none;
`;

FileUploaderWrapper.displayName = 'FileUploaderWrapper';

export const ErrorMessage = css`
    display: flex;
    font-size: 12px;
    position: relative;
    min-height: 12px;
    color: ${({ theme }: WithTheme) => theme.colors.error.base};

    > span {
        position: absolute;
        left: 0;
        line-height: 14px;
    }
`;

const FileError = styled.div`
    ${ErrorMessage}
    ${FadeIn}
`;

const AttachmentsArea = styled.span`
    margin-left: 10px;
`;

export default {
    FileUploaderWrapper,
    OverlayOnDragOver,
    OverlayNotAllowed,
    FilesList,
    FileListButton,
    FilesListElement,
    AttachmentsArea,
    FileListElementFilename,
    FileUploadInput,
    UploadContentWrapper,
    FileError,
    FileListIcon,
    UploaderContent
};
