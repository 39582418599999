import { TppApplicationStatus } from "models/domain/tpp";

export const getTppStatusTranslationPath = (tppStatus?: TppApplicationStatus): string => {
    return ({
        [TppApplicationStatus.OBTAINING_TPP_LICENSE]: () => {
            return 'postSubmission.fields.tppStatus.valueObtainingLicense';
        },
        [TppApplicationStatus.REGULATED_TPP]: () => {
            return 'postSubmission.fields.tppStatus.valueRegulatedTpp';
        },
    }[tppStatus || ''] || (() => ''))();
};
