import styled from 'styled-components';
import {

    ColorProps,
    HeightProps,
    SpaceProps,
    FontSizeProps,
    FontWeightProps,
    WidthProps,
    color,
    fontSize,
    fontWeight,
    space,
} from 'styled-system';

export type IconOrientation = 'upside-down' | 'rotated-clockwise'

const transformIcon = (iconOrientation: IconOrientation): string => {
    return {
        'upside-down': 'transform: rotate(-180deg);',
        'rotated-clockwise': 'transform: rotate(-90deg);'
    }[iconOrientation];
};

export interface IconProps {
    icon: string,
    iconOrientation?: IconOrientation,
}

export type IconFontProps = IconProps & FontSizeProps & FontWeightProps & WidthProps & HeightProps & SpaceProps & ColorProps;

export const IconFont = styled.i<IconFontProps>`
  font-family: 'okeoIcons' !important;
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: inherit;
  backface-visibility: hidden;
  transform-origin: center center;
  transition: color .2s ease-in, transform .3s ease-out;

  ${fontSize}
  ${fontWeight}
  ${space}
  ${color}
  ${({ iconOrientation }) => iconOrientation ? transformIcon(iconOrientation) : ''}
  
  &:before {
    content: '${({ icon }) => icon}';
    min-width: 16px;
    min-height: 16px;
    color: inherit;
  }
`;
