import { RedocStandalone } from 'redoc';
import React, { FunctionComponent } from 'react';

import theme from 'config/theme';
import { apiDocumentationUrl } from 'models/app/api-environment';
import { docNavBarHeight, fontFamily, sideBarWidth } from 'assets/styles/global/layout.styled';

import { globalColors } from 'components/AppLayout/config';
import NavigationBar from './NavigationBar/NavigationBar';
import Styled from './Documentation.styled';

const Documentation: FunctionComponent = () => {
    return (
        <Styled.DocumentationWrap>

            <NavigationBar />

            <RedocStandalone
                specUrl={apiDocumentationUrl}
                options={{
                    maxDisplayedEnumValues: 6,
                    hideHostname: true,
                    disableSearch: true,
                    noAutoAuth: true,
                    enumSkipQuotes: true,
                    hideDownloadButton: true,
                    jsonSampleExpandLevel: 'all',
                    expandSingleSchemaField: true,
                    expandResponses: "200,201,202,204",
                    scrollYOffset: docNavBarHeight,
                    theme: {
                        colors: { primary: { main: globalColors.fontColors.green.dark } },
                        sidebar: {
                            width: `${sideBarWidth}px`,
                            backgroundColor: theme.colors.gray.xlight
                        },
                        typography: {
                            fontFamily,
                            headings: { fontFamily }
                        }
                    }
                }}
            />
        </Styled.DocumentationWrap>
    );
};

export default Documentation;
