import React from 'react';
import Icons from 'assets/styles/icons/icons';
import { loginRoutePath } from 'config/config';
import { globalColors } from 'components/AppLayout/config';
import { DecorationIcon, PageSubHeader, CenteredButton } from 'components/reusable/Authentication/AuthenticationPanel/AuthenticationPanel.styled';

import AuthenticationPanel from 'components/reusable/Authentication/AuthenticationPanel/AuthenticationPanel';
import { ResetPasswordSuccessProps } from './ResetPasswordSuccess.types';

export const ResetPasswordSuccess: React.FC<ResetPasswordSuccessProps> = ({ t, dispatchNavigate }) => {
    return (
        <AuthenticationPanel pageHeader={t('pageHeader.restoreAccess')}>
            <PageSubHeader>
                {t('restoreAccessResult.line1')}
                <br />
                {t('restoreAccessResult.line2')}
            </PageSubHeader>

            <DecorationIcon
                icon={Icons.envelope}
                color={globalColors.fontColors.green.light}
            />

            <CenteredButton
                width="auto"
                type="button"
                onClick={() => dispatchNavigate(loginRoutePath)}
            >
                {t('buttons.login.text')}
            </CenteredButton>

        </AuthenticationPanel>
    );
};
