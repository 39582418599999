import styled from "styled-components";

export const StepHeaderWrapper = styled.div<{ fontSize?: string}>`
    margin-bottom: 30px;
    font-size: ${({ fontSize }) => fontSize || 'initial'}
`;

export const OnboardingFieldWrap = styled.div`
    margin-bottom: 10px;
 `;

export const CenteredSpaceBetween = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
 `;
