import { compose, Dispatch } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { destroy, reduxForm } from "redux-form";
import State from "models/app/store";
import { OnboardingStep } from "models/app/workflow";
import { setOnboardingStep } from "store/actions/onboarding";
import { Action } from "models/meta/action";
import { OnboardingApplicationReview } from "./OnboardingApplicationReview.layout";
import {
    OnboardingApplicationReviewDispatchProps,
    OnboardingApplicationReviewOwnProps,
    OnboardingApplicationReviewStateProps
} from "./OnboardingApplicationReview.types";
import { ONBOARDING_FORM_NAME } from "../Onboarding.types";

function mapStateToProps(state: State): OnboardingApplicationReviewStateProps {
    return {
        tppDetails: state.onboarding.tppDetails,
        countriesList: state.dictionary.countries
    };
}

function mapDispatchToProps(dispatch: Dispatch<Action>): OnboardingApplicationReviewDispatchProps {
    return {
        dispatchDestroyOnboardingForm: () => {
            dispatch(destroy(ONBOARDING_FORM_NAME));
        },
        dispatchSetOnboardingStep: (onboardingStep: OnboardingStep) => {
            dispatch(setOnboardingStep(onboardingStep));
        }
    };
}

export default compose<React.FC<OnboardingApplicationReviewOwnProps>>(
    withTranslation('onboarding'),
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        destroyOnUnmount: false,
        form: ONBOARDING_FORM_NAME
    })
)(OnboardingApplicationReview);
