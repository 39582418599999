import React from "react";
import { Field } from "redux-form";
import { FormField } from "components/reusable/FormField/FormField.layout";
import { withCheckbox } from "components/reusable/FormField/Checkbox/Checkbox.layout";
import { SubSectionHeader } from "components/reusable/SubSectionHeader/SubSectionHeader.layout";
import { OnboardingFormPSD2DetailsApplicableRolesProps } from "./OnboardingFormPSD2Details.types";
import { OnboardingFormPSD2DetailsApplicableRolesWrapper } from "./OnboardingFormPSD2Details.styled";

const OnboardingFormPSD2DetailsApplicableRoles:
    React.FC<OnboardingFormPSD2DetailsApplicableRolesProps> = (
        { t, disabled = false, withTooltips = true, withLabel = true, labels, showError }
    ) => (

        <OnboardingFormPSD2DetailsApplicableRolesWrapper>
            {withLabel && <SubSectionHeader copy={t('preSubmission.psd2DetailsStep.fields.roles.label')} />}

            <Field
                name="declaredAisp"
                component={FormField}
                shouldShowError={false}
                renderCustomInput={withCheckbox({
                    showError,
                    disabled,
                    label: labels?.[0] || t('onboarding:preSubmission.psd2DetailsStep.fields.roles.aisp'),
                    tooltip: withTooltips ? t('onboarding:preSubmission.psd2DetailsStep.fields.roles.tooltipAisp') : ''
                })}
                t={t}
            />

            <Field
                p={0}
                name="declaredPisp"
                component={FormField}
                shouldShowError={false}
                renderCustomInput={withCheckbox({
                    showError,
                    disabled,
                    label: labels?.[1] || t('onboarding:preSubmission.psd2DetailsStep.fields.roles.pisp'),
                    tooltip: withTooltips ? t('onboarding:preSubmission.psd2DetailsStep.fields.roles.tooltipPisp') : ''
                })}
                t={t}
            />

        </OnboardingFormPSD2DetailsApplicableRolesWrapper>
    );

export default OnboardingFormPSD2DetailsApplicableRoles;
