import {
    END_APP_LOADING,
    EndAppLoading,
    START_APP_LOADING,
    StartAppLoading,
    SET_LANGUAGE,
    SetLanguage,
    SetScrollPosition,
    SET_SCROLL_POSITION,
    SetScrollPositionProps
} from './app.types';

export const startAppLoading = (): StartAppLoading => ({ type: START_APP_LOADING });
export const endAppLoading = (): EndAppLoading => ({ type: END_APP_LOADING });
export const setLanguage = (locale: string): SetLanguage => ({ type: SET_LANGUAGE, payload: locale });
export const setScrollPosition = (options: SetScrollPositionProps): SetScrollPosition => ({ type: SET_SCROLL_POSITION, payload: options });
