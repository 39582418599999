import React from 'react';
import { globalColors } from 'components/AppLayout/config';

const ArrowDown: React.FC<{ fill?: string, width?: number, height?: number }> = ({
    fill = globalColors.fontColors.green.light,
    width = 11.29,
    height = 7.06,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 11.29 7.06"
    >
        <path
            id="arrow-right"
            d="M11.915,6l5.645,5.645L11.915,17.29,10.5,15.879l4.238-4.234L10.5,7.411Z"
            transform="translate(17.29 -10.5) rotate(90)"
            fill={fill}
            fillRule="evenodd"
        />
    </svg>
);

ArrowDown.defaultProps = {
    fill: globalColors.fontColors.green.light,
    width: 11.29,
    height: 7.06,
};

export default ArrowDown;
