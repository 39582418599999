import styled from "styled-components";
import { BorderColorTransition } from "assets/styles/animations/animations.styled";
import { WithTheme } from "config/theme";

export const TppStatusSelector = styled.div`
    position: relative;
    display: flex;
    align-content: center;
    
    .react-dropdown-select {
        min-height: 54px;
    }
    
    .react-dropdown-select-content.react-dropdown-select-content {
        padding: 18px;
    }
    
    .react-dropdown-select-dropdown.react-dropdown-select-dropdown {
        right: -1px;
        
        & .react-dropdown-select-item {
            min-height: 54px;
        }
    }
`;

export const TppStatusSelectorWrapper = styled.div<{ showError: boolean }>`
    
    ${BorderColorTransition};

    width: 100%;
    margin-right: 32px;
    color: ${({ theme, showError }) => showError ? theme.colors.error.base : 'initial'};
    border: 1px solid ${({ showError, theme }: WithTheme & { showError: boolean }) => showError ? theme.colors.error.base : theme.colors.gray.base}; 
`;
