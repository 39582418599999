import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { getFormAsyncErrors, reduxForm } from 'redux-form';

import { State } from 'models/app/store';
import { Dispatch } from 'models/meta/action';
import { navigate } from 'store/actions/common';

import { login } from 'store/actions/user';
import { createAsyncValidate, shouldAsyncValidate } from "utils/form";

import {
    LOGIN_FORM,
    LoginFormProps,
    LoginFormDispatchProps,
    LoginFormStateProps,
    schema,
} from './LoginForm.types';
import { LoginForm } from './LoginForm.layout';

const mapStateToProps = (state: State): LoginFormStateProps => ({
    fieldsValues: state.form?.[LOGIN_FORM]?.values || {},
    formErrors: getFormAsyncErrors(LOGIN_FORM)(state) || {},
    loginStep: state.user.loginStep,
});

export const mapDispatchToProps = (dispatch: Dispatch): LoginFormDispatchProps => ({
    dispatchLogin: (emailAddress, password) => dispatch(login({ emailAddress, password })),
    dispatchNavigate: (location, options) => dispatch(navigate(location, options))
});

const decorate = compose<React.FC<Partial<LoginFormProps>>>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withTranslation('common'),
    reduxForm({
        form: LOGIN_FORM,
        initialValues: {
            emailAddress: '',
            password: '',
        },
        asyncValidate: createAsyncValidate(schema),
        shouldAsyncValidate
    })
);

export default decorate(LoginForm);
