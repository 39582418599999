import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { State } from 'models/app/store';
import { RegisterStateProps } from './Register.types';
import { Register } from './Register.layout';

const mapStateToProps = (state: State): RegisterStateProps => ({ registerStep: state.user.registerStep });

export default connect(mapStateToProps)(withTranslation('common')(Register));
