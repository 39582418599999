import styled from "styled-components";
import { NarrowContentWrap } from "components/AppLayout/AppLayout.styled";


export const Wrapper = styled(NarrowContentWrap)`
    padding-bottom: 40px;
`;

const ReportTileWrapper = styled.div`
    display: grid;
    padding-top: 50px;
    grid-gap: 50px;
    grid-template-columns: 1fr 1fr;
`;

export default { ReportTileWrapper, Wrapper };
