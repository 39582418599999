import React from 'react';

import { apiEnvironmentDetails } from 'models/app/api-environment';

import { ApiKeySectionProps } from './ApiKeySection.types';
import { ApiKeyHolder } from './ApiKeyHolder/ApiKeyHolder.layout';
import Styled from './ApiKeySection.styled';


export const ApiKeySection: React.FC<ApiKeySectionProps> = props => {
    const { getApiKey, isLoading, environment, dispatchInsertKey, dispatchDeleteKey, dispatchRevealKey, dispatchConcealKey, t } = props;
    const apiKey = getApiKey(environment);
    const apiKeyId = apiKey?.apiKeyId;
    const present = !!apiKeyId;

    return (
        <Styled.ApiKeySection>
            <Styled.ApiKeySectionHeader>{t(`sectionHeaders.${apiEnvironmentDetails[environment]?.headerKey}`, '???')}</Styled.ApiKeySectionHeader>
            <Styled.ApiKeyHeader>{t("apiKeyItem.header")}</Styled.ApiKeyHeader>
            <ApiKeyHolder
                placeholder={t("apiKeyItem.noApiKey")}
                apiKey={apiKey}
                isLoading={isLoading}
                onIconClick={() => dispatchRevealKey(environment, apiKeyId!)}
                onBlur={() => dispatchConcealKey(environment, apiKeyId!)}
            />

            <Styled.ApiKeyOperations>
                <Styled.ApiKeyOperation
                    onClick={() => dispatchInsertKey(environment)}
                    disabled={present || isLoading}
                >
                    {t("buttons.generateKey.text")}
                </Styled.ApiKeyOperation>
                <Styled.ApiKeyOperation
                    onClick={() => dispatchDeleteKey(environment, apiKeyId!)}
                    disabled={!present || isLoading}
                >
                    {t("buttons.deleteKey.text")}
                </Styled.ApiKeyOperation>
            </Styled.ApiKeyOperations>
        </Styled.ApiKeySection>
    );
};
