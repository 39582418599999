import { ofType } from 'redux-observable';
import { switchMap, tap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import i18next from 'i18next';

import { Epic } from 'models/meta/epic';
import {
    DISMISS_ALL_BUT_UNAUTH_TOASTS,
    DISMISS_ALL_TOASTS,
    DOWNLOAD_URI,
    DownloadURI,
    NAVIGATE,
    SHOW_ERROR_TOAST,
    SHOW_INFO_TOAST,
    SHOW_SUCCESS_TOAST,
    SHOW_WARN_TOAST
} from 'store/actions/common.types';

import renderToastMessage from 'components/reusable/Toast/Toast.layout';
import { UNATHORIZED_TOAST_ID } from "./certificatesEpic.types";

export const onDownloadURI: Epic = (action$, state$, { document, file }) =>
    action$.pipe(
        ofType(DOWNLOAD_URI),
        tap(({ payload: { uri, fileName } }: DownloadURI) => {

            file.downloadURI(document, uri, fileName);
        }),
        switchMap(() => EMPTY)
    );

export const onShowSuccessToast: Epic = (action$, _, { showToast }) =>
    action$.pipe(
        ofType(SHOW_SUCCESS_TOAST),
        switchMap(({ payload: { title, desc, options } }) => {
            showToast(renderToastMessage(`${title}`, `${desc}`), 'SUCCESS', options);
            return EMPTY;
        })
    );
export const onShowInfoToast: Epic = (action$, _, { showToast }) =>
    action$.pipe(
        ofType(SHOW_INFO_TOAST),
        switchMap(({ payload: { title, desc, options } }) => {
            showToast(renderToastMessage(i18next.t(`${title}`), i18next.t(`${desc}`)), 'INFO', options);
            return EMPTY;
        })
    );

export const onShowWarnToast: Epic = (action$, _, { showToast }) =>
    action$.pipe(
        ofType(SHOW_WARN_TOAST),
        switchMap(({ payload: { title, desc, options } }) => {
            showToast(renderToastMessage(i18next.t(`${title}`), i18next.t(`${desc}`)), 'WARN', options);
            return EMPTY;
        })
    );

export const onShowErrorToast: Epic = (action$, _, { showToast }) =>
    action$.pipe(
        ofType(SHOW_ERROR_TOAST),
        switchMap(({ payload: { title, desc, options, translatingOptions } }) => {
            const toastMessage = (
                translatingOptions.shouldTranslate
                    ? renderToastMessage(i18next.t(`${title}`), i18next.t(`${desc}`))
                    : renderToastMessage(title, desc)
            );

            const toastId = showToast(toastMessage, 'DANGER', options);

            return of({ type: 'SET_TOAST_ID', payload: { toastId } });
        })
    );

export const onNavigationRequested: Epic = (action$, _, { history }) => action$.pipe(
    ofType(NAVIGATE),
    tap(({ payload, meta }) => {
        const { externalURL } = meta;
        if (externalURL) {
            window.location = payload;
        } else {
            history.replace(payload);
        }
    }),

    switchMap(() => EMPTY)
);

export const onDismissAllToasts: Epic = (action$, _, { toast }) =>
    action$.pipe(
        ofType(DISMISS_ALL_TOASTS),
        tap(() => {
            toast.dismiss();
        }),
        switchMap(() => {
            return EMPTY;
        })
    );

export const onDismissAllButUnauthToasts: Epic = (action$, state$, { toast }) =>
    action$.pipe(
        ofType(DISMISS_ALL_BUT_UNAUTH_TOASTS),
        tap(() => {

            Object.keys(state$.value.toasts).forEach((toastId) => {
                if (toastId !== UNATHORIZED_TOAST_ID) {
                    toast.dismiss(toastId);
                }
            });
        }),
        switchMap(() => {
            return EMPTY;
        })
    );
