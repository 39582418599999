class FileService {

    static downloadURI(
        document: HTMLDocument,
        uri: string,
        fileName: string
    ): void {

        const htmlAnchorElement: HTMLAnchorElement = document.createElement('a');

        htmlAnchorElement.href = uri;
        htmlAnchorElement.download = fileName;
        htmlAnchorElement.click();
    }
}

export default FileService;
