import { OperationResultClass } from 'components/AppLayout/config.types';

export type OkeoButtonAction = ActionVariant;

export enum ActionVariant {
    SUCCESS = 'SUCCESS',
    INFO = 'INFO',
    WARN = 'WARN',
    DANGER = 'DANGER',
    ERROR = 'ERROR',
}

// @ts-ignore
export interface OkeoButtonProps extends ButtonProps {
    actionVariant?: OkeoButtonAction | OperationResultClass;
}
