import styled from 'styled-components';
import { docNavBarHeight, navBarDecorationHeight, sideBarWidth } from 'assets/styles/global/layout.styled';
import { globalColors, okeoGradient } from 'components/AppLayout/config';

const NavigationBar = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, .26);
`;

const NavigationBarDecorationWrapper = styled.div`
    height: ${navBarDecorationHeight}px;
    width: 100%;
    background: ${okeoGradient};
`;

const NavigationBarLogoAndButtonWrap = styled.div`
    display: flex;
    align-self: center;
    justify-content: space-between;
    height: ${docNavBarHeight - navBarDecorationHeight}px;
    padding: 20px 0;
    background-color: ${globalColors.backgrounds.white};
`;

const LogoWrap = styled.div`
    position: relative;
    top: -4px;
    width: ${sideBarWidth}px;
    text-align: center;
`;

const ButtonWrap = styled.div`
    padding-right: 50px;
`;

export default {
    LogoWrap,
    ButtonWrap,
    NavigationBar,
    NavigationBarDecorationWrapper,
    NavigationBarLogoAndButtonWrap,
};
