import React from 'react';
import Icons from 'assets/styles/icons/icons';

import { CloseToastButtonProps } from './CloseToastButton.types';
import Styled from './CloseToastButton.styled';

export default function CloseToastButton({ closeToast }: CloseToastButtonProps): JSX.Element {
    return (
        <Styled.ToastButton type="button" onClick={closeToast}>
            <Styled.Icon icon={Icons.reject} fontSize={4} mr={3} color="gray.xdark" />
        </Styled.ToastButton>
    );
}
