import { CountriesInfo } from 'models/domain/dictionary';

import {
    GET_COUNTRIES_INFO,
    GET_COUNTRIES_INFO_FAILURE,
    GET_COUNTRIES_INFO_SUCCESS,
    GetCountriesInfo,
    GetCountriesInfoFailure,
    GetCountriesInfoSuccess,
} from './dictionary.types';


export const getCountriesInfo = (): GetCountriesInfo => ({ type: GET_COUNTRIES_INFO });
export const getCountriesInfoSuccess = (countries: CountriesInfo): GetCountriesInfoSuccess => ({ type: GET_COUNTRIES_INFO_SUCCESS, payload: { countries } });
export const getCountriesInfoFailure = (): GetCountriesInfoFailure => ({ type: GET_COUNTRIES_INFO_FAILURE });
