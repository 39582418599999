import { CountriesInfo } from 'models/domain/dictionary';

type GET_COUNTRIES_INFO = 'GET_COUNTRIES_INFO';
type GET_COUNTRIES_INFO_SUCCESS = 'GET_COUNTRIES_INFO_SUCCESS';
type GET_COUNTRIES_INFO_FAILURE = 'GET_COUNTRIES_INFO_FAILURE';

export const GET_COUNTRIES_INFO: GET_COUNTRIES_INFO = 'GET_COUNTRIES_INFO';
export const GET_COUNTRIES_INFO_SUCCESS: GET_COUNTRIES_INFO_SUCCESS = 'GET_COUNTRIES_INFO_SUCCESS';
export const GET_COUNTRIES_INFO_FAILURE: GET_COUNTRIES_INFO_FAILURE = 'GET_COUNTRIES_INFO_FAILURE';

export interface GetCountriesInfo {
    type: GET_COUNTRIES_INFO;
}

export interface GetCountriesInfoSuccess {
    type: GET_COUNTRIES_INFO_SUCCESS;
    payload: { countries: CountriesInfo; }
}

export interface GetCountriesInfoFailure {
    type: GET_COUNTRIES_INFO_FAILURE;
}


type GetCountriesInfoActions = GetCountriesInfo | GetCountriesInfoSuccess | GetCountriesInfoFailure;

export type DictionaryActions = GetCountriesInfoActions;
