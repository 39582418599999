import { UserState } from 'models/app/store';
import {
    LOGIN,
    LOGOUT,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    SET_LOGIN_STEP,
    SET_REGISTER_STEP,
    SET_RESET_PASSWORD_STEP,
    TppActions
} from 'store/actions/user.types';
import { LoginStep, RegisterStep, ResetPasswordStep } from 'models/app/workflow';
import { User } from 'models/domain/user';
import { createReducer, Handlers } from './reducer';

import { CLEAR_STORE } from '../actions/common.types';

export const initialState: UserState = {
    isAuthenticated: false,
    isLoading: false,
    loginStep: LoginStep.AUTHORISATION,
    registerStep: RegisterStep.REGISTRATION,
    resetPasswordStep: ResetPasswordStep.RESET_FORM,
};

const handlers: Handlers<UserState, TppActions> = {
    [LOGIN]: () => {
        return { isLoading: true };
    },
    [LOGIN_FAILURE]: () => {
        return { isAuthenticated: false, isLoading: false };
    },
    [LOGIN_SUCCESS]: (payload: User) => {
        return {
            isAuthenticated: true,
            isLoading: false,
            data: payload
        };
    },
    [LOGOUT]: () => {
        return initialState;
    },
    [REGISTER]: () => {
        return { isLoading: true };
    },
    [REGISTER_FAILURE]: () => {
        return { isLoading: false };
    },
    [REGISTER_SUCCESS]: () => {
        return { isLoading: false };
    },
    [RESET_PASSWORD]: () => {
        return { isLoading: true };
    },
    [RESET_PASSWORD_FAILURE]: () => {
        return { isLoading: false };
    },
    [RESET_PASSWORD_SUCCESS]: () => {
        return { isLoading: false };
    },
    [SET_LOGIN_STEP]: (payload: LoginStep) => {
        return { loginStep: payload };
    },
    [SET_REGISTER_STEP]: (payload: RegisterStep) => {
        return { registerStep: payload };
    },
    [SET_RESET_PASSWORD_STEP]: (payload: ResetPasswordStep) => {
        return { resetPasswordStep: payload };
    },
    [CLEAR_STORE]: () => {
        return initialState;
    }
};

export default createReducer(initialState, handlers);
