import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Dispatch } from 'models/meta/action';
import { navigate } from 'store/actions/common';
import { RegisterSuccess } from './RegisterSuccess.layout';
import { RegisterSuccessProps, RegisterSuccessDispatchProps } from './RegisterSuccess.types';

const mapDispatchToProps = (dispatch: Dispatch): RegisterSuccessDispatchProps =>
    ({ dispatchNavigate: (location, options) => { dispatch(navigate(location, options)); } });

export default compose<React.FC<Partial<RegisterSuccessProps>>>(
    withTranslation('common'),
    connect(null, mapDispatchToProps)
)(RegisterSuccess);
