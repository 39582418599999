import {
    CSR_FILE_UPLOADER_NAME,
    FileUploaderActions,
    ONBOARDING_FILE_UPLOADER_NAME,
    SET_FILE_UPLOADER_BEING_DRAGGED_OVER_STATE,
    SET_FILE_UPLOADER_DROP_DEPTH,
    SET_FILE_UPLOADER_ERRORS,
    SET_FILE_UPLOADER_FILES,
    START_FILE_UPLOADER_ASYNC_VALIDATION,
    STOP_FILE_UPLOADER_ASYNC_VALIDATION
} from "store/actions/fileUploader.types";
import { createReducer, Handlers } from './reducer';
import { DropDepth, FileUploaderState } from "./fileUploaderReducer.types";

const singleFileUploaderInitialState = {
    files: [],
    errors: [],
    isBeingDraggedOver: false,
    isBeingAsyncValidated: false,
    dropDepth: DropDepth.OUTSIDE_DROP_ZONE
};

export const initialState: FileUploaderState = {
    [ONBOARDING_FILE_UPLOADER_NAME]: singleFileUploaderInitialState,
    [CSR_FILE_UPLOADER_NAME]: singleFileUploaderInitialState
};

const handlers: Handlers<FileUploaderState, FileUploaderActions> = {
    [SET_FILE_UPLOADER_BEING_DRAGGED_OVER_STATE]: ({ id, isBeingDraggedOver }, state): Partial<FileUploaderState> => {
        return {
            [id]: {
                ...state[id],
                isBeingDraggedOver
            }
        };
    },
    [SET_FILE_UPLOADER_DROP_DEPTH]: ({ id, dropDepth }, state): Partial<FileUploaderState> => {
        return {
            [id]: {
                ...state[id],
                dropDepth
            }
        };
    },
    [SET_FILE_UPLOADER_FILES]: ({ id, files }, state): Partial<FileUploaderState> => {
        return {
            [id]: {
                ...state[id],
                files
            }
        };
    },
    [SET_FILE_UPLOADER_ERRORS]: ({ id, errors }, state): Partial<FileUploaderState> => {
        return {
            [id]: {
                ...state[id],
                errors
            }
        };
    },
    [START_FILE_UPLOADER_ASYNC_VALIDATION]: (id, state) => {
        return {
            [id]: {
                ...state[id],
                isBeingAsyncValidated: true
            }
        };
    },
    [STOP_FILE_UPLOADER_ASYNC_VALIDATION]: (id, state) => {
        return {
            [id]: {
                ...state[id],
                isBeingAsyncValidated: false
            }
        };
    },
};

export default createReducer(initialState, handlers);
