import React from 'react';
import Styled from './AuthenticationPanel.styled';

import { AuthenticationPanelProps } from './AuthenticationPanel.types';

export default function AuthenticationPanel({ children, pageHeader }: AuthenticationPanelProps) {

    return (
        <div>
            <Styled.PageHeader>{pageHeader}</Styled.PageHeader>

            <Styled.AuthenticationPanelWrap>
                { children }
            </Styled.AuthenticationPanelWrap>
        </div>

    );
}
