import React from 'react';
import { Trans } from 'react-i18next';
import { ErrorMessageProps } from './ErrorMessage.types';
import Styled from './ErrorMessage.styled';

export default function ErrorMessage({ translationKey, translationValues, dataTestId }: ErrorMessageProps) {

    return (
        <Styled.ErrorMessageWrapper data-test-id={`${dataTestId}-error-message`}>
            <Styled.ErrorMessageText>
                <Trans
                    i18nKey={translationKey}
                    values={translationValues}
                />
            </Styled.ErrorMessageText>
        </Styled.ErrorMessageWrapper>
    );
}
