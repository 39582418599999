import React, { useEffect } from 'react';
import { defaultRoutePath } from 'config/config';

import AppLogo from 'components/reusable/AppLogo/AppLogo.layout';
import CircleButton from 'components/reusable/Buttons/CircleButton/CircleButton.layout';
import Styled from './AuthenticationPage.styled';

import { AuthenticationPageProps } from './AuthenticationPage.types';

export default function AuthenticationPage({ t, children, dispatchLogout, dispatchNavigate }: AuthenticationPageProps) {

    useEffect(() => {
        dispatchLogout();
    }, []);

    return (
        <Styled.AuthenticationPageOuterWrap>

            <CircleButton
                onClick={() => dispatchNavigate(defaultRoutePath)}
                iconName="reject"
                iconTooltip={t('buttons.exitCircleButton.openPartTitle')}
            />

            <Styled.AuthenticationPageInnerWrap>

                <Styled.AuthenticationPageLogoWrap>
                    <AppLogo size="larger" />
                </Styled.AuthenticationPageLogoWrap>

                { children }

            </Styled.AuthenticationPageInnerWrap>
        </Styled.AuthenticationPageOuterWrap>
    );
}
