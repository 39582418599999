import { UploaderFile } from "components/reusable/FileUploader/FileUploader.types";
import {
    GENERATE_SELF_SIGNED_CERTIFICATE,
    GENERATE_SELF_SIGNED_CERTIFICATE_FAILURE,
    GENERATE_SELF_SIGNED_CERTIFICATE_SUCCESS,
    GenerateSelfSignedCertificate,
    GenerateSelfSignedCertificateFailure,
    GenerateSelfSignedCertificateSuccess,
    UPLOAD_CSR
} from "./certificates.types";

export function uploadCsr(files: UploaderFile[]) {
    return {
        type: UPLOAD_CSR,
        payload: files
    };
}

export function generateSelfSignedCertificate(): GenerateSelfSignedCertificate {
    return { type: GENERATE_SELF_SIGNED_CERTIFICATE };
}

export function generateSelfSignedCertificateSuccess(): GenerateSelfSignedCertificateSuccess {
    return { type: GENERATE_SELF_SIGNED_CERTIFICATE_SUCCESS };
}

export function generateSelfSignedCertificateFailure(): GenerateSelfSignedCertificateFailure {
    return { type: GENERATE_SELF_SIGNED_CERTIFICATE_FAILURE };
}

export type CertificatesActions =
    GenerateSelfSignedCertificate
    | GenerateSelfSignedCertificateFailure
    | GenerateSelfSignedCertificateSuccess
