import React from "react";
import { InfoRowProps } from "./InfoRow.types";
import { InfoRowValue, InfoRowWrapper } from "./InfoRow.styled";

export const InfoRow: React.FC<InfoRowProps> = ({
    copy,
    value,
    withoutColons,
    withoutMargin,
    valueColor
}) => {

    return (
        <InfoRowWrapper withoutMargin={withoutMargin}>
            {copy}
            {withoutColons ? '' : ':'}
            <InfoRowValue color={valueColor}>{value}</InfoRowValue>
        </InfoRowWrapper>
    );
};
