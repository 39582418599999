import i18next from 'i18next';

import { Action } from 'models/meta/action';
import { ToastOptions } from "react-toastify";
import {
    CLEAR_STORE,
    ClearStore,
    DISMISS_ALL_BUT_UNAUTH_TOASTS,
    DISMISS_ALL_TOASTS,
    DOWNLOAD_URI,
    Navigate,
    NAVIGATE,
    NavigateOptions,
    SHOW_ERROR_TOAST,
    SHOW_INFO_TOAST,
    SHOW_SUCCESS_TOAST,
    SHOW_WARN_TOAST,
    TranslatingOptions
} from './common.types';

export const navigate = (location: string, options?: Partial<NavigateOptions>): Navigate => {
    return { type: NAVIGATE, payload: location, meta: { externalUrl: options?.externalUrl ?? false } };
};

export const clearStore = (): ClearStore => ({ type: CLEAR_STORE });
export const showSuccessToast = (title: string, desc: string): Action => ({
    type: SHOW_SUCCESS_TOAST,
    payload: {
        title,
        desc
    }
});

export const showInfoToast = (title: string, desc: string): Action => ({
    type: SHOW_INFO_TOAST,
    payload: {
        title,
        desc
    }
});

export const showWarnToast = (title: string, desc: string): Action => ({
    type: SHOW_WARN_TOAST,
    payload: {
        title,
        desc
    }
});

export const showErrorToast = (title: string, desc: string, options?: ToastOptions, translatingOptions?: TranslatingOptions): Action => ({
    type: SHOW_ERROR_TOAST,
    payload: {
        title,
        desc,
        options,
        translatingOptions: translatingOptions || { shouldTranslate: true }
    }
});

const roots = ['actionMsg', 'actionMessages'];
const actionMessage = (ns: string, actionName: string, item: string, params?: Record<string, unknown>) =>
    i18next.t(roots.map(root => `${ns}:${root}.${actionName}.${item}`), params);

export const actionSuccessToast = (ns: string = 'common', actionName: string = 'genericSuccessMessage', params?: Record<string, unknown>) =>
    showSuccessToast(actionMessage(ns, actionName, 'title', params), actionMessage(ns, actionName, 'text', params));

export const actionInfoToast = (ns: string = 'common', actionName: string = 'genericSuccessMessage', params?: Record<string, unknown>) =>
    showInfoToast(actionMessage(ns, actionName, 'title', params), actionMessage(ns, actionName, 'text', params));

export const actionWarnToast = (ns: string = 'common', actionName: string = 'genericErrorMessage', params?: Record<string, unknown>) =>
    showWarnToast(actionMessage(ns, actionName, 'title', params), actionMessage(ns, actionName, 'text', params));

export const actionErrorToast = (
    ns: string = 'common',
    actionName: string = 'genericErrorMessage',
    params?: Record<string, unknown>,
    options?: ToastOptions
) => {

    return showErrorToast(
        actionMessage(ns, actionName, 'title', params),
        actionMessage(ns, actionName, 'text', params),
        options
    );
};

export function dismissAllToasts() {
    return { type: DISMISS_ALL_TOASTS };
}

export function dismissAllButUnathToasts() {
    return { type: DISMISS_ALL_BUT_UNAUTH_TOASTS };
}

export function downloadURI(
    uri: string,
    fileName: string
) {

    return {
        type: DOWNLOAD_URI,
        payload: { uri, fileName }
    };
}
