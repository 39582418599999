import { WithTranslation } from 'react-i18next';
import yup from "utils/yup";
import { FormErrors } from 'redux-form';
import { NavigateOptions } from 'store/actions/common.types';

export const LOGIN_FORM = 'login';

const commonPath = 'common:validationMsg';
const passwordMinLength = 12;
const maxLength = 255;

const minLengthMessage: yup.TestOptionsMessage<{ min: number }> = ({ min: minCount }) => {
    return ({ key: `${commonPath}.minimumLength`, values: { minCount } });
};

export const loginSchema = {
    emailAddress: yup
        .string()
        .email(`${commonPath}.emailInvalidFormat`)
        .max(maxLength)
        .required(`${commonPath}.fieldMandatory`),

    password: yup
        .string()
        .required(`${commonPath}.fieldMandatory`)
        .matches(/\d/, `${commonPath}.passwordLackOfDigit`)
        .matches(/[a-z]/, `${commonPath}.passwordLackOfLowercase`)
        .matches(/[A-Z]/, `${commonPath}.passwordLackOfUppercase`)
        .test({
            name: "passwordRepeatedChars",
            message: `${commonPath}.passwordRepeatedChars`,
            test: (value: string) => value.match(/(.)\1\1/) === null,
        })
        .matches(/^\S.*\S$/, `${commonPath}.passwordSpaces`)
        .min(passwordMinLength, minLengthMessage),
};

export const schema = yup.object(loginSchema);

export interface RegisterFormStateProps {
    fieldsValues: Record<string, string>,
    formErrors: FormErrors,
    registerStep: string,
}

export interface RegisterFormDispatchProps {
    dispatchRegister: (emailAddress: string, password: string) => void;
    dispatchNavigate: (location: string, options?: NavigateOptions) => void;
}

export type RegisterFormProps = RegisterFormStateProps & RegisterFormDispatchProps & WithTranslation;
