import React from 'react';
import { WithT } from 'i18next';

import Styled from 'components/reusable/CommonTypography.styled';
import { NarrowContentWrap } from 'components/AppLayout/AppLayout.styled';
import TranslationWithMarkup from 'components/reusable/TranslationWithMarkup/TranslationWithMarkup.layout';
import LogoutButton from "components/reusable/Buttons/LogoutButton/LogoutButton";

const OpenBanking: React.FC<WithT> = ({ t }) => {

    return (
        <NarrowContentWrap>

            <LogoutButton />

            <Styled.PageHeading>{ t('pageHeader') }</Styled.PageHeading>

            <Styled.SectionHeading>{ t('chapters.basicInfo.header') }</Styled.SectionHeading>

            <TranslationWithMarkup translationKey="open-banking:chapters.basicInfo.text" />

            <Styled.SectionHeading>{ t('chapters.preConditions.header') }</Styled.SectionHeading>

            <TranslationWithMarkup translationKey="open-banking:chapters.preConditions.text" />

            <Styled.SectionHeading>{ t('chapters.howToStart.header') }</Styled.SectionHeading>

            <TranslationWithMarkup translationKey="open-banking:chapters.howToStart.text" />

        </NarrowContentWrap>
    );
};

export default OpenBanking;
