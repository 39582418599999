import React from 'react';
import { WithT } from 'i18next';
import CustomLanguageChanger from 'components/atoms/CustomLanguageChanger/CustomLanguageChanger';
import Styled from './Footer.styled';

function Footer({ t }: WithT) {
    const copy = ` ${t('footer.copyRight')} ${new Date().getFullYear()}`;

    return (
        <Styled.LoginFooterOuterWrap>
            <Styled.LoginFooterInnerWrap>
                <Styled.LogoAndCopy>
                    <Styled.Logo />
                    <Styled.Copyrights>
                        &copy;
                        {copy}
                    </Styled.Copyrights>
                </Styled.LogoAndCopy>

                {/* TODO to be restored */}
                {/*<Styled.LinksWrap>*/}
                {/*    <Styled.Link href="#">{t('footer.aboutOkeo')}</Styled.Link>*/}
                {/*    <Styled.Link href="#">{t('footer.policies')}</Styled.Link>*/}
                {/*    <Styled.Link href="#">{t('footer.contact')}</Styled.Link>*/}
                {/*</Styled.LinksWrap>*/}

                <CustomLanguageChanger dropdownPostition="top" />

            </Styled.LoginFooterInnerWrap>
        </Styled.LoginFooterOuterWrap>
    );
}

export default Footer;
