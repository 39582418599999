import styled from "styled-components";

export const InfoRowValue = styled.div<{ color?: string }>`
    color: ${({ theme, color }) => color || theme.colors.accent.light};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    padding-left: 6px;
`;

export const InfoRowWrapper = styled.div<{ withoutMargin?: boolean }>`
    margin-bottom: ${({ withoutMargin }) => withoutMargin ? 0 : '12px'};
    display: flex;
    align-items: center;
`;
