import { css } from "styled-components";
import { GlobalColors } from "components/AppLayout/config.types";

export const globalColors: GlobalColors = {
    backgrounds: {
        black: '#000000',
        white: '#FFFFFF',

        green: {
            lighter: '#38990d1f',
            light: '#6DDE36',
            base: '#7ABD5A',
            dark: '#4E9716'
        },

        grey: {
            light: '#FCFCFC',
            base: '#F1F1F1',
            dark: '#BBBBBB',
            darker: '#D1D1D1',
            xdark: '#8A8A8A',
        },

        yellow: {
            light: '#FFE121',
            base: '#FFC400',
            dark: '#F6BD00',
        },

        orange: {
            light: '#FFAF2C',
            base: '#FC7E1D',
            dark: '#E66604',
        },

        blue: {
            light: '#A4F0FF',
            base: '#25D5F9',
            dark: '#05A8C9',
        },

    },

    fontColors: {
        grey: {
            light: '#D1D1D1',
            base: '#BBBBBB',
            dark: '#8A8A8A',
            darker: '#7D7D7D',
            xdark: '#7F7F7F',
        },

        green: {
            light: '#51C817',
            base: '#62C831',
            dark: '#4E9716',
        },

        black: '#000000',
        white: '#FFFFFF',
        orange: '#FC7E1D'
    }
} as const;

const type = 'linear-gradient';
const deg = '169deg';
const opc1 = 0.73;
// const opc2 = 0.9;

export const okeoGradient = `${type}(
    ${deg},
    ${globalColors.backgrounds.green.light} 0%,
    rgba(73, 165, 30, ${opc1}) 100%)`;


const standardDurationTime = '0.25s';

export const standardTransition = css`
    transition: ${standardDurationTime} ease;
`;

export const standardShadow = css`
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .25);
`;

export const standardLighterShadow = css`
    box-shadow: 0 0px 6px rgba(0, 0, 0, 0.1);
`;

export const standardBorder = css`
    border: 1px solid ${globalColors.backgrounds.grey.dark};
`;

export const successColor = globalColors.backgrounds.green.light;
export const infoColor = globalColors.backgrounds.blue.dark;
export const warningColor = globalColors.backgrounds.yellow.dark;
export const errorColor = globalColors.backgrounds.orange.dark;
