import { ofType } from 'redux-observable';
import { of, EMPTY } from 'rxjs';
import { switchMapTo, switchMap, tap } from 'rxjs/operators';

import i18next from 'i18next';
import { Epic } from 'models/meta/epic';
import { endAppLoading, startAppLoading } from 'store/actions/app';
import { SET_LANGUAGE, SET_SCROLL_POSITION } from 'store/actions/app.types';

// TODO

export const onLoadingBegin: Epic = action$ =>
    action$.pipe(
        ofType(
            /* SIGN_ON  */ // handled on the form itself
            /* VERIFY_CODE, */ // handled on the form itself
        ),
        switchMapTo(of(startAppLoading()))
    );

export const onLoadingEnd: Epic = action$ =>
    action$.pipe(
        ofType(
            /* SIGN_ON_SUCCESS, SIGN_ON_FAILURE  */ // handled on the form itself
            /* VERIFY_CODE_SUCCESS, VERIFY_CODE_FAILURE, */ // handled on the form itself
        ),
        switchMapTo(of(endAppLoading()))
    );

export const onSetScrollPosition: Epic = action$ =>
    action$.pipe(
        ofType(SET_SCROLL_POSITION),
        tap(({ payload }) => {
            const options = {
                top: payload.top || 0,
                left: payload.left || 0,
            };

            const element = payload.ref?.current;

            if (element) {
                element.scrollTo(options);

            } else {
                document.body.scrollTo(options);
            }
        }),
        switchMap(() => EMPTY)
    );

export const onSetLanguage: Epic = action$ =>
    action$.pipe(
        ofType(SET_LANGUAGE),
        tap(({ payload: language }) => {
            i18next.changeLanguage(language);
        }),
        switchMap(() => EMPTY)
    );
