import React from 'react';
import Icons from 'assets/styles/icons/icons';

import { LoggedInAsProps } from './LoggedInAs.types';
import Styled from './LoggedInAs.styled';

const LoggedInAs: React.FC<LoggedInAsProps> = ({ t, userName }) => {
    return (
        <Styled.LoggedInAsWrap>
            <Styled.ContentWrap>
                <Styled.LoggedInAsLabel>
                    <Styled.LoggedInAsIcon icon={Icons.person} />
                    {t('leftSideBar.user.loggedInAs')}
                </Styled.LoggedInAsLabel>

                <Styled.LoggedInAs>{userName}</Styled.LoggedInAs>
            </Styled.ContentWrap>
        </Styled.LoggedInAsWrap>

    );
};

export default LoggedInAs;
