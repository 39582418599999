import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { getFormAsyncErrors, reduxForm } from 'redux-form';

import { State } from 'models/app/store';
import { Dispatch } from 'models/meta/action';
import { navigate } from 'store/actions/common';

import { register } from 'store/actions/user';
import { createAsyncValidate, shouldAsyncValidate } from "utils/form";

import {
    LOGIN_FORM,
    RegisterFormDispatchProps,
    RegisterFormProps,
    RegisterFormStateProps,
    schema,
} from './RegisterForm.types';
import { RegisterForm } from './RegisterForm.layout';

const mapStateToProps = (state: State): RegisterFormStateProps => ({
    fieldsValues: state.form?.[LOGIN_FORM]?.values || {},
    formErrors: getFormAsyncErrors(LOGIN_FORM)(state) || {},
    registerStep: state.user.registerStep
});

export const mapDispatchToProps = (dispatch: Dispatch): RegisterFormDispatchProps => ({
    dispatchRegister: (emailAddress, password) => dispatch(register({ emailAddress, password })),
    dispatchNavigate: (location, options) => dispatch(navigate(location, options))
});

const decorate = compose<React.FC<Partial<RegisterFormProps>>>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withTranslation('common'),
    reduxForm({
        form: LOGIN_FORM,
        initialValues: {
            emailAddress: '',
            password: '',
        },
        asyncValidate: createAsyncValidate(schema),
        shouldAsyncValidate
    })
);

export default decorate(RegisterForm);
