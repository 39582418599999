import React from "react";
import { OnboardingApplicationReviewStatus } from "./OnboardingApplicationReviewStatus.layout";
import { OnboardingApplicationReviewCompanyDetails } from "./OnboardingApplicationReviewCompanyDetails.layout";
import { OnboardingApplicationReviewRepresentativeDetails } from "./OnboardingApplicationReviewRepresentativeDetails.layout";
import { OnboardingApplicationReviewPSD2Details } from "./OnboardingApplicationReviewPSD2Details.layout";
import { OnboardingApplicationReviewProps } from "./OnboardingApplicationReview.types";
import { OnboardingApplicationReviewActionables } from "./OnboardingApplicationReviewActionables.layout";
import { OnboardingApplicationReviewDivider } from "./OnboardingApplicationReview.styled";

export const OnboardingApplicationReview: React.FC<OnboardingApplicationReviewProps> = ({
    tppDetails,
    countriesList,
    t,
    i18n,
    dispatchDestroyOnboardingForm,
    dispatchSetOnboardingStep
}) => (
    <div>

        <OnboardingApplicationReviewStatus
            language={i18n.language}
            tppDetails={tppDetails}
            t={t}
        />

        <OnboardingApplicationReviewDivider />

        <OnboardingApplicationReviewCompanyDetails
            tppDetails={tppDetails}
            countriesList={countriesList}
            t={t}
        />

        <OnboardingApplicationReviewDivider />

        <OnboardingApplicationReviewRepresentativeDetails
            tppDetails={tppDetails}
            t={t}
        />

        <OnboardingApplicationReviewDivider />

        <OnboardingApplicationReviewPSD2Details
            tppDetails={tppDetails}
            t={t}
        />

        <OnboardingApplicationReviewActionables
            t={t}
            dispatchDestroyOnboardingForm={dispatchDestroyOnboardingForm}
            dispatchSetOnboardingStep={dispatchSetOnboardingStep}
            applicationStatus={tppDetails?.application?.applicationStatus}
        />

    </div>
);
