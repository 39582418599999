import React from 'react';
import { WithTranslation } from 'react-i18next';

import { NarrowContentWrap } from 'components/AppLayout/AppLayout.styled';
import Styled from 'components/reusable/CommonTypography.styled';
import TranslationWithMarkup from 'components/reusable/TranslationWithMarkup/TranslationWithMarkup.layout';
import LogoutButton from "components/reusable/Buttons/LogoutButton/LogoutButton";

const GettingStarted:React.FC<WithTranslation> = ({ t }) => {

    return (
        <NarrowContentWrap>

            <LogoutButton />

            <Styled.PageHeading>{ t('pageHeader') }</Styled.PageHeading>

            <Styled.SectionHeading>{ t('chapters.introduction.header') }</Styled.SectionHeading>

            <TranslationWithMarkup translationKey="getting-started:chapters.introduction.text" />

            <Styled.SectionHeading>{ t('chapters.devPortal.header') }</Styled.SectionHeading>

            <TranslationWithMarkup translationKey="getting-started:chapters.devPortal.text" />

            <Styled.SectionHeading>{ t('chapters.openBanking.header') }</Styled.SectionHeading>

            <TranslationWithMarkup translationKey="getting-started:chapters.openBanking.text" />

        </NarrowContentWrap>
    );
};

export default GettingStarted;
