import React from 'react';
import logo from 'assets/img/logo.svg';
import { defaultRoutePath } from 'config/config';

import Styled from './AppLogo.styled';
import { AppLogoProps } from './AppLogo.types';

const AppLogo: React.FC<AppLogoProps> = ({ size, greyOut, linksToDefaultPath }) => {

    return linksToDefaultPath && !greyOut
        ? (
            <Styled.AppLogoLinkWrap to={defaultRoutePath}>
                <Styled.AppLogo src={logo} alt="OKEO logo" size={size} />
            </Styled.AppLogoLinkWrap>

        )
        : (<Styled.AppLogo src={logo} alt="OKEO logo" size={size} />);
};

export default AppLogo;
