import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import theme from 'config/theme';
import { IconFont } from 'assets/styles/icons/Icons.styled';
import { ColorTransition } from 'assets/styles/animations/animations.styled';
import { globalColors } from 'components/AppLayout/config';

const SideBarLinkStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  margin-bottom: 10px;
  color: ${globalColors.fontColors.grey.dark};
  text-decoration: none;
  font-weight: ${theme.fontWeights.semiBold};
  ${ColorTransition};

  &:hover,
  &.active,
  &.focus {
    color: ${globalColors.fontColors.green.base};
    outline: none;
    cursor: pointer;
  }
`;

const SideBarLink = styled(NavLink)`
  ${SideBarLinkStyles};
`;

const SideBarExternalLink = styled.a`
  ${SideBarLinkStyles};
`;

const SideBarLinkIcon = styled(IconFont)`
  margin-right: 12px;
  font-size: 15px;
  ${ColorTransition};
`;

export default { SideBarLink, SideBarExternalLink, SideBarLinkIcon };
