import styled from 'styled-components';

import { WithTheme } from 'config/theme';


const Introduction = styled.div`
    color: ${({ theme }: WithTheme) => theme.colors.font.onPrimaryLighter};
`;

export default { Introduction };
