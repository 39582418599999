import { Action } from 'models/meta/action';
import { RegisterStep, ResetPasswordStep } from 'models/app/workflow';
import { User, LoginRequest, RegisterRequest, ResetPasswordRequest } from 'models/domain/user';
import { ClearStore } from './common.types';

type LOGIN = 'LOGIN';
type LOGIN_SUCCESS = 'LOGIN_SUCCESS';
type LOGIN_FAILURE = 'LOGIN_FAILURE';
type SET_LOGIN_STEP = 'SET_LOGIN_STEP';

type REGISTER = 'REGISTER';
type REGISTER_SUCCESS = 'REGISTER_SUCCESS';
type REGISTER_FAILURE = 'REGISTER_FAILURE';
type SET_REGISTER_STEP = 'SET_REGISTER_STEP';

type RESET_PASSWORD = 'RESET_PASSWORD';
type RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
type RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
type SET_RESET_PASSWORD_STEP = 'SET_RESET_PASSWORD_STEP';

type LOGOUT = 'LOGOUT';

type REMOVE_TOKEN = 'REMOVE_TOKEN';

export const LOGIN: LOGIN = 'LOGIN';
export const LOGIN_SUCCESS: LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE: LOGIN_FAILURE = 'LOGIN_FAILURE';
export const SET_LOGIN_STEP: SET_LOGIN_STEP = 'SET_LOGIN_STEP';

export const REGISTER: REGISTER = 'REGISTER';
export const REGISTER_SUCCESS: REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE: REGISTER_FAILURE = 'REGISTER_FAILURE';
export const SET_REGISTER_STEP: SET_REGISTER_STEP = 'SET_REGISTER_STEP';

export const RESET_PASSWORD: RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS: RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE: RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const SET_RESET_PASSWORD_STEP: SET_RESET_PASSWORD_STEP = 'SET_RESET_PASSWORD_STEP';

export const LOGOUT: LOGOUT = 'LOGOUT';

export const REMOVE_TOKEN: REMOVE_TOKEN = 'REMOVE_TOKEN';

export interface Login extends Action {
    type: LOGIN;
    payload: LoginRequest;
}

export interface LoginSuccess extends Action {
    type: LOGIN_SUCCESS;
    payload: User;
}

export interface LoginFailure extends Action {
    type: LOGIN_FAILURE;
}

export interface Logout extends Action {
    type: LOGOUT;
}

export interface Register extends Action {
    type: REGISTER;
    payload: RegisterRequest;
}

export interface RegisterSuccess extends Action {
    type: REGISTER_SUCCESS;
}

export interface RegisterFailure extends Action {
    type: REGISTER_FAILURE;
}

export interface ResetPassword extends Action {
    type: RESET_PASSWORD;
    payload: ResetPasswordRequest;
}

export interface ResetPasswordSuccess extends Action {
    type: RESET_PASSWORD_SUCCESS;
}

export interface ResetPasswordFailure extends Action {
    type: RESET_PASSWORD_FAILURE;
}

export interface RemoveToken extends Action {
    type: REMOVE_TOKEN;
}

export interface SetLoginStep {
    type: SET_LOGIN_STEP;
    payload: string;
}

export interface SetRegisterStep {
    type: SET_REGISTER_STEP;
    payload: RegisterStep;
}

export interface SetResetPasswordStep {
    type: SET_RESET_PASSWORD_STEP;
    payload: ResetPasswordStep;
}

type LoginActions = Login | LoginSuccess | LoginFailure | Logout | SetLoginStep;
type RegisterActions = Register | RegisterSuccess | RegisterFailure | SetRegisterStep;
type ResetPasswordActions = ResetPassword | ResetPasswordSuccess | ResetPasswordFailure | SetResetPasswordStep;

type TokenActions = RemoveToken;

export type TppActions = LoginActions | RegisterActions | ResetPasswordActions | TokenActions | ClearStore;
