import styled from 'styled-components';
import { BgColorTransition } from 'assets/styles/animations/animations.styled';
import { IconFont } from 'assets/styles/icons/Icons.styled';
import { ButtonBaseWithVariants } from '../BaseButton';

const CircleButton = styled(ButtonBaseWithVariants)`
    position: fixed;
    z-index: 10;
    top: 53px;
    right: 45px;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    line-height: 1;
    ${BgColorTransition};
    font-size: 13px;
    cursor: pointer !important;
`;

const Icon = styled(IconFont)`
    font-size: 10px;
`;

export default {
    CircleButton,
    Icon
};
