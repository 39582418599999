import styled, { css, StyledComponent } from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';

import theme, { WithTheme } from 'config/theme';
import { ColorTransition } from 'assets/styles/animations/animations.styled';
import { space, SpaceProps } from "styled-system";
import { globalColors } from 'components/AppLayout/config';

export const PageHeading = styled.h1<SpaceProps>`
    margin-bottom: 75px;
    font-size: 26px;
    line-height: 36px;
    font-weight: ${theme.fontWeights.semiBold};
    
    ${space};
`;

export const SectionHeading = styled.h2`
    margin-top: 50px; 
    margin-bottom: 30px;
    font-size: 22px;
    line-height: 30px;
    font-weight: ${theme.fontWeights.semiBold};
`;

export const Paragraph = styled.p`
    margin: 0;
    padding-bottom: 15px;
    font-size: 16px;
    line-height: 22px;
    font-weight: ${theme.fontWeights.normal};
`;

export const GrayParagraph = styled<StyledComponent<React.FC<SpaceProps>, {}>>(Paragraph)`
    color: ${({ theme }: WithTheme) => theme.colors.gray.darkest};
    
    ${space};
`;

export const Emphasis = styled.em`
    font-weight: ${theme.fontWeights.bold};
    font-style: normal;
`;

export const Underline = styled.u`
    text-decoration: underline;
`;

export const Quotation = styled.q`
    margin: 0;
    font-style: italic;
`;

const hyperlinkStyles = css`
    color: ${globalColors.fontColors.green.base};
    font-weight: ${theme.fontWeights.bold};
    ${ColorTransition};
    cursor: pointer;
    text-decoration: none;
    
    &:hover,
    &:active {
      color: ${theme.colors.accent.dark};
    }
`;

export const Anchor = styled.a`
 ${hyperlinkStyles};
`;

export const InternalLink = styled<React.FC<Partial<LinkProps>>>(Link)`
 ${hyperlinkStyles};
`;

export const UnorderedList = styled.ul`
    margin: 0;
    padding: 0 0 15px 20px;
    list-style: disc;
`;

export const OrderedList = styled.ol`
    margin: 0;
    padding: 0 0 15px 20px;
    list-style: decimal;
`;

export const ListItem = styled.li`
    margin-bottom: 3px;
    font-size: 16px;
    line-height: 22px;
    font-weight: ${theme.fontWeights.normal};
    list-style: inherit;
`;

export const divider = css`
    border: none;
    border-top: ${({ theme }: WithTheme) => `2px solid ${theme.colors.gray.dark}`};
`;

export const Divider = styled.hr`
    margin-top: 50px;
    margin-bottom: 30px;

    ${divider}
`;

export default {
    PageHeading,
    Divider,
    SectionHeading,
    Paragraph,
    Underline,
    Emphasis,
    Quotation,
    Anchor,
    InternalLink,
    UnorderedList,
    OrderedList,
    ListItem
};
