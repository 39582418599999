export enum OperationResultClass {
    INFO = 'INFO',
    SUCCESS = 'SUCCESS',
    WARN = 'WARN',
    DANGER = 'DANGER',
    ERROR = 'ERROR',
}

export interface GlobalColors {
    backgrounds: Backgrounds;
    fontColors: FontColors;
}

export interface FontColors {
    grey: ColorBrightnessExtended;
    black: string;
    white: string;
    orange: string;
    green: ColorBrightness;
}

export interface Backgrounds {
    black: string;
    white: string;
    yellow: ColorBrightness;
    orange: ColorBrightness;
    blue: ColorBrightness;
    green: ColorBrightness;
    grey: ColorBrightnessExtended;
}

export interface ColorBrightness {
    lighter?: string;
    light: string;
    base: string;
    dark: string;
}

export interface ColorBrightnessExtended {
    base: string;
    light: string;
    dark: string;
    darker: string;
    xdark: string;
}
