import { LoginRequest, RegisterRequest, ResetPasswordRequest, User } from 'models/domain/user';
import { tppManagement } from 'services/api/tppManagement';

class TppManagementService {
    static register(credentials: LoginRequest): Promise<User> {
        return tppManagement.register(credentials);
    }

    static login(credentials: RegisterRequest): Promise<User> {
        return tppManagement.login(credentials);
    }

    static sendPasswordResetEmail(payload: ResetPasswordRequest): Promise<void> {
        return tppManagement.sendPasswordResetEmail(payload);
    }
}

export default TppManagementService;
