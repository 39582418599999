import { connect } from 'react-redux';
import { Dispatch } from 'models/meta/action';
import { navigate } from 'store/actions/common';
import { LoginOrRegister } from './LoginOrRegister.layout';
import { LoginOrRegisterDispatchProps } from './LoginOrRegister.types';

const mapDispatchToProps = (dispatch: Dispatch): LoginOrRegisterDispatchProps =>
    ({ dispatchNavigate: (location, options) => { dispatch(navigate(location, options)); } });

export default connect(null, mapDispatchToProps)(LoginOrRegister);
