import styled from 'styled-components';
import { IconFont } from 'assets/styles/icons/Icons.styled';
import { globalColors, okeoGradient, standardLighterShadow } from 'components/AppLayout/config';


const ReportTile = styled.button`
    width: 200px;
    height: 120px;
    padding: 20px 15px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    ${standardLighterShadow};
    background-color: ${globalColors.backgrounds.grey.light};
    color: ${globalColors.fontColors.green.dark};

    &:hover {
        background: ${okeoGradient};
        color: ${globalColors.fontColors.white};
    }
`;

const ReportTileLabel = styled.span`
    text-align: center;
    font-size: 14px;
    line-height: 19px;
`;

const ReportTileIcon = styled(IconFont)`
    font-size: 30px;
    transition: unset;
`;

export default { ReportTile, ReportTileLabel, ReportTileIcon };
