import React from 'react';
import Icons from 'assets/styles/icons/icons';
import Tooltip from "components/reusable/Tooltip/Tooltip.layout";

import Styled from './CircleButton.styled';
import { CircleButtonProps } from './CircleButton.types';

const CircleButton: React.FC<CircleButtonProps> = ({ onClick, iconName, iconTooltip }) => {

    return (
        <>
            <Styled.CircleButton
                variant="light"
                type="button"
                onClick={() => onClick()}
                data-tip={iconTooltip}
                data-test-id={iconName}
                data-for="left-tooltip"
            >
                <Styled.Icon icon={Icons[iconName]} />
            </Styled.CircleButton>

            <Tooltip place="left" id="left-tooltip" />
        </>
    );
};

export default CircleButton;
