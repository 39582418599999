import styled from 'styled-components';
import { globalColors } from 'components/AppLayout/config';
import { docNavBarHeight } from 'assets/styles/global/layout.styled';


const DocumentationWrap = styled.div`
    /* spinner override */
    & > div:not(.redoc-wrap) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      padding-top: ${docNavBarHeight}px;
      transition: all 0.2s ease-out;
      
      & > div {
        display: none;
      }
      

      & > svg {
          width: 200px;
          height: 200px;
      
          path {
             fill:  ${globalColors.fontColors.green.base};
          }
      }
    }
   
    
    .menu-content {
      padding: 25px 0;
      
      /* menu 2nd level item color fix*/
      li > label:hover > span:nth-of-type(2) {
          color: ${globalColors.fontColors.green.base};
      }
    }
    
    .api-content {
      padding-top: 100px;
    }

    /* hide powered by link */
    a[href='https://github.com/Redocly/redoc'] {
      display: none;
    }

`;

export default { DocumentationWrap };
