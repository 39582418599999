import { GetTppDetailsResponse, TppApplication, TppId, TppStatus } from "models/domain/tpp";
import { HttpService } from '../HttpService';

const onboarding = {
    getTppStatus: (): Promise<TppStatus | null> => {
        return HttpService.get('/api/tppmanagement/v1/tpp/status', { noErrorHandling: true });
    },

    getTppDetails: (tppId: TppId): Promise<GetTppDetailsResponse> => {
        return HttpService.get(`/api/tppmanagement/v1/tpp/${tppId}`);
    },

    submitTppApplication: (tppApplication: TppApplication): Promise<string> => {
        return HttpService.post('/api/tppmanagement/v1/application', tppApplication, { noErrorHandling: true });
    },

    updateTppApplication: (tppId: TppId, tppApplication: TppApplication): Promise<string> => {
        return HttpService.put(`/api/tppmanagement/v1/application/${tppId}`, tppApplication, { noErrorHandling: true });
    }
};

export { onboarding };
