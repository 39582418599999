import { State } from 'models/app/store';
import createWithLoading from 'hocs/loading/createWithLoading';
import LoadingOverlaySection from 'components/reusable/Overalys/SectionLoadingOverlay.layout';
import { AuthenticationPanelProps } from './AuthenticationPanel.types';
import AuthenticationPanel from './AuthenticationPanel.layout';

const loadingSelector = (state: State) => state.user.isLoading;

export default createWithLoading<AuthenticationPanelProps>(AuthenticationPanel, {
    loadingSelector,
    OverlayComponent: LoadingOverlaySection,
    positionLock: true
});
