import { ApiKeyState } from 'models/app/store';

export interface ApiKeyHolderProps {
    placeholder: string;
    apiKey: ApiKeyState | undefined;
    isLoading?: boolean;
    onIconClick?: () => void;
    onBlur?: () => void;
}

export enum ApiKeyTileMode {
    LOADING, PLACEHOLDER, REVEALED, CONCEALED
}

export interface ApiKeyTileProps {
    mode: ApiKeyTileMode;
}

export function createApiKeyMode(apiKey: ApiKeyState | undefined): ApiKeyTileMode {
    if (!apiKey?.apiKeyId) {
        return ApiKeyTileMode.PLACEHOLDER;

    } else if (apiKey.apiKeyValue) {
        return ApiKeyTileMode.REVEALED;

    } else {
        return ApiKeyTileMode.CONCEALED;
    }
}
