import * as yup from 'yup';
import { setLocale } from 'yup';

setLocale({
    string: {
        min: ({ min }) => ({ key: 'common:validationMsg.minimumLength', values: { minLength: min } }),
        max: ({ max }) => ({ key: 'common:validationMsg.maximumLength', values: { maxLength: max } }),
    },
});

export default yup;
