import * as React from "react";
import { FunctionComponent } from "react";
import { SpinnerPaletteRingProps } from './SpinnerPaletteRing.types';

const SpinnerPaletteRing: FunctionComponent<SpinnerPaletteRingProps> = ({
    strokeWidth = 10,
    width = 200,
    height = 200,
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        display="block"
    >
        <g transform="rotate(358.521 50 50)">
            <path
                d="M80 50a30 30 0 01-30 30"
                fill="none"
                stroke="#457221"
                strokeWidth={strokeWidth}
            />
            <path
                d="M50 80a30 30 0 01-30-30"
                fill="none"
                stroke="#659f39"
                strokeWidth={strokeWidth}
            />
            <path
                d="M20 50a30 30 0 0130-30"
                fill="none"
                stroke="#7fbf4f"
                strokeWidth={strokeWidth}
            />
            <path
                d="M50 20a30 30 0 0130 30"
                fill="none"
                stroke="#f9f9f9"
                strokeWidth={strokeWidth}
            />
            <animateTransform
                attributeName="transform"
                type="rotate"
                values="0 50 50;360 50 50"
                dur="1s"
                repeatCount="indefinite"
            />
        </g>
    </svg>
);

export default SpinnerPaletteRing;
