import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Epic } from 'models/meta/epic';
import { dismissAllToasts, downloadURI } from "store/actions/common";
import { setFileUploaderFiles } from "store/actions/fileUploader";
import { CSR_FILE_UPLOADER_NAME } from "store/actions/fileUploader.types";
import { GENERATE_SELF_SIGNED_CERTIFICATE, UPLOAD_CSR } from "store/actions/certificates.types";
import { generateSelfSignedCertificateFailure, generateSelfSignedCertificateSuccess } from "store/actions/certificates";
import { ApiError } from "models/meta/api";
import { handleCertificateErrors, handleUploadCsrErrors } from "./certificatesEpic.helpers";

export const onUploadCSR: Epic = (action$, state$, { certificates }) =>
    action$.pipe(
        ofType(UPLOAD_CSR),
        switchMap((action) => {

            return (
                from(
                    certificates.uploadCSR(
                        action.payload,
                        state$.value.onboarding.tppStatus?.tppId || ''
                    )
                ).pipe(
                    switchMap(() => {

                        return of(
                            dismissAllToasts(),
                            setFileUploaderFiles(CSR_FILE_UPLOADER_NAME, action.payload)
                        );

                    }),
                    catchError((error: ApiError) => {

                        return of(
                            ...handleCertificateErrors(error, handleUploadCsrErrors),
                            setFileUploaderFiles(CSR_FILE_UPLOADER_NAME, [])
                        );
                    })
                ));
        })
    );

export const onGenerateSelfSignedCertificate: Epic = (action$, state$, { certificates, document }) =>
    action$.pipe(
        ofType(GENERATE_SELF_SIGNED_CERTIFICATE),
        switchMap(() => {

            const tppId = state$.value.onboarding.tppStatus?.tppId;

            return (
                from(
                    certificates.generateSelfSignedCertificate(tppId || '')
                ).pipe(
                    switchMap((fileContent: string) => {

                        return of(
                            downloadURI(
                                `data:application/octet-stream,${encodeURIComponent(fileContent)}`,
                                'okeo-open-banking-sandbox-certificate.pem'
                            ),
                            generateSelfSignedCertificateSuccess()
                        );
                    }),
                    catchError((error) => {

                        return of(
                            ...handleCertificateErrors(error),
                            generateSelfSignedCertificateFailure()
                        );
                    })
                ));
        })
    );

export default [
    onGenerateSelfSignedCertificate,
    onUploadCSR,
];
