import styled from 'styled-components';
import { globalColors } from 'components/AppLayout/config';

const AuthenticationPageOuterWrap = styled.div`
    max-width: 1180px;
    width: 100%;
    background-color: ${globalColors.backgrounds.white};
`;

const AuthenticationPageInnerWrap = styled.div`
    width: 488px;
    margin: 0 auto;
`;

const AuthenticationPageLogoWrap = styled.div`
    text-align: center;
    margin-bottom: 60px;
`;

export default {
    AuthenticationPageOuterWrap,
    AuthenticationPageInnerWrap,
    AuthenticationPageLogoWrap,
};
