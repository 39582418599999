import { combineEpics } from 'redux-observable';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { developmentMode } from 'config/config';
import { Epic } from 'models/meta/epic';

import {
    onDismissAllToasts,
    onDismissAllButUnauthToasts,
    onNavigationRequested,
    onShowErrorToast,
    onShowInfoToast,
    onShowSuccessToast,
    onShowWarnToast,
    onDownloadURI
} from './commonEpic';

import {
    onLogin,
    onLoginSuccess,
    onLoginSuccessRedirect,
    onLogout,
    onNavigateToLogin,
    onRegister,
    onRegisterSuccess,
    onResetPassword,
    onResetPasswordSuccess,
    onRemoveToken
} from './userEpic';

import { onLoadingBegin, onLoadingEnd, onSetLanguage, onSetScrollPosition } from './appEpic';
import { onGetCountriesInfo } from './dictionaryEpic';

import onboardingEpics from './onboardingEpic';
import certificatesEpic from "./certificatesEpic";
import apiKeysEpics from './apiKeysEpic';

const commonEpics: Epic[] = [
    onShowSuccessToast,
    onShowInfoToast,
    onShowWarnToast,
    onShowErrorToast,
    onNavigationRequested,
    onDismissAllToasts,
    onDismissAllButUnauthToasts,
    onDownloadURI
];

const userEpics: Epic[] = [
    onLogout,
    onLogin,
    onLoginSuccess,
    onLoginSuccessRedirect,
    onRegister,
    onRegisterSuccess,
    onNavigateToLogin,
    onResetPassword,
    onResetPasswordSuccess,
    onRemoveToken
];

const appEpics: Epic[] = [
    onLoadingBegin,
    onLoadingEnd,
    onSetScrollPosition,
    onSetLanguage
];

const dictionaryEpics: Epic[] = [
    onGetCountriesInfo
];

const epics: Epic[] = [
    ...commonEpics,
    ...appEpics,
    ...userEpics,
    ...onboardingEpics,
    ...certificatesEpic,
    ...apiKeysEpics,
    ...dictionaryEpics
];

export const areEpicsDoubled = Array.from(new Set(epics)).length !== epics.length;

if (developmentMode && areEpicsDoubled) {
    throw Error("Doubled epics!");
}

const onAnyEpic: Epic = combineEpics(...epics);

function errorHandler<T>(error: Error, source: Observable<T>): Observable<T> {
    if (developmentMode) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    return source;
}

const onEpic: Epic = (action$, store$, dependencies) => onAnyEpic(action$, store$, dependencies).pipe(catchError(errorHandler));

export default onEpic;
