import { TppId } from "models/domain/tpp";
import { HttpService } from "../HttpService";

const certificates = {

    uploadCSR: (fileContent: string, tppId: TppId) => {

        return HttpService.post(
            `/api/tppmanagement/v1/certificate/csr/${tppId}`,
            fileContent,
            { headers: { 'Content-Type': 'application/octet-stream' }, noErrorHandling: true }
        );
    },

    generateSelfSignedCertificate: (tppId: TppId) => {

        return HttpService.post(
            `/api/tppmanagement/v1/certificate/selfSigned`,
            null,
            {
                params: { tppId },
                noErrorHandling: true
            }
        );
    }
};

export { certificates };
