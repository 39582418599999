import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { duration } from 'assets/styles/animations/transitions.styled';
import { StandardTransitionProps } from './StandardTransition.types';

const StandardTransition: React.FC<StandardTransitionProps> = ({ inProp, children }): JSX.Element => {

    return (
        <CSSTransition
            in={inProp}
            appear
            mountOnEnter
            unmountOnExit
            classNames="fade"
            timeout={duration}
        >
            {children}
        </CSSTransition>
    );
};

export default StandardTransition;
