import React from 'react';
import { Field } from 'redux-form';
import { OkeoButton } from 'components/atoms/OkeoButton/OkeoButton.styled';
import { OnboardingFormRepresentativeDetailsProps } from "./OnboardingFormRepresentativeDetails.types";
import { FormContentWrapper } from "./OnboardingFormCompanyDetails.styled";
import { SpaceBetween } from "./OnboardingFormRepresentativeDetails.styled";
import { OnboardingField } from "./OnboardingForm.helpers";

export const OnboardingFormRepresentativeDetails: React.FC<OnboardingFormRepresentativeDetailsProps> = props => {

    const { handleSubmit, untouchFormFields, t, onPreviousButtonClick } = props;

    return (
        <form onSubmit={(evt) => {
            (handleSubmit(evt) as unknown as { catch: Function }).catch?.(() => null);
        }}
        >

            <Field
                name="representativeFirstName"
                label={t('onboarding:preSubmission.representativeDetailsStep.fields.representativeFirstName.label')}
                outsideTooltip={t('onboarding:preSubmission.representativeDetailsStep.fields.representativeFirstName.tooltip')}
                component={OnboardingField}
                type="text"
                id="representativeFirstName"
                t={t}
            />

            <Field
                name="representativeLastName"
                label={t('onboarding:preSubmission.representativeDetailsStep.fields.representativeLastName.label')}
                outsideTooltip={t('onboarding:preSubmission.representativeDetailsStep.fields.representativeLastName.tooltip')}
                component={OnboardingField}
                type="text"
                id="representativeLastName"
                t={t}
            />

            <Field
                name="representativePosition"
                label={t('onboarding:preSubmission.representativeDetailsStep.fields.representativePosition.label')}
                outsideTooltip={t('onboarding:preSubmission.representativeDetailsStep.fields.representativePosition.tooltip')}
                component={OnboardingField}
                type="text"
                id="representativePosition"
                t={t}
            />

            <FormContentWrapper>
                <SpaceBetween>

                    <OkeoButton
                        onClick={onPreviousButtonClick}
                        variant="light"
                        type="button"
                    >
                        {t('onboarding:postSubmission.buttons.previousStep.text')}
                    </OkeoButton>

                    <OkeoButton
                        onClick={untouchFormFields}
                        type="submit"
                    >
                        {t('onboarding:postSubmission.buttons.nextStep.text')}
                    </OkeoButton>

                </SpaceBetween>
            </FormContentWrapper>

        </form>
    );
};
