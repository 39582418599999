import { Action } from "models/meta/action";
import { DropDepth, FileUploaderError, FileUploaderId } from "../reducers/fileUploaderReducer.types";

export const SET_FILE_UPLOADER_BEING_DRAGGED_OVER_STATE = 'SET_FILE_UPLOADER_BEING_DRAGGED_OVER_STATE';
export const SET_FILE_UPLOADER_DROP_DEPTH = 'SET_FILE_UPLOADER_DROP_DEPTH';
export const SET_FILE_UPLOADER_FILES = 'SET_FILE_UPLOADER_FILES';
export const SET_FILE_UPLOADER_ERRORS = 'SET_FILE_UPLOADER_ERRORS';
export const START_FILE_UPLOADER_ASYNC_VALIDATION = 'START_FILE_UPLOADER_ASYNC_VALIDATION';
export const STOP_FILE_UPLOADER_ASYNC_VALIDATION = 'STOP_FILE_UPLOADER_ASYNC_VALIDATION';

export const ONBOARDING_FILE_UPLOADER_NAME = 'ONBOARDING_FILE_UPLOADER_NAME';
export const CSR_FILE_UPLOADER_NAME = 'CSR_FILE_UPLOADER_NAME';

export interface SetFileUploaderBeingDraggedOverState extends Action {
    type: typeof SET_FILE_UPLOADER_BEING_DRAGGED_OVER_STATE,
    payload: { id: FileUploaderId, isBeingDraggedOver: boolean }
}

export interface SetFileUploaderDropDepth extends Action {
    type: typeof SET_FILE_UPLOADER_DROP_DEPTH,
    payload: { id: FileUploaderId, dropDepth: DropDepth }
}

export interface SetFileUploaderFiles extends Action {
    type: typeof SET_FILE_UPLOADER_FILES,
    payload: { id: FileUploaderId, files: File[] }
}

export interface SetFileUploaderErrors extends Action {
    type: typeof SET_FILE_UPLOADER_ERRORS,
    payload: { id: FileUploaderId, errors: FileUploaderError[] }
}

export interface StartFileUploaderAsyncValidation extends Action {
    type: typeof START_FILE_UPLOADER_ASYNC_VALIDATION,
    payload: FileUploaderId
}

export interface StopFileUploaderAsyncValidation extends Action {
    type: typeof STOP_FILE_UPLOADER_ASYNC_VALIDATION,
    payload: FileUploaderId
}

export type FileUploaderActions = SetFileUploaderBeingDraggedOverState
    | SetFileUploaderDropDepth
    | SetFileUploaderFiles
    | SetFileUploaderErrors
    | StartFileUploaderAsyncValidation
    | StopFileUploaderAsyncValidation
