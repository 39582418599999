import React, { FunctionComponent, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import Tooltip from 'components/reusable/Tooltip/Tooltip.layout';
import AppToastContainer from 'components/reusable/Toast/AppToastContainer/AppToastContainer.layout';
import { AppStyles } from 'assets/styles/global/global.styled';

import SideBar from './SideBar/SideBar';
import Styled from './AppLayout.styled';
import Footer from './Footer/Footer';
import NavigationBar from './NavigationBarDecoration/NavigationBarDecoration.layout';
import { AppLayoutProps } from './AppLayout.types';

const AppLayout: FunctionComponent<AppLayoutProps> = (
    {
        children,
        shouldShowPostApprovalItems,
        isAuthenticated,
        location,
        dispatchSetScrollPosition
    }
) => {

    const { pathname } = location;
    const contentWrapRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatchSetScrollPosition({ ref: contentWrapRef });
    }, [pathname]);

    return (
        <Styled.PageWrap>
            <NavigationBar />

            <Styled.ContentWrap ref={contentWrapRef}>
                <SideBar isAuthenticated={isAuthenticated} />

                <Styled.RouteTransitionGroup>
                    <CSSTransition
                        key={location.key}
                        classNames="fade"
                        timeout={200}
                    >
                        <Styled.MainContentWrap>
                            {children}

                            <Tooltip />

                            <AppToastContainer />

                        </Styled.MainContentWrap>
                    </CSSTransition>
                </Styled.RouteTransitionGroup>
            </Styled.ContentWrap>

            <Footer />

            <AppStyles />
        </Styled.PageWrap>
    );
};

export default AppLayout;
