import { WithTranslation } from 'react-i18next';
import yup from "utils/yup";
import { FormErrors } from 'redux-form';
import { ResetPasswordRequest } from 'models/domain/user';

export const RESET_FORM = 'reset-password';

const maxLength = 255;

export const resetPasswordSchema = {
    emailAddress:
        yup.string()
            .email('common:validationMsg.validEmail')
            .max(maxLength)
            .required(`common:validationMsg.fieldMandatory`),

};

export const schema = yup.object(resetPasswordSchema);

export interface ResetPasswordFormStateProps {
    fieldsValues: Record<string, string>,
    formErrors: FormErrors,
    loginStep: string,
    language: string,
}

export interface ResetPasswordFormDispatchProps {
    dispatchResetPassword: (payload: ResetPasswordRequest) => void;
}

export type ResetPasswordFormProps = ResetPasswordFormStateProps & ResetPasswordFormDispatchProps & WithTranslation;
