import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { defaultRoutePath } from 'config/config';
import { Action, Dispatch } from 'models/meta/action';
import { navigate } from 'store/actions/common';
import { logout } from "store/actions/user";

import State from "models/app/store";
import { LogoutButtonDispatchProps, LogoutButtonStateProps } from './LogoutButton.types';
import LogoutButton from './LogoutButton.layout';


const mapDispatchToProps = (dispatch: Dispatch<Action>): LogoutButtonDispatchProps => ({
    dispatchLogout: () => {

        const navigateToDefaultRoutePath: Action = navigate(defaultRoutePath);

        dispatch(logout());
        dispatch(navigateToDefaultRoutePath);
    }
});

const mapStateToProps = (state: State): LogoutButtonStateProps => {
    return ({ shouldShow: state.user.isAuthenticated });
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('common')(LogoutButton));

