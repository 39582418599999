import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { IconFont } from "assets/styles/icons/Icons.styled";
import { space, SpaceProps } from "styled-system";

const StandardTooltip = styled(ReactTooltip)`
  max-width: 300px;
  text-align: center;
  
  &.show {
    opacity: 1;
  }
`;

export default { StandardTooltip };

export const OutsideTooltipIcon = styled(IconFont)`
    font-size: 22px;
    cursor: help;
    height: 22px;
    display: flex;
    align-items: center;
    visibility: ${({ show }: { show: boolean }) => show ? 'initial' : 'hidden'}
`;

export const OutsideTooltipIconWrapper = styled.div<SpaceProps>`
    display: flex;
    align-items: center;
    
    ${space}
`;
