import styled from "styled-components";

export const FileUploaderWithTooltipWrapper = styled.div`
    height: 200px;
`;

export const FileUploaderWrapper = styled.div`
    position: relative;
    width: 444px;
`;

export const FileUploaderTooltipWrapper = styled.div`
    position: absolute;
    right: -54px;
    top: 35px;
`;
