import styled from 'styled-components';
import { TransitionGroup } from 'react-transition-group';
import { globalColors, standardLighterShadow } from 'components/AppLayout/config';

const SideBarWrap = styled.aside`
  position: relative;
  padding: 0 25px;
  text-align: center; 
  background-color: ${globalColors.backgrounds.grey.light};
  ${standardLighterShadow};
`;

const SideBarInnerWrap = styled.div`
  position: sticky;
  top: 0;
`;

const AppLogoWrap = styled.aside`
  padding: 40px 0;
  text-align: center; 
  border-bottom: 1px solid ${globalColors.backgrounds.grey.xdark};
  margin-bottom: 30px;
`;

const PublicLinksWrap = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid ${globalColors.backgrounds.grey.xdark};
`;

const UserPart = styled(TransitionGroup)`
  position: relative;
  height: 120px;
`;

const AuthorisedLinksWrap = styled.div`
  padding-top: 30px;
  border-top: 1px solid ${globalColors.backgrounds.grey.xdark};
`;

const AuthorisedLinksTransitionWrap = styled.div`
  position: relative;
`;

export default {
    SideBarWrap,
    SideBarInnerWrap,
    AppLogoWrap,
    PublicLinksWrap,
    UserPart,
    AuthorisedLinksTransitionWrap,
    AuthorisedLinksWrap
};
