import { TppId } from 'models/domain/tpp';
import { ApiKey, ApiKeyId, GetApiKeyListResponse } from 'models/domain/api-keys';

import { HttpService } from '../HttpService';


const apiKeys = {
    insertApiKey: (tppId: TppId): Promise<void> => {
        return HttpService.post(`/api/tppmanagement/v1/tpp/${tppId}/keys`, undefined);
    },
    deleteApiKey: (tppId: TppId, apiKeyId: ApiKeyId): Promise<void> => {
        return HttpService.delete(`/api/tppmanagement/v1/tpp/${tppId}/keys/${apiKeyId}`);
    },
    getApiKey: (tppId: TppId, apiKeyId: ApiKeyId): Promise<Required<ApiKey>> => {
        return HttpService.get(`/api/tppmanagement/v1/tpp/${tppId}/keys/${apiKeyId}`);
    },
    getApiKeyList: (tppId: TppId): Promise<GetApiKeyListResponse> => {
        return HttpService.get(`/api/tppmanagement/v1/tpp/${tppId}/keys`);
    },
};

export { apiKeys };
