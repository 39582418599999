import styled from 'styled-components';
import { TransitionGroup } from 'react-transition-group';
import theme from 'config/theme';
import { IconFont } from 'assets/styles/icons/Icons.styled';
import { globalColors } from 'components/AppLayout/config';

const TransitionWrap = styled(TransitionGroup)`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
`;

const ContentWrap = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
`;

const Label = styled.div`
  min-height: 32px;
  padding-bottom: 16px;
  font-size: 14px;
  color: ${theme.colors.black};
`;

const LoggedInAsIcon = styled(IconFont)`
  margin-right: 10px;
  color: ${globalColors.fontColors.grey.dark};
  font-size: 18px;
`;

const LoggedInAs = styled.div`
  color: ${globalColors.fontColors.grey.dark};
  font-weight: ${theme.fontWeights.semiBold};
  font-size: 18px;
`;

export default { TransitionWrap, ContentWrap, Label, LoggedInAsIcon, LoggedInAs };
