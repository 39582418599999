import { WithTranslation } from "react-i18next";
import { MimeType } from "models/app/common";
import { id } from "utils/function-tools";
import { UploaderFile } from "components/reusable/FileUploader/FileUploader.types";

export type DispatchUploadCsr = (files: UploaderFile[]) => void
export type DispatchGenerateSelfSignedCertificate = () => void

export interface CertificatesDispatchProps {
    dispatchUploadCsr: DispatchUploadCsr,
    dispatchGenerateSelfSignedCertificate: DispatchGenerateSelfSignedCertificate,
    dispatchDismissAllButUnauthToasts: () => void
}

export interface CertificatesStateProps {
    isCsrFileBeingAsyncValidated: boolean,
    isCsrFileAttached: boolean,
}

export interface CertificatesOwnProps {
}

export interface CertificatesProps extends WithTranslation,
    CertificatesOwnProps,
    CertificatesDispatchProps,
    CertificatesStateProps {
}

const csrExtensions = ['pem', 'csr'];

const csrMimeTypes: string[] = [''];

export const CSR_MIME_TYPES: MimeType[] = csrExtensions.map((extension) => {
    return csrMimeTypes.map((mime) => {
        return {
            mime,
            extension
        };
    });
}).flatMap(id);
