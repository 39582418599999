import styled from 'styled-components';
import { WithTheme } from 'config/theme';
import { globalColors } from 'components/AppLayout/config';

const TextButton = styled.button`
    display: inline-block;
    padding: 0;
    margin: 0;
    min-width: unset;
    background-color: transparent;
    border: none;
    box-shadow: none;
    font-weight: ${({ theme }: WithTheme) => theme.fontWeights.bold};
    color: ${globalColors.fontColors.green.light};  

    &:hover,
    &:active,
    &:focus {
        color: ${globalColors.fontColors.green.dark};
        cursor: pointer;
    }

    &:disabled {
        color: ${globalColors.fontColors.grey.xdark};
    }
`;

export default TextButton;
