import { GetTppDetailsResponse, TppApplication, TppId, TppStatus } from "models/domain/tpp";
import { OnboardingStep } from "models/app/workflow";

type SET_ONBOARDING_STEP = 'SET_ONBOARDING_STEP';
type GET_TPP_STATUS = 'GET_TPP_STATUS';
type GET_TPP_DETAILS = 'GET_TPP_DETAILS';
type GET_TPP_DETAILS_SUCCESS = 'GET_TPP_DETAILS_SUCCESS';
type GET_TPP_STATUS_SUCCESS = 'GET_TPP_STATUS_SUCCESS';
type GET_TPP_STATUS_FAILURE = 'GET_TPP_STATUS_FAILURE';
type RESET_ONBOARDING_STATE = 'RESET_ONBOARDING_STATE';
type SUBMIT_TPP_APPLICATION = 'SUBMIT_TPP_APPLICATION';
type SUBMIT_TPP_APPLICATION_SUCCESS = 'SUBMIT_TPP_APPLICATION_SUCCESS';
type SUBMIT_TPP_APPLICATION_FAILURE = 'SUBMIT_TPP_APPLICATION_FAILURE';
type UPLOAD_AND_PARSE_QWAC_FILE = 'UPLOAD_AND_PARSE_QWAC_FILE';

export const GET_TPP_STATUS: GET_TPP_STATUS = 'GET_TPP_STATUS';
export const GET_TPP_DETAILS: GET_TPP_DETAILS = 'GET_TPP_DETAILS';
export const GET_TPP_DETAILS_SUCCESS: GET_TPP_DETAILS_SUCCESS = 'GET_TPP_DETAILS_SUCCESS';
export const GET_TPP_STATUS_SUCCESS: GET_TPP_STATUS_SUCCESS = 'GET_TPP_STATUS_SUCCESS';
export const GET_TPP_STATUS_FAILURE: GET_TPP_STATUS_FAILURE = 'GET_TPP_STATUS_FAILURE';

export const SUBMIT_TPP_APPLICATION = 'SUBMIT_TPP_APPLICATION';
export const SUBMIT_TPP_APPLICATION_SUCCESS = 'SUBMIT_TPP_APPLICATION_SUCCESS';
export const SUBMIT_TPP_APPLICATION_FAILURE = 'SUBMIT_TPP_APPLICATION_FAILURE';

export const UPLOAD_AND_PARSE_QWAC_FILE = 'UPLOAD_AND_PARSE_QWAC_FILE';

export const SET_ONBOARDING_STEP: SET_ONBOARDING_STEP = 'SET_ONBOARDING_STEP';

export const RESET_ONBOARDING_STATE: RESET_ONBOARDING_STATE = 'RESET_ONBOARDING_STATE';

export interface SetOnboardingStep {
    type: SET_ONBOARDING_STEP;
    payload: OnboardingStep | null;
}

export interface SubmitTppApplication {
    type: SUBMIT_TPP_APPLICATION;
    payload: TppApplication;
}

export interface SubmitTppApplicationSuccess {
    type: SUBMIT_TPP_APPLICATION_SUCCESS;
    payload: TppId
}

export interface SubmitTppApplicationFailure {
    type: SUBMIT_TPP_APPLICATION_FAILURE;
}

export interface GetTppStatus {
    type: GET_TPP_STATUS;
}

export interface ResetOnboardingState {
    type: RESET_ONBOARDING_STATE;
}

export interface GetTppStatusSuccess {
    type: GET_TPP_STATUS_SUCCESS;
    payload: TppStatus | null
}

export interface GetTppDetailsSuccess {
    type: GET_TPP_DETAILS_SUCCESS;
    payload: GetTppDetailsResponse
}

export interface GetTppDetails {
    type: GET_TPP_DETAILS;
    payload: GetTppDetailsResponse
}

export interface GetTppStatusFailure {
    type: GET_TPP_STATUS_FAILURE;
}

export type OnboardingActions =
    ResetOnboardingState
    | GetTppStatusSuccess
    | GetTppStatusFailure
    | GetTppStatus
    | GetTppDetailsSuccess
    | GetTppDetails
    | SubmitTppApplication
    | SubmitTppApplicationSuccess
    | SubmitTppApplicationFailure
    | SetOnboardingStep;
