import React from 'react';
import { NarrowContentWrap } from 'components/AppLayout/AppLayout.styled';
import Styled, { GrayParagraph } from 'components/reusable/CommonTypography.styled';
import TranslationWithMarkup from "components/reusable/TranslationWithMarkup/TranslationWithMarkup.layout";
import LogoutButton from "components/reusable/Buttons/LogoutButton/LogoutButton";
import { EnvironmentsProps } from "./Environments.types";
import { Environment } from "./Environment.layout";

const Environments: React.FC<EnvironmentsProps> = ({
    t,
    tppId,
    shouldShowProductionEnvironment,
}) => {

    return (
        <NarrowContentWrap>

            <LogoutButton />

            <Styled.PageHeading>{t('environments:pageHeader')}</Styled.PageHeading>

            <TranslationWithMarkup
                translationKey="environments:text"
                components={{ p: <GrayParagraph pb={0} /> }}
            />

            <Environment
                tooltipId="sandbox-environment-tooltip"
                url="https://sandbox.okeo.com"
                subSectionHeader={t('environments:sectionHeaders.sandboxEnvironment')}
                t={t}
                tppId={tppId}
            />

            {
                shouldShowProductionEnvironment && (
                    <Environment
                        tooltipId="production-environment-tooltip"
                        url="https://okeo.com/prod/xs2a/api/v1/"
                        subSectionHeader={t('environments:sectionHeaders.productionEnvironment')}
                        t={t}
                        tppId={tppId}
                    />
                )
            }

        </NarrowContentWrap>
    );
};

export default Environments;
