import { HttpStatus } from 'utils/constants/http-status';
import { Action } from './action';

export const loginUrl = '/api/tppmanagement/v1/user/authentication';
export const registerUrl = '/api/tppmanagement/v1/user';
export const restPasswordUrl = '/api/tppmanagement/v1/user/password';


export interface ApiError extends Error {
    status: HttpStatus;
    authentication: boolean;
    handled: boolean;
    actions: Action[];
}

export interface RequestConfig {
    params?: object;
    headers?: object;
    raw?: boolean
    noErrorHandling?: boolean;
}

export interface Response<T = any> {
    data: T;
    status: number;
    headers: any;
}
