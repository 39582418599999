import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { toast } from "react-toastify";

import { ThemeProvider } from 'styled-components';
import { createBrowserHistory } from 'history';

import theme from 'config/theme';
import { developmentMode } from 'config/config';

import { configureStore, Dependencies } from 'store';
import { createHttpService, HttpService } from 'services/HttpService';
import TppManagementService from 'services/TppManagementService';
import OnboardingService from "services/OnboardingService";
import ApiKeysService from 'services/ApiKeysService';
import Main from 'components/Main';
import showToast from 'components/reusable/Toast/AppToast/AppToast.layout';
import ModalOrchestrator from "components/reusable/Modals/ModalOrchestrator.layout";
import CertificatesService from "services/CertificatesService";
import DictionaryService from 'services/DictionaryService';
import FileService from "services/FileService";

const wrapper = document.getElementById('app') as HTMLDivElement;
const history = createBrowserHistory();
const http = createHttpService();

const dependencies: Dependencies = {
    history,
    http,
    toast,
    showToast,
    document,
    tpp: TppManagementService,
    onboarding: OnboardingService,
    certificates: CertificatesService,
    apiKeys: ApiKeysService,
    dictionary: DictionaryService,
    file: FileService,
};

const store = configureStore(dependencies);
HttpService.configure(store.dispatch);

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>

            <Router history={history}>
                <Main />
                <ModalOrchestrator />
            </Router>
        </ThemeProvider>
    </Provider>,
    wrapper
);


if (developmentMode && module.hot) {
    /* eslint-disable no-console */
    module.hot.accept(error => console.error(error));
    module.hot.addStatusHandler(status => console.log(`HMR: ${status}`));
    /* eslint-enable no-console */
}
