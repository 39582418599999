import styled from "styled-components";
import { divider } from "components/reusable/CommonTypography.styled";

export const OnboardingApplicationReviewActionablesWrapper = styled.div`
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const OnboardingApplicationReviewActionablesTooltip = styled.div`
    margin-right: 20px;
`;

export const OnboardingApplicationReviewDivider = styled.hr`
    ${divider};
    
    margin-top: 30px;
    margin-bottom: 10px;
`;
