import React, { SyntheticEvent } from "react";
import { WrappedFieldProps } from "redux-form";
import Icons from "assets/styles/icons/icons";
import { globalColors } from "components/AppLayout/config";
import { FormContentWrapper } from "components/pages/Onboarding/OnboardingForm/OnboardingFormCompanyDetails.styled";
import Tooltip from "components/reusable/Tooltip/Tooltip.layout";
import { OutsideTooltipIcon, OutsideTooltipIconWrapper } from "components/reusable/Tooltip/Tooltip.styled";
import { CheckboxOwnProps, CheckboxProps } from "./Checkbox.types";
import {
    CheckboxInput,
    CheckboxInputWrapper,
    CheckboxLabel,
    CheckboxTooltipWrapper,
    DisabledCheckbox
} from "./Checkbox.styled";

export const Checkbox: React.FC<CheckboxProps> = ({ input, tooltip, label, disabled, showError }) => {

    return (
        <OutsideTooltipIconWrapper>
            <FormContentWrapper>

                <CheckboxInputWrapper>

                    <CheckboxLabel
                        showError={showError}
                        data-test-id={`${input.name}-checkbox`}
                        disabled={disabled}
                        tabIndex={0}
                        checked={!!input.value}
                        onKeyDown={(e: SyntheticEvent & { key: string }) => e.key === 'Enter' && input.onChange(!input.value)}
                    >

                        <CheckboxInput
                            disabled={false}
                            type="checkbox"
                            checked={!!input.value}
                            {...input}
                        />

                        {label}

                    </CheckboxLabel>

                </CheckboxInputWrapper>

            </FormContentWrapper>

            <CheckboxTooltipWrapper>
                <OutsideTooltipIcon
                    show={!!tooltip}
                    icon={Icons.infoCircle}
                    color={globalColors.fontColors.green.light}
                    data-for="checkbox-tooltip"
                    data-tip={tooltip}
                />

                <Tooltip id="checkbox-tooltip" />
            </CheckboxTooltipWrapper>

        </OutsideTooltipIconWrapper>
    );
};

export const withCheckbox = (props: CheckboxOwnProps) => (wrappedFieldProps: WrappedFieldProps) => {

    const Wrapper = props.disabled ? DisabledCheckbox : React.Fragment;

    return (
        <Wrapper>
            <Checkbox
                {...props}
                {...wrappedFieldProps}
            />
        </Wrapper>
    );
};
