import styled, { css } from 'styled-components';
import { errorColor, globalColors } from 'components/AppLayout/config';
import { AnimatedLabelProps } from "./AnimatedLabel.types";

const raisedLabelStyles = css`
    top: -6px;
    font-size: 12px;
    background-color: rgba(255,255,255, 1);
`;

const AnimatedLabel = styled.label<Omit<AnimatedLabelProps, 'text'>>`
    position: absolute;
    top: 22px;
    left: 10px;
    z-index: 4;
    display: block;
    width: max-content;
    font-size: 14px;
    padding: 0 12px 0 10px;
    user-select: none;
    white-space: nowrap;
    cursor: text;
    pointer-events: none;
    color: ${globalColors.fontColors.grey.base};
    color: ${props => (props.hasError ? errorColor : globalColors.fontColors.black)};
    background-color: rgba(255,255,255, 0);
    transition: all .2s;
    
    ${props => props.hasValue && raisedLabelStyles}
    
    input:-webkit-autofill + & {
      ${raisedLabelStyles};
    }

`;

export default { AnimatedLabel };
