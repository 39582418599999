import React from 'react';

import { noop } from 'utils/function-tools';

import { ApiKeyHolderProps, ApiKeyTileMode, createApiKeyMode } from './ApiKeyHolder.types';
import Styled from './ApiKeyHolder.styled';


export const ApiKeyHolder: React.FC<ApiKeyHolderProps> = props => {
    const mode = props.isLoading ? ApiKeyTileMode.LOADING : createApiKeyMode(props.apiKey);

    const content = mode === ApiKeyTileMode.PLACEHOLDER ? props.placeholder : props.apiKey?.apiKeyValue;
    const onBlur = mode === ApiKeyTileMode.REVEALED ? props.onBlur : noop;
    const onClick = mode === ApiKeyTileMode.CONCEALED ? props.onIconClick : noop;

    return (
        <Styled.ApiKeyTile tabIndex={0} onBlur={onBlur}>
            <Styled.ApiKeyTileContent mode={mode}>{content}</Styled.ApiKeyTileContent>
            <Styled.ApiKeyTileIcon mode={mode} onClick={onClick} />
        </Styled.ApiKeyTile>
    );
};
