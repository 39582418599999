import { OnboardingStep } from 'models/app/workflow';
import { GetTppDetailsResponse, TppApplication, TppId, TppStatus } from "models/domain/tpp";
import {
    GET_TPP_DETAILS,
    GET_TPP_DETAILS_SUCCESS,
    GET_TPP_STATUS,
    GET_TPP_STATUS_SUCCESS,
    RESET_ONBOARDING_STATE,
    SET_ONBOARDING_STEP,
    SetOnboardingStep,
    SUBMIT_TPP_APPLICATION,
    SUBMIT_TPP_APPLICATION_FAILURE,
    SUBMIT_TPP_APPLICATION_SUCCESS,
    UPLOAD_AND_PARSE_QWAC_FILE
} from "./onboarding.types";

export const setOnboardingStep = (onboardingStep: OnboardingStep | null): SetOnboardingStep => {
    return {
        type: SET_ONBOARDING_STEP,
        payload: onboardingStep
    };
};

export const getTppStatus = () => ({ type: GET_TPP_STATUS });

export const getTppDetails = (tppId: TppId) => ({ type: GET_TPP_DETAILS, payload: tppId });

export const getTppDetailsSuccess = (tppDetails: GetTppDetailsResponse) => {
    return ({ type: GET_TPP_DETAILS_SUCCESS, payload: tppDetails });
};

export const resetOnboardingState = () => ({ type: RESET_ONBOARDING_STATE });

export const getTppStatusSuccess = (tppStatus: TppStatus | null) => ({
    type: GET_TPP_STATUS_SUCCESS,
    payload: tppStatus
});

export const submitTppApplication = (tppApplication: TppApplication) => ({
    type: SUBMIT_TPP_APPLICATION,
    payload: tppApplication
});

export const submitTppApplicationSuccess = (tppId: TppId) => {
    return ({ type: SUBMIT_TPP_APPLICATION_SUCCESS, tppId });
};

export const submitTppApplicationFailure = () => ({ type: SUBMIT_TPP_APPLICATION_FAILURE });

export const uploadAndParseQwac = (tppId: TppId, qwacFile: string) => ({
    type: UPLOAD_AND_PARSE_QWAC_FILE,
    payload: {
        tppId,
        qwacFile
    }
});
