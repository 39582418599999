import React, { FunctionComponent } from 'react';

import Styled from './NavigationBarDecoration.styled';

const NavigationBarDecoration: FunctionComponent = () => (
    <Styled.NavigationBarDecoration>
        <Styled.NavigationBarWrapper />
    </Styled.NavigationBarDecoration>
);

export default NavigationBarDecoration;
