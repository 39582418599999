import { Epic, ofType } from 'redux-observable';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { GET_COUNTRIES_INFO } from '../actions/dictionary.types';
import { getCountriesInfoFailure, getCountriesInfoSuccess } from '../actions/dictionary';


export const onGetCountriesInfo: Epic = (action$, state$, { dictionary }) =>
    action$.pipe(
        ofType(GET_COUNTRIES_INFO),
        withLatestFrom(state$),
        switchMap(([_, state]) => {
            const { countries } = state.dictionary;
            const countriesInfoStream = countries.length > 0 ? of(countries) : from(dictionary.getAllCountryCodesDetails());
            return countriesInfoStream.pipe(
                map(countries => getCountriesInfoSuccess(countries)),
                catchError(() => of(getCountriesInfoFailure()))
            );
        })
    );
