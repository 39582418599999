import styled from "styled-components";
import theme from "config/theme";
import { footerHeight } from "assets/styles/global/layout.styled";
import { globalColors, standardShadow } from "components/AppLayout/config";
import logoSymbol from "assets/img/logo-symbol.svg";

const LoginFooterOuterWrap = styled.footer`
    position: relative;
    z-index: 3;
    ${standardShadow};
    background-color: ${theme.colors.white};
`;

const LoginFooterInnerWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${footerHeight}px;
    padding: 0 90px 0 20px;
    color: ${globalColors.fontColors.grey.dark};
    margin: 0 auto;
    position: relative;
`;

const LinksWrap = styled.div`
    position: relative;
    margin: 0 auto;
    padding: 0 240px 0 270px;
    display: flex;
    flex: 1 1 100%;
    align-items: center;
    justify-content: center;
`;

const LogoAndCopy = styled.div`
    display: flex;
    align-items: center;
`;

const Copyrights = styled.span`
    margin-left: 18px;
    font-size: 14px;
    line-height: 19px;
    color: ${globalColors.fontColors.grey.dark};
    white-space: nowrap;
`;

const Link = styled.a`
    padding: 0 25px;
    font-size: 14px;
    font-weight: 600;
    color: ${globalColors.fontColors.grey.dark};
    cursor: pointer;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
    white-space: nowrap;

    &:hover,
    &:active {
        color: ${globalColors.fontColors.green.base};
    }
`;

const PositionedLanguageChanger = styled.div`
    position: absolute;
    right: 0;
`;

const Logo = styled.div`
    width: 54px;
    height: 34px;
    background-size: 90px;
    background-position: center center;
    background-image: url(${logoSymbol});
`;

export default {
    Logo,
    Link,
    LinksWrap,
    Copyrights,
    LogoAndCopy,
    LoginFooterOuterWrap,
    LoginFooterInnerWrap,
    PositionedLanguageChanger,
};
