import React from 'react';
import { WithT } from 'i18next';

import Styled from 'components/reusable/CommonTypography.styled';
import { NarrowContentWrap } from 'components/AppLayout/AppLayout.styled';

const TermsOfService: React.FC<WithT> = ({ t }) => {

    return (
        <NarrowContentWrap>
            <Styled.PageHeading>*Terms Of Service*</Styled.PageHeading>
            <h1>TODO</h1>
        </NarrowContentWrap>
    );
};

export default TermsOfService;
