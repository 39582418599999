import styled from 'styled-components';

import { WithTheme } from 'config/theme';
import TextButton from 'components/reusable/Buttons/TextButton.styled';
import Styled from 'components/reusable/CommonTypography.styled';


const verticalSpacing = ({ theme }: WithTheme) => theme.space[6];


const ApiKeyOperations = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ApiKeyOperation = styled(TextButton)`
    font-weight: ${({ theme }: WithTheme) => theme.fontWeights.semiBold};
`;

const ApiKeySection = styled.div`
    border-top: 2px solid ${({ theme }: WithTheme) => theme.colors.gray.dark};
    margin-top: ${verticalSpacing}px;
    margin-bottom: ${verticalSpacing}px;
    padding-top: ${verticalSpacing}px;
`;

const ApiKeySectionHeader = styled(Styled.Paragraph)`
    padding-bottom: ${verticalSpacing}px;
    color: ${({ theme }: WithTheme) => theme.colors.font.onDarkLighter};
`;

const ApiKeyHeader = styled(Styled.Emphasis)`
    display: inline-block;
    margin-bottom: 8px;
    font-weight: ${({ theme }: WithTheme) => theme.fontWeights.semiBold};
`;

export default { ApiKeySection, ApiKeyOperations, ApiKeyOperation, ApiKeySectionHeader, ApiKeyHeader };
