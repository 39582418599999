import React from "react";
import { OnboardingStep } from "models/app/workflow";
import TranslationWithMarkup from "components/reusable/TranslationWithMarkup/TranslationWithMarkup.layout";
import { PageHeading } from "components/reusable/CommonTypography.styled";
import { OkeoButton } from "components/atoms/OkeoButton/OkeoButton.styled";
import { OnboardingApplicationSummaryProps } from "./OnboardingApplicationSummary.types";
import { ApplicationSummaryContinueButtonWrapper } from "./OnboardingApplicationSummary.styled";

export const OnboardingApplicationSummary: React.FC<OnboardingApplicationSummaryProps> = (
    {
        dispatchSetOnboardingStep,
        isTppApplicationBeingSubmitted,
        t
    }
) => {

    return (
        !isTppApplicationBeingSubmitted
            ? (
                <>

                    <PageHeading>
                        {t('onboarding:preSubmission.submittedStep.pageHeader')}
                    </PageHeading>

                    <TranslationWithMarkup translationKey="onboarding:preSubmission.submittedStep.text" />

                    <ApplicationSummaryContinueButtonWrapper>
                        <OkeoButton
                            onClick={() => {
                                dispatchSetOnboardingStep(OnboardingStep.INTRODUCTION);
                            }}
                            type="button"
                        >

                            {t('onboarding:postSubmission.buttons.continue.text')}
                        </OkeoButton>
                    </ApplicationSummaryContinueButtonWrapper>

                </>
            )
            : <></>
    );
};
