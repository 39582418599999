import { Action, compose, Dispatch } from "redux";
import { connect } from "react-redux";
import { OnboardingStep } from "models/app/workflow";
import { getTppStatus, resetOnboardingState, setOnboardingStep, submitTppApplication } from "store/actions/onboarding";
import State from "models/app/store";
import createWithLoading from "hocs/loading/createWithLoading";
import LoadingOverlaySection from "components/reusable/Overalys/SectionLoadingOverlay.layout";
import { TppApplication } from "models/domain/tpp";
import { WithTranslation, withTranslation } from "react-i18next";
import { reset } from "redux-form";
import { setFileUploaderFiles } from "store/actions/fileUploader";
import { ONBOARDING_FILE_UPLOADER_NAME } from "store/actions/fileUploader.types";
import React from "react";
import Onboarding from "./Onboarding.layout";
import {
    ONBOARDING_FORM_NAME,
    OnboardingDispatchProps,
    OnboardingMergeProps,
    OnboardingStateProps
} from "./Onboarding.types";

function mapDispatchToProps(dispatch: Dispatch<Action>): OnboardingDispatchProps {
    return {
        dispatchGetTppStatus: (isAuthenticated: boolean) => {

            if (isAuthenticated) {
                dispatch(getTppStatus());
            }
        },
        dispatchResetOnboardingForm: () => {
            dispatch(reset(ONBOARDING_FORM_NAME));
        },
        dispatchResetOnboardingState: () => {
            dispatch(resetOnboardingState());
        },
        dispatchSetOnboardingStep: (onboardingStep: OnboardingStep) => {
            dispatch(setOnboardingStep(onboardingStep));
        },
        dispatchSubmitTppApplication: (tppApplication: TppApplication) => {
            dispatch(submitTppApplication(tppApplication));
        },
        dispatchResetFileUploaderFiles: () => {
            dispatch(setFileUploaderFiles(ONBOARDING_FILE_UPLOADER_NAME, []));
        }
    };
}

const loadingSelector = (state: State) => {
    return state.onboarding.isTppStatusBeingLoaded || state.onboarding.areTppDetailsBeingLoaded;
};

function mapStateToProps(state: State): OnboardingStateProps {
    return {
        isAuthenticated: state.user.isAuthenticated,
        isLoading: loadingSelector(state),
        tppStatus: state.onboarding.tppStatus,
        onboardingStep: state.onboarding.onboardingStep
    };
}

const mergeProps = (
    stateProps: OnboardingStateProps,
    dispatchProps: OnboardingDispatchProps,
    ownProps: WithTranslation
): OnboardingMergeProps => {

    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        dispatchGetTppStatus: () => {
            dispatchProps.dispatchGetTppStatus(stateProps.isAuthenticated);
        }
    };
};

const ConnectedOnboarding = compose<React.FC>(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(Onboarding);

export default createWithLoading<{}>(ConnectedOnboarding, {
    loadingSelector,
    OverlayComponent: LoadingOverlaySection,
    positionLock: true
});
