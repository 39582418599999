import React from 'react';
import { Action } from 'models/meta/action';

type SET_LANGUAGE = 'SET_LANGUAGE';
type START_APP_LOADING = 'START_APP_LOADING';
type END_APP_LOADING = 'END_APP_LOADING';
type SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';

export const START_APP_LOADING: START_APP_LOADING = 'START_APP_LOADING';
export const END_APP_LOADING: END_APP_LOADING = 'END_APP_LOADING';
export const SET_LANGUAGE: SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_SCROLL_POSITION: SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';


export interface StartAppLoading extends Action {
    type: START_APP_LOADING;
}

export interface EndAppLoading extends Action {
    type: END_APP_LOADING;
}

export interface SetLanguage {
    type: SET_LANGUAGE;
    payload: string;
}

export interface SetScrollPositionProps extends ScrollToOptions {
    ref?: React.Ref<HTMLElement | null>;
}

export interface SetScrollPosition {
    type: SET_SCROLL_POSITION;
    payload: SetScrollPositionProps;
}

type LoadingActions = SetLanguage | SetScrollPosition | StartAppLoading | EndAppLoading;

export type AppActions = LoadingActions;
