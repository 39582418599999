import { Action } from 'models/meta/action';

export const SHOW_SUCCESS_TOAST = 'SHOW_SUCCESS_TOAST';
export const SHOW_INFO_TOAST = 'SHOW_INFO_TOAST';
export const SHOW_WARN_TOAST = 'SHOW_WARN_TOAST';
export const SHOW_ERROR_TOAST = 'SHOW_ERROR_TOAST';
export const DISMISS_ALL_TOASTS = 'DISMISS_ALL_TOASTS';
export const DISMISS_ALL_BUT_UNAUTH_TOASTS = 'DISMISS_ALL_BUT_UNAUTH_TOASTS';
export const DOWNLOAD_URI = 'DOWNLOAD_URI';

export const CLEAR_STORE = 'CLEAR_STORE';

export const NAVIGATE = 'NAVIGATE';

export interface DownloadURI extends Action {
    type: typeof DOWNLOAD_URI;
    payload: {
        uri: string,
        fileName: string
    }
}

export interface ClearStore extends Action {
    type: typeof CLEAR_STORE;
}

export interface NavigateOptions {
    externalUrl?: boolean;
}

export interface Navigate extends Action {
    type: typeof NAVIGATE;
    payload: string;
    meta: NavigateOptions;
}

export interface TranslatingOptions {
    shouldTranslate: boolean
}
