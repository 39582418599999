import React from "react";
import { WrappedFieldProps } from "redux-form";
import Icons from "assets/styles/icons/icons";
import { globalColors } from "components/AppLayout/config";
import Tooltip from "components/reusable/Tooltip/Tooltip.layout";
import { FormFieldProps } from "components/reusable/FormField/FormField.types";
import { FormField } from "components/reusable/FormField/FormField.layout";
import { TextInput } from "components/reusable/FormField/TextInput/TextInput.layout";
import { OutsideTooltipIcon, OutsideTooltipIconWrapper } from "components/reusable/Tooltip/Tooltip.styled";
import { OnboardingFieldWrap } from "./OnboardingForm.styled";
import { OnboardingFieldProps } from "./OnboardingForm.types";

const renderOnboardingField = ({ outsideTooltip, placeholder, id, ...rest }: OnboardingFieldProps) => ({ input, meta }: WrappedFieldProps) => {

    const showError = meta.touched && meta.error;

    return (
        <OutsideTooltipIconWrapper>
            <TextInput
                mr={5}
                dataTestId={`${id}-input`}
                value={input.value}
                onChange={input.onChange}
                onBlur={input.onBlur}
                onFocus={input.onFocus}
                showError={showError}
                placeholder={placeholder}
                {...rest}
            />

            <OutsideTooltipIcon
                show={!!outsideTooltip}
                icon={Icons.infoCircle}
                color={globalColors.fontColors.green.light}
                data-for={`outsideTooltip-${input.name}`}
                data-test-id={`outsideTooltip-${input.name}`}
                data-tip={outsideTooltip}
            />

            <Tooltip id={`outsideTooltip-${input.name}`} />

        </OutsideTooltipIconWrapper>
    );
};

export const WithOutsideTooltip = (Component: typeof FormField) => {
    return (props: FormFieldProps & OnboardingFieldProps) => {
        return (
            <OnboardingFieldWrap>
                <Component
                    {...props}
                    renderCustomInput={renderOnboardingField(props)}
                />
            </OnboardingFieldWrap>
        );
    };
};

export const OnboardingField = WithOutsideTooltip(FormField);
