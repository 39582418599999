import styled, { css } from "styled-components";
import { WithDisabled } from "models/app/common";
import { disableCursor, disablePointer } from "components/reusable/access.styled";
import { WithTheme } from "config/theme";
import { BorderColorTransition, ColorTransition } from "assets/styles/animations/animations.styled";
import { CheckboxLabelProps } from "./Checkbox.types";

export const CheckboxInputWrapper = styled.div`
    display: flex;
    align-items: center; 
`;

export const CheckboxTooltipWrapper = styled.div`
    margin-left: 32px;
`;

export const CheckboxLabel = styled.label<CheckboxLabelProps & WithDisabled & { showError?: boolean }>`
    position: relative;
    ${disableCursor};
    display: initial;
    font-size: 14px;
    ${ColorTransition};

    ${({ display }) => display === 'flex' && css`
        display: flex;
        justify-content: space-between;
        align-items: center;
    `}
    
    &:before {
        ${BorderColorTransition};

        content: '';
        background-color: ${({ theme }) => theme.colors.white};
        border: 1px solid ${({ theme, showError }) => showError ? theme.colors.error.base : theme.colors.gray.base} ;
        height: 22px;
        width: 22px;
        display: inline-block;
        position: relative;
        top: -1px; /* compensate for border */
        vertical-align: middle;
        margin-right: 16px;
        border-radius: 2px;
        ${({ type }) => type === 'radio' && css`border-radius: 50%;`}
    }

    &:focus {
        outline: none;
    }

    ${({ disabled }) => !disabled && css`
    &:hover:before {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    }`}

    ${({ theme, disabled }) => !disabled && css`
    &:focus:before,
    &:hover:before {
         ${BorderColorTransition};

         border-color:  ${({ showError }: { showError?: boolean }) => showError ? theme.colors.error.base : theme.colors.accent.base} ;   
    }`}

    &:after {
        content: '';
        display: block;
        position: absolute;
        transform: scale(0);
        transition: transform 0.2s ease-in;
        ${({ type, checked }) => type === 'radio'
        ? css<CheckboxLabelProps>`
            top: 5px;
            left: 5px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: ${({ theme }) => theme.colors.accent.base};
            ${checked && css`transform: scale(1);`}
        `
        : css<CheckboxLabelProps>`
            top: 4px;
            left: 9px;
            width: 4px;
            height: 7px;            
            border: solid ${({ theme, disabled }: WithDisabled & WithTheme) => disabled ? theme.colors.gray.base : theme.colors.accent.base};
            border-width: 0 2px 2px 0;
            ${checked && css`transform: scale(1.5) rotate(55deg);`}
        `}
    }
`;

export const CheckboxInput = styled.input<WithDisabled>`
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    ${disablePointer}
`;

export const DisabledCheckbox = styled.div`
    border: ${({ theme }: WithTheme) => theme.colors.gray.base};
    pointer-events: none;
`;
