import React from "react";
import Icons from "assets/styles/icons/icons";
import { globalColors } from "components/AppLayout/config";
import { SubSectionHeader } from "components/reusable/SubSectionHeader/SubSectionHeader.layout";
import { OutsideTooltipIcon, OutsideTooltipIconWrapper } from "components/reusable/Tooltip/Tooltip.styled";
import FileUploader from "components/reusable/FileUploader/FileUploader";
import Tooltip from "components/reusable/Tooltip/Tooltip.layout";
import ErrorMessage from "components/reusable/FormField/ErrorMessage/ErrorMessage.layout";
import {
    FileUploaderTooltipWrapper,
    FileUploaderWithTooltipWrapper,
    FileUploaderWrapper
} from "./FileUploaderWithTooltip.styled";
import { FileUploaderWithTooltipProps } from "./FileUploaderWithTooltip.types";

export const FileUploaderWithTooltip: React.FC<FileUploaderWithTooltipProps> = (props) => {

    const {
        dispatchAsynchronousValidation,
        showError,
        fieldLocationInFormState,
        removeFilesOnUnmount,
        onChangesToAttachedFiles,
        id,
        tooltip,
        header,
        onFileUploaderUnmounted,
        acceptedFileTypes
    } = props;

    const fileUploaderTooltipId = `file-uploader-${id}-tooltip`;

    return (
        <FileUploaderWithTooltipWrapper>

            <SubSectionHeader
                copy={header}
            />

            <OutsideTooltipIconWrapper>

                <FileUploaderWrapper>

                    <FileUploader
                        onFileUploaderUnmounted={onFileUploaderUnmounted}
                        showError={!!showError}
                        id={id}
                        acceptedFileTypes={acceptedFileTypes}
                        maxFiles={1}
                        dataTestId={`file-uploader-${id}`}
                        {...(onChangesToAttachedFiles ? { onChangesToAttachedFiles } : {})}
                        {...(dispatchAsynchronousValidation ? { dispatchAsynchronousValidation } : {})}
                        {...(removeFilesOnUnmount ? { removeFilesOnUnmount } : {})}
                        {...(fieldLocationInFormState ? { fieldLocationInFormState } : { })}
                    />

                    <FileUploaderTooltipWrapper>
                        <OutsideTooltipIcon
                            show
                            icon={Icons.infoCircle}
                            color={globalColors.fontColors.green.light}
                            data-for={fileUploaderTooltipId}
                            data-test-id={fileUploaderTooltipId}
                            data-tip={tooltip}
                        />

                        <Tooltip id={fileUploaderTooltipId} />
                    </FileUploaderTooltipWrapper>

                    {showError && (
                        <ErrorMessage
                            translationKey="common:validationMsg.fieldMandatory"
                            translationValues={{}}
                            dataTestId={`file-uploader-${id}-validation-error`}
                        />
                    )}

                </FileUploaderWrapper>

            </OutsideTooltipIconWrapper>

        </FileUploaderWithTooltipWrapper>
    );
};
