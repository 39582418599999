import styled from "styled-components";

export const OnboardingFormPSD2DetailsApplicableRolesWrapper = styled.div`
    padding-top: 20px;
`;

export const CheckboxesErrorMessageWrapper = styled.div<{ showError: boolean }>`
    visibility: ${({ showError }) => showError ? 'visible' : 'hidden'}; 
    padding-bottom: 16px;
    padding-top: 4px;
`;
