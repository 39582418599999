import { Action, compose, Dispatch } from "redux";
import { change, clearAsyncError, getFormAsyncErrors, reduxForm } from "redux-form";
import { createAsyncValidate } from "utils/form";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import State from "models/app/store";
import { ONBOARDING_FORM_NAME } from "components/pages/Onboarding/Onboarding.types";
import createWithLoading from "hocs/loading/createWithLoading";
import LoadingOverlaySection from "components/reusable/Overalys/SectionLoadingOverlay.layout";
import { dismissAllToasts } from "store/actions/common";
import { OnboardingFormPSD2Details } from "./OnboardingFormPSD2Details.layout";
import {
    OnboardingFormPSD2DetailsDispatchProps,
    OnboardingFormPSD2DetailsOwnProps,
    OnboardingFormPSD2DetailsStateProps
} from "./OnboardingFormPSD2Details.types";
import { onboardingPSD2DetailsSchema, onboardingPSD2DetailsSchemaObject } from "../OnboardingForm.types";

const isTppApplicationBeingSubmittedSelector = (state: State) => {
    return state.onboarding.isTppApplicationBeingSubmitted;
};

function mapDispatchToProps(dispatch: Dispatch<Action>): OnboardingFormPSD2DetailsDispatchProps {
    return {
        dispatchDismissAllToasts: () => {
            dispatch(dismissAllToasts());
        },
        dispatchChangeForm: (fieldName: string, value: string) => {
            dispatch(change(ONBOARDING_FORM_NAME, fieldName, value));
        },
        dispatchClearAsyncError: (fieldName) => {
            dispatch(clearAsyncError(ONBOARDING_FORM_NAME, fieldName));
        },
    };
}

function mapStateToProps(state: State): OnboardingFormPSD2DetailsStateProps {
    return {
        formData: state.form.onboarding,
        formErrors: getFormAsyncErrors(ONBOARDING_FORM_NAME)(state),
        tppStatus: state.form.onboarding?.values?.tppStatus
    };
}

const WrappedOnboardingFormPSD2Details = compose<React.FC<OnboardingFormPSD2DetailsOwnProps>>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation('onboarding'),
    reduxForm({
        form: ONBOARDING_FORM_NAME,
        destroyOnUnmount: false,
        onChange: (vals: typeof onboardingPSD2DetailsSchema, dispatch) => {

            if (vals.declaredAisp || vals.declaredPisp) {
                dispatch(clearAsyncError('onboarding', 'declaredAisp'));
                dispatch(clearAsyncError('onboarding', 'declaredPisp'));
            }
        },
        forceUnregisterOnUnmount: true,
        asyncValidate: createAsyncValidate(onboardingPSD2DetailsSchemaObject),
        shouldAsyncValidate: ({ trigger }) => {
            return trigger === 'submit';
        }
    })
)(OnboardingFormPSD2Details);

export default createWithLoading<OnboardingFormPSD2DetailsOwnProps>(WrappedOnboardingFormPSD2Details, {
    loadingSelector: isTppApplicationBeingSubmittedSelector,
    OverlayComponent: LoadingOverlaySection,
    positionLock: true
});
