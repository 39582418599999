import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Dispatch } from 'models/meta/action';

import { State } from 'models/app/store';
import { setScrollPosition } from 'store/actions/app';
import { AccessStatus } from "models/domain/tpp";
import { AppLayoutDispatchProps, AppLayoutStateProps } from './AppLayout.types';
import AppLayout from './AppLayout.layout';

const mapStateToProps = (state: State): AppLayoutStateProps => {
    return {
        shouldShowPostApprovalItems: state.onboarding.tppStatus?.accessStatus === AccessStatus.GRANTED,
        isAuthenticated: state.user.isAuthenticated
    };
};

const mapDispatchToProps = (dispatch: Dispatch): AppLayoutDispatchProps =>
    ({ dispatchSetScrollPosition: (options) => dispatch(setScrollPosition(options)) });

export default compose<React.FC>(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AppLayout);
