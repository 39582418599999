import * as React from 'react';
import { noop } from "utils/function-tools";
import Icons from "assets/styles/icons/icons";
import { IconFont } from "assets/styles/icons/Icons.styled";
import { OkeoButton } from 'components/atoms/OkeoButton/OkeoButton.styled';
import { ActionVariant } from 'components/atoms/OkeoButton/OkeoButton.types';
import Styled from '../ModalOrchestrator.styled';
import { BiActionModalProps } from "./BiActionModal.types";

const trapFocusInsideModal = (e: KeyboardEvent, { current }: React.RefObject<HTMLButtonElement>) => {
    if (e.key === 'Tab' && !!current) {
        e.preventDefault();
        current.focus();
    }
};

const closeAfterAction = (props: Partial<BiActionModalProps>, action: () => void) => {
    action();
    props?.close?.();
};

export function BiActionModalLayout(props: Partial<BiActionModalProps>) {

    const closeButtonRef: React.RefObject<HTMLButtonElement> = React.useRef({} as HTMLButtonElement);

    const negativeActionButton: React.RefObject<HTMLButtonElement> = React.useRef({} as HTMLButtonElement);
    const positiveActionButton: React.RefObject<HTMLButtonElement> = React.useRef({} as HTMLButtonElement);

    React.useLayoutEffect(() => {
        closeButtonRef.current && closeButtonRef.current.focus();

        closeButtonRef.current && closeButtonRef.current.blur();
    }, []);

    const { desc, title, positiveAction, negativeAction, positiveActionName, negativeActionName, close } = props;

    return (
        <Styled.ModalWrapper role="dialog" aria-describedby="biModalDesc" data-test-id="bi-action-modal-dialog">
            <Styled.CloseButton
                variant="light"
                ref={closeButtonRef}
                onClick={close}
                type="button"
                onKeyDown={(e: KeyboardEvent) => trapFocusInsideModal(e, positiveActionButton)}
                data-test-id="bi-action-modal-close-btn"
            >
                <IconFont className="reject" icon={Icons.reject} ml={2} />
            </Styled.CloseButton>
            <Styled.Title>{title}</Styled.Title>
            <Styled.Description id="biModalDesc">{desc}</Styled.Description>
            <div>
                <OkeoButton
                    ref={positiveActionButton}
                    type="button"
                    actionVariant={ActionVariant.DANGER}
                    mr="24px"
                    onKeyDown={(e: KeyboardEvent) => trapFocusInsideModal(e, negativeActionButton)}
                    onClick={() => closeAfterAction(props, negativeAction || noop)}
                    data-test-id="bi-action-modal-negative-action-btn"
                >
                    {negativeActionName}
                </OkeoButton>
                <OkeoButton
                    ref={negativeActionButton}
                    type="button"
                    onKeyDown={(e: KeyboardEvent) => trapFocusInsideModal(e, closeButtonRef)}
                    onClick={() => closeAfterAction(props, positiveAction || noop)}
                    data-test-id="bi-action-modal-positive-action-btn"
                >
                    {positiveActionName}
                </OkeoButton>
            </div>
        </Styled.ModalWrapper>
    );
}

BiActionModalLayout.type = 'BiAction';

export default BiActionModalLayout;
