import styled from "styled-components";
import { FlagEN } from "assets/flags";
import { globalColors, standardLighterShadow, standardTransition } from "components/AppLayout/config";
import { CustomLanguageChangerPostion } from "components/atoms/CustomLanguageChanger/CustomLanguageChanger.types";

const Wrapper = styled.div`
    position: relative;
    display: flex;
`;

const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
`;

const Flag = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-image: url(${FlagEN});
`;

const ListWrapper = styled.div<{ opened: boolean, dropdownPostition?: CustomLanguageChangerPostion }>`
    background-color: ${globalColors.backgrounds.white};
    position: absolute;

    ${({ dropdownPostition }) => dropdownPostition === 'top'
        ? 'bottom: calc(100% + 10px); right: 2px;'
        : 'top: 100%'};

    ${standardLighterShadow};
    display: ${({ opened }) => opened ? 'block' : 'none'};
`;

const Option = styled.div`
    min-width: 120px;
    height: 40px;
    padding: 0px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: ${globalColors.fontColors.black};
    font-weight: 400;
    
    &:hover {
        background: linear-gradient(169deg,
            ${globalColors.backgrounds.green.light} 0%,
            rgba(73, 165, 30, 0.73) 100%
        );
    }
`;

const ChevronWrapper = styled.div<{ opened: boolean }>`
    ${standardTransition};
    transform: rotate(${({ opened }) => opened ? '-180deg' : '0deg'});
`;

export default { Wrapper, ContentWrapper, Flag, ListWrapper, Option, ChevronWrapper };

