const Icons = {
    arrowDown: "\\e959",
    arrowRightCircle: "\\e918",
    arrowsCycle: "\\e904",
    arrowsDottedRight: "\\e901",
    arrowsIn: "\\e91a",
    arrowsInvert: "\\e958",
    arrowsOut: "\\e91c",
    arrowsShuffle: "\\e93d",
    arrowsSwap: "\\e902",
    bell: "\\e913",
    bookAdd: "\\e900",
    bookEdit: "\\e946",
    bookView: "\\e94f",
    calendar: "\\e905",
    calendar2: "\\e925",
    calendarCheckMark: "\\e956",
    calendarReject: "\\e90d",
    calendarSchedule: "\\e90e",
    calendarSingleDay: "\\e953",
    checkMark: "\\e92d",
    checkMarkCircle: "\\e930",
    chevronDown: "\\e921",
    clock: "\\e90b",
    closeCircle: "\\e931",
    cog: "\\e914",
    cogEdit: "\\e942",
    cogView: "\\e94b",
    coin: "\\e952",
    copy: "\\e922",
    creditCard: "\\e911",
    dropFile: "\\e906",
    edit: "\\e933",
    envelope: "\\e91f",
    envelope2: "\\e923",
    envelopeEdit: "\\e943",
    envelopeView: "\\e94c",
    exchange: "\\e916",
    exchangeEdit: "\\e93f",
    exchangeView: "\\e94d",
    fileText: "\\e908",
    folderAdd: "\\e917",
    home: "\\e90f",
    homeEdit: "\\e938",
    homeMoney: "\\e939",
    homeView: "\\e93a",
    infoCircle: "\\e92f",
    itemEdit: "\\e907",
    itemOut: "\\e90a",
    itemReject: "\\e934",
    itemSchedule: "\\e935",
    itemsOut: "\\e909",
    key: "\\e93c",
    like: "\\e93b",
    lock: "\\e936",
    lockClosedFilled: "\\e91e",
    lockEdit: "\\e944",
    lockView: "\\e94e",
    logout: "\\e912",
    mapMarker: "\\e91d",
    meterEdit: "\\e941",
    meterView: "\\e94a",
    person: "\\e957",
    person2: "\\e950",
    personAdd: "\\e947",
    personCircle: "\\e948",
    personStar: "\\e954",
    phone: "\\e920",
    pieChart: "\\e910",
    pieChartEdit: "\\e940",
    pieChartView: "\\e949",
    priceTag: "\\e919",
    rate: "\\e955",
    reject: "\\e924",
    search: "\\e92a",
    shoppingCart: "\\e915",
    shoppingCartAdd: "\\e945",
    snippet: "\\e93e",
    sortAsc: "\\e92c",
    sortDesc: "\\e92b",
    star: "\\e937",
    starFilled: "\\e903",
    tag: "\\e91b",
    trash: "\\e90c",
    wallet: "\\e929",
    warning: "\\e92e",
    sell: "\\e926",
    buy: "\\e927",
    link: "\\e928",
    eye: "\\e932"
};

export default Icons;
