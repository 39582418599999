import styled from "styled-components";

export const InfoRowWithTooltipWrapper = styled.div`
    display: flex;
    margin-bottom: 10px;
`;

export const TooltipWrapper = styled.div`
    margin-left: 40px;
`;
