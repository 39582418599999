import { State } from 'models/app/store';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import LoggedInAs from './LoggedInAs.layout';
import { LoggedInStateProps } from './LoggedInAs.types';

const mapStateToProps = (state: State): LoggedInStateProps => ({ userName: state.user.data?.displayName });

export default connect(mapStateToProps)(withTranslation('common')(LoggedInAs));
