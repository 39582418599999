import React from "react";
import { StepHeaderProps } from "components/pages/Onboarding/OnboardingForm/OnboardingForm.types";
import { StepHeaderWrapper } from "components/pages/Onboarding/OnboardingForm/OnboardingForm.styled";
import { FormContentWrapper } from "components/pages/Onboarding/OnboardingForm/OnboardingFormCompanyDetails.styled";
import { SpaceBetween } from "components/pages/Onboarding/OnboardingForm/OnboardingFormRepresentativeDetails.styled";
import { GrayFontContainer } from "./SubSectionHeader.styled";

export const SubSectionHeader: React.FC<StepHeaderProps> = ({ copy, stepCount, fontSize }) => {
    return (
        <StepHeaderWrapper fontSize={fontSize}>
            <FormContentWrapper>
                <GrayFontContainer>
                    <SpaceBetween>

                        <span>
                            {copy}
                        </span>

                        {
                            (stepCount !== undefined) && (
                                <span>
                                    {`${stepCount}/3`}
                                </span>
                            )
                        }

                    </SpaceBetween>
                </GrayFontContainer>
            </FormContentWrapper>
        </StepHeaderWrapper>
    );
};
