import { applyMiddleware, compose, createStore, Store } from 'redux';
import { History } from 'history';
import { createEpicMiddleware } from 'redux-observable';
import { toast } from "react-toastify";

import 'config/i18next';
import 'config/sentry';

import { developmentMode } from 'config/config';
import { Action } from 'models/meta/action';
import { State } from 'models/app/store';
import { HttpService } from 'services/HttpService';
import TppManagementService from 'services/TppManagementService';
import OnboardingService from "services/OnboardingService";
import ApiKeysService from 'services/ApiKeysService';
import CertificatesService from "services/CertificatesService";
import DictionaryService from "services/DictionaryService";

import showToast from 'components/reusable/Toast/AppToast/AppToast.layout';

import FileService from "services/FileService";
import { rootReducer } from './reducers';
import rootEpic from './epics';

export interface Dependencies {
    history: History,
    http: HttpService,
    showToast: typeof showToast,
    toast: typeof toast,
    document: HTMLDocument;
    tpp: typeof TppManagementService,
    onboarding: typeof OnboardingService,
    certificates: typeof CertificatesService,
    apiKeys: typeof ApiKeysService,
    dictionary: typeof DictionaryService,
    file: typeof FileService
}

export function configureStore(dependencies: Dependencies): Store<State> {
    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const epicMiddleware = createEpicMiddleware<Action, Action, State, Dependencies>({ dependencies });
    const middleware = applyMiddleware(epicMiddleware);
    const enhancer = developmentMode ? composeEnhancers(middleware) : middleware;

    const storeInstance = createStore(rootReducer, enhancer);

    epicMiddleware.run(rootEpic);

    return storeInstance;
}
