import styled, { css } from 'styled-components';

import { IconFont } from 'assets/styles/icons/Icons.styled';
import { ColorTransition } from 'assets/styles/animations/animations.styled';
import { space, SpaceProps } from "styled-system";
import { errorColor, globalColors, successColor } from 'components/AppLayout/config';
import { ErrorProp, FieldIconStyleProps, IconProp } from './FormField.types';

export const FormFieldWithError = styled.div<SpaceProps>`
    width: 100%;
    padding: 8px 0 25px;
    position: relative;
    ${space};
`;

export const AnimatedLabelWrap = styled.div<ErrorProp & IconProp>`
    position: relative;
    width: 100%;
    
    > input {
      ${props => props.hasIcon && css`padding-right: 60px;`};
      border-color: ${globalColors.fontColors.grey.base};
    }

    input:hover + .input-label,
    input:focus + .input-label,
    div:hover + .input-label,
    i:hover ~ .input-label {
      color: ${props => (props.hasError ? errorColor : successColor)};
    }
    
    i:hover + input  {
      border-color: ${props => (props.hasError ? errorColor : successColor)};
    }
`;

const FieldIcon = styled(IconFont)<FieldIconStyleProps>`
    position: absolute;
    z-index: 10;
    top: 50%;
    right: 21px;
    font-size: 18px;
    transform: translateY(-50%);
    color: ${successColor};
    ${({ hasError }) => hasError && css`color: ${errorColor}`};
    ${({ isValid }) => isValid && css`color: ${successColor}`};
    ${ColorTransition};
`;

export default { AnimatedLabelWrap, FormFieldWithError, FieldIcon };
