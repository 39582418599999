import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import { Dispatch } from 'models/meta/action';
import { navigate } from 'store/actions/common';

import NavigationBar from './NavigationBar.layout';
import { NavigationBarDispatchProps } from './NavigationBar.types';

const mapDispatchToProps = (dispatch: Dispatch): NavigationBarDispatchProps =>
    ({ dispatchNavigate: (location, options) => dispatch(navigate(location, options)) });

export default compose(
    withTranslation('common'),
    connect(null, mapDispatchToProps)
)(NavigationBar);
