import { ofType } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Epic } from 'models/meta/epic';
import { GET_TPP_DETAILS, GET_TPP_STATUS, SUBMIT_TPP_APPLICATION } from "store/actions/onboarding.types";
import { ApplicationStatus, TppId } from "models/domain/tpp";
import {
    getTppDetails,
    getTppDetailsSuccess,
    getTppStatusSuccess,
    setOnboardingStep,
    submitTppApplicationFailure,
    submitTppApplicationSuccess
} from "store/actions/onboarding";
import { OnboardingStep } from "models/app/workflow";
import { initialize } from "redux-form";
import { ONBOARDING_FORM_NAME } from "components/pages/Onboarding/Onboarding.types";
import { ApiError } from "models/meta/api";
import { HttpStatus } from "utils/constants/http-status";
import { actionErrorToast } from 'store/actions/common';
import { setFileUploaderFiles } from 'store/actions/fileUploader';
import { ONBOARDING_FILE_UPLOADER_NAME } from 'store/actions/fileUploader.types';

export const onGetTppDetails: Epic = (action$, _, { onboarding }) =>
    action$.pipe(
        ofType(GET_TPP_DETAILS),
        switchMap((action) => {

            return from(onboarding.getTppDetails(action.payload)).pipe(
                switchMap((tppDetails) => {

                    return of(
                        setOnboardingStep(null),
                        getTppDetailsSuccess(tppDetails),
                        initialize(ONBOARDING_FORM_NAME, tppDetails.application)
                    );

                }),
                catchError(() => {
                    return of(setOnboardingStep(null));
                })
            );
        })
    );

export const onGetTppStatus: Epic = (action$, _, { onboarding }) =>
    action$.pipe(
        ofType(GET_TPP_STATUS),
        switchMap(() => {

            return from(onboarding.getTppStatus()).pipe(
                switchMap((tppStatus) => {

                    return (
                        (!tppStatus || !Object.keys(tppStatus).length || !tppStatus.tppId)
                            ? of(getTppStatusSuccess(tppStatus))
                            : of(getTppDetails(tppStatus.tppId), getTppStatusSuccess(tppStatus))
                    );
                }),
                catchError((error: ApiError) => {

                    return (
                        error.status === HttpStatus.NOT_FOUND
                            ? of(getTppStatusSuccess(null))
                            : of(
                                ...error.actions,
                                getTppStatusSuccess(null),
                            )
                    );

                })
            );
        })
    );

export const onSubmitTppApplication: Epic = (action$, state$, { onboarding }) =>
    action$.pipe(
        ofType(SUBMIT_TPP_APPLICATION),
        switchMap((action) => {

            const applicationAction = (
                (state$?.value.onboarding.tppDetails?.application.applicationStatus === ApplicationStatus.ACCEPTED)
                    ? onboarding.updateTppApplication.bind({}, state$?.value.onboarding.tppStatus?.tppId)
                    : onboarding.submitTppApplication
            );

            return from(applicationAction(action.payload)).pipe(
                switchMap((tppIdFromSubmittingNewApplication?: TppId) => {

                    const tppIdToUpdate = state$?.value.onboarding.tppStatus?.tppId;
                    const tppId = tppIdFromSubmittingNewApplication || tppIdToUpdate;

                    return tppId
                        ? of(
                            submitTppApplicationSuccess(tppId),
                            setOnboardingStep(OnboardingStep.APPLICATION_SUBMITTED)
                        )
                        : EMPTY;
                }),
                catchError((error: ApiError) => {

                    return (
                        error.message === "Certificate is not valid"
                            ? of(
                                actionErrorToast('common', 'invalidEidasQwac'),
                                setFileUploaderFiles(ONBOARDING_FILE_UPLOADER_NAME, []),
                                submitTppApplicationFailure()
                            )
                            : of(
                                ...error.actions,
                                submitTppApplicationFailure()
                            )
                    );
                })
            );
        })
    );

export default [
    onGetTppDetails,
    onGetTppStatus,
    onSubmitTppApplication
];
