import { Form as ReduxForm } from "redux-form";
import styled, { css } from 'styled-components';
import { WithTheme } from 'config/theme';
import { IconFont } from 'assets/styles/icons/Icons.styled';
import { globalColors, okeoGradient, standardTransition } from "components/AppLayout/config";
import { OkeoButton } from "components/atoms/OkeoButton/OkeoButton.styled";


const AuthenticationPageOuterWrap = styled.div`
    max-width: 1180px;
    width: 100%;
    background-color: ${globalColors.backgrounds.white};
`;

const AuthenticationPageInnerWrap = styled.div`
    width: 488px;
    margin: 0 auto;
`;

const AuthenticationPageLogoWrap = styled.div`
    text-align: center;
    margin-bottom: 60px;
`;

const AuthenticationPanelWrap = styled.section<{ hasError?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 52px;
    height: 378px;
    padding: 23px 11px 38px;
    text-align: center;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, .16);
    background-color: ${globalColors.backgrounds.white};

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 8px;
    background: ${okeoGradient};
    ${({ hasError, theme }) => hasError && css`background-color: ${theme.colors.error.base}`};
    ${standardTransition};
  }
`;

export const TooltipWrapper = styled.div`
    align-self: flex-start;
    position: relative;
    top: 26px;

    & i {
        position: absolute;
        right: -25px;
    }
`;
export const RowWithTooltipWrapper = styled.div`
    display: flex;
`;

export const PageHeader = styled.h1`
    margin: 0 0 55px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    text-align: center;
    color: ${globalColors.fontColors.black};
`;

export const SuccessSubHeader = styled.div`
    margin: 20px auto 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    color: ${globalColors.fontColors.green.light};
`;

export const PageSubHeader = styled.h2`
    margin: 0 0 28px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: center;
    color: ${({ theme }: WithTheme) => theme.colors.font.onPrimaryLighter};
`;

export const SecondaryActionWrap = styled.section`
    padding: 23px 0 13px;
    line-height: 24px;
    text-align: center;
    font-size: 12px;
`;

export const SecondaryActionLabel = styled.span`
    display: inline-block;
    margin: 0 1ch;
`;

export const InfoIcon = styled(IconFont)`
    position: absolute;
    top: 22px;
    right: 19px;
    font-size: 22px;
    cursor: help;
`;

export const DecorationIcon = styled(IconFont)`
    display: block;
    margin: 0 auto;
    font-size: 80px;
    pointer-events: none;
`;

export const DecorationImage = styled.img`
    display: block;
    margin: 0 auto;
`;

export const CenteredButton = styled(OkeoButton)`
    display: block;
    margin: 5px auto;
`;

export const Form = styled(ReduxForm)`
    padding: 0 30px;
`;

export const InfoMessage = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    color: ${({ theme }: WithTheme) => theme.colors.gray.xdark}
`;

export default {
    TooltipWrapper,
    RowWithTooltipWrapper,
    AuthenticationPanelWrap,
    AuthenticationPageOuterWrap,
    AuthenticationPageInnerWrap,
    AuthenticationPageLogoWrap,
    PageHeader,
    PageSubHeader,
    InfoIcon,
    Form,
    SecondaryActionWrap,
    DecorationIcon,
    DecorationImage,
    CenteredButton
};
