// TODO restore API call when it becomes possible
// import { HttpService } from '../HttpService';
// import { CountriesInfo } from 'models/domain/dictionary';

// type SearchMode = 'SELECTED' | 'ALL';

import { CountriesInfo } from 'models/domain/dictionary';

const dictionary = {
    getAllCountryCodesDetails: (codes?: string[]): Promise<CountriesInfo> => {

        // TODO restore API call when it becomes possible
        // const searchMode: SearchMode = codes !== undefined ? 'SELECTED' : 'ALL';
        // const searchForValues = codes !== undefined ? `&searchForValues=${codes.join("&searchForValues=")}` : '';
        // return HttpService.get(`api/dictionary/v1/getAllCountryCodesDetails?searchMode=${searchMode}${searchForValues}`);

        // TODO: this is TMP solution
        const countriesList = import('services/api/countriesList.json').then(module => module.default);

        return Promise.resolve(countriesList);
    }
};

export { dictionary };
