import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import State from 'models/app/store';

import userReducer from './userReducer';
import appReducer from './appReducer';

import onboardingReducer from "./onboardingReducer";
import modalReducer from "./modalReducer";
import fileUploaderReducer from "./fileUploaderReducer";
import apiKeysReducer from './apiKeysReducer';
import certificatesReducer from "./certificatesReducer";
import toastsReducer from "./toastsReducer";
import dictionaryReducer from './dictionaryReducer';

export const rootReducer = combineReducers<State>({
    form: formReducer,
    user: userReducer,
    app: appReducer,
    modal: modalReducer,
    fileUploader: fileUploaderReducer,
    onboarding: onboardingReducer,
    certificates: certificatesReducer,
    apiKeys: apiKeysReducer,
    toasts: toastsReducer,
    dictionary: dictionaryReducer
});
