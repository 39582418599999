import React from 'react';
import Styled, { flagPositions } from './CountryFlag.styled';


const CountryFlag: React.FC<{name: string}> = ({ name }): JSX.Element => {
    const position = flagPositions[name] || 0;

    return (
        <Styled.CountryFlag prefix={position} />
    );
};

export default CountryFlag;
